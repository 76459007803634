import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { stripTag } from '../../core/helpers.js';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import ReactTable from 'react-table';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../components/Buttons/Button';
//import ButtonGroup from '@material-ui/core/ButtonGroup';

import Tooltip from '@material-ui/core/Tooltip';
//import CreditCard from "@material-ui/icons/CreditCard";
//import CustomInput from "../../components/CustomInput/CustomInput.jsx";
// @material-ui/icons
import Cancel from '@material-ui/icons/Cancel';
import tooltipStyle from '../../components/Tooltip/TooltipStyle';
import { ReactComponent as Bill } from '../../assets/icons/bill.svg';

const styles = (theme) => ({
  ...tooltipStyle(theme),
  tagStyle: {
    background: '#efefef',
    padding: '0px 3px 0px 3px',
    margin: '0px 2px 0px 2px',
    borderRadius: '3px',
    fontWeight: '400',
    textDecoration: 'none',
    fontStyle: 'unset',
    fontVariant: 'small-caps',
    textTransform: 'lowercase',
    letterSpacing: '1.6px',
    fontSize: '8pt',
    border: '1px solid #b6b6b6',
    width: '-moz-fit-content',
    marginBottom: '1px',
  },
  tooltip: {
    maxWidth: '80px',
    '& img': {
      borderRadius: '25px 25px 25px 25px',
    },
  },
  bajaIcon: {
    width: '30px !important',
    height: '30px !important',
  },
  rowIcon: {
    maxWidth: '30px',
  },
});

class ListadoCuentas extends Component {
  static propTypes = {
    classes: PropTypes.any,
    style: PropTypes.any,
    DDJJComerciosRI: PropTypes.any,
    data: PropTypes.array.isRequired,
    onSaveCuentas: PropTypes.func.isRequired,
    onDeleteCuenta: PropTypes.func.isRequired,
    onUpDebitoAutomatico: PropTypes.func.isRequired,
    onUpFacturaElectronica: PropTypes.func.isRequired,
    onDownDebitoAutomatico: PropTypes.func.isRequired,
    onDownFacturaElectronica: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.renderEditable = this.renderEditable.bind(this);
    this.renderTags = this.renderTags.bind(this);
  }

  onSaveCuentas = () => {
    const cuentas = [];
    this.state.data.map((c) => {
      cuentas.push({
        aliasCuenta: c.value,
        idCuenta: this.props.data[c.index].idCuenta,
        idPersona: this.props.data[c.index].idPersona,
        nroCuenta: this.props.data[c.index].nroCuenta,
        tipoCuenta: this.props.data[c.index].tipoCuenta,
      });
    });
    this.props.onSaveCuentas(cuentas);
  };

  renderPageJump = (options) => {
    return (
      <input
        style={{ maxWidth: '60px' }}
        type="text"
        value={options.value}
        onBlur={options.onBlur}
        onChange={options.onChange}
        onKeyPress={options.onKeyPress}
      />
    );
  };

  defaultFilter = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };

  render() {
    const data = this.props.data;
    return (
      <div>
        <ReactTable
          data={this.getItemsInfo(data)}
          filterable
          resizable={true}
          columns={this.getColumns()}
          defaultPageSize={5}
          renderPageJump={this.renderPageJump}
          showPageSizeOptions={true}
          showPaginationTop={false}
          showPaginationBottom
          className="-striped -highlight"
          previousText="<"
          nextText=">"
          loadingText="Cargando..."
          noDataText="No se encontraron datos"
          pageText="Página"
          ofText="de"
          rowsText="filas"
          defaultFilterMethod={this.defaultFilter}
        />
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start"
        >
          <Grid item>
            <Button color="primary" onClick={this.onSaveCuentas}>
              Guardar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
  editRow = (item) => {
    const state = this.state;
    state.editando = item;
    this.setState({ ...state });
  };

  deleteRow = (item) => {
    this.props.onDeleteCuenta(item);
  };

  handlerDebitoAutomatico = (item) => {
    if (item.debitoAutomatico === 'N')
      return this.props.onUpDebitoAutomatico(item);

    if (item.debitoAutomatico === 'S')
      return this.props.onDownDebitoAutomatico(item);
  };

  handlerFacturaElectronica = (item) => {
    if (item.facturaElectronica === 'N')
      return this.props.onUpfacturaElectronica(item);

    if (item.facturaElectronica === 'S')
      return this.props.onDownfacturaElectronica(item);
  };

  onClickAgregar = () => {};

  renderTags(cellInfo) {
    const classes = this.props.classes;

    return (
      <div>
        {cellInfo.original.debitoAutomatico === 'S' && (
          <div className={classes.tagStyle}>Débito Automatico</div>
        )}
        {cellInfo.original.facturaElectronica === 'S' && (
          <div className={classes.tagStyle}>Boleta Electrónica</div>
        )}
      </div>
    );
  }
  renderEditable(cellInfo) {
    if (!this.props.data[cellInfo.index]) return <p>NOTHING</p>;
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const item = {
            index: cellInfo.index,
            columnId: cellInfo.column.id,
            value: stripTag(e.target.innerHTML),
          };
          const data = this.state.data.filter((i) => i.index !== item.index);
          data.push(item);
          this.setState({ data });
        }}
        dangerouslySetInnerHTML={{
          __html: this.props.data[cellInfo.index][cellInfo.column.id] || '',
        }}
      />
    );
  }

  getItemsInfo = (items) => {
    const { classes } = this.props;
    return items.map((item) => {
      return {
        ...item,
        actions: (
          <div className="actions-right">
            {this.props.debitoAutomatico && this.props.verIconos && (
              <Button
                simple
                round
                size="sm"
                color="secondary"
                onClick={
                  item.debitoAutomatico === 'N'
                    ? () => this.props.onUpDebitoAutomatico(item)
                    : () => this.props.onDownDebitoAutomatico(item)
                }
              >
                <Tooltip
                  id="tooltip-da"
                  title={
                    item.debitoAutomatico === 'N'
                      ? 'Asociar Debito Automático'
                      : 'Baja Debito Automático'
                  }
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>{item.debitoAutomatico === 'N' ? 'DA' : 'BD'}</div>
                </Tooltip>
              </Button>
            )}

            {this.props.verIconos && (
              <Button
                round
                simple
                size="sm"
                color="secondary"
                className={classes.tooltip}
                onClick={
                  item.facturaElectronica === 'N'
                    ? () => this.props.onUpFacturaElectronica(item)
                    : ( this.props.permiteBaja === 'S' ?
                        () => this.props.onDownFacturaElectronica(item)
                      : () =>{}
                      )
                }
              >
                <Tooltip
                  id="tooltip-af"
                  title={
                    item.facturaElectronica === 'N' ? (
                      `Adherir a ${this.props.tooltipAltFact}`
                    ) : (
                      <div>
                        {`Adherido a ${this.props.tooltipAltFact}`}
                        <br />
                        {this.props.permiteBaja === 'S'
                          ? 'Desea dar de baja ?'
                          : ''}
                      </div>
                    )
                  }
                  placement="top"
                >
                  {item.facturaElectronica === 'N' ?
                    (this.props.urlIconCuenta_no === undefined ? (                    
                          <Bill  fill='' className={classes.bajaIcon}/>
                        ):(
                          <img alt="bill icon" className={classes.rowIcon} src={this.props.urlIconCuenta_no}/>
                          ) 
                    ):
                    (this.props.urlIconCuenta_si === undefined ? (                    
                          <Bill  fill='#63e457' className={classes.bajaIcon}/>
                        ):(
                          <img alt="bill icon" className={classes.rowIcon} src={this.props.urlIconCuenta_si}/>
                          ) 
                    )                  } 
                </Tooltip>
              </Button>
            )}

            <Button
              justIcon
              round
              simple
              size="sm"
              color="error"
              onClick={() => this.deleteRow(item)}
            >
              <Tooltip
                id="tooltip-remove"
                title={this.props.toolTipDesAsociar}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Cancel />
              </Tooltip>
            </Button>
          </div>
        ),
      };
    });
  };

  makePlaceholderFilter(placeholder) {
    return ({ filter, onChange }) => (
      <input
        type="text"
        placeholder={placeholder}
        style={{
          width: '100%',
        }}
        value={filter ? filter.value : ''}
        onChange={(event) => onChange(event.target.value)}
      />
    );
  }

  getColumns = () => {
    const columns = [
      {
        Header: 'Alias',
        accessor: 'aliasCuenta',
        style: { textAlign: 'left' },
        Cell: this.renderEditable,
        minWidth: 30,
        sortMethod: (a, b) => {
          return String(a) > String(b) ? 1 : -1;
        },
        Filter: this.makePlaceholderFilter('Filtrar'),
      },
      {
        Header: 'Cuenta',
        accessor: 'descripcion',
        style: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: '50px',
        },
        minWidth: 90,
        sortMethod: (a, b) => {
          return String(a) > String(b) ? 1 : -1;
        },
        Filter: this.makePlaceholderFilter('Filtrar'),
      },
      /*SE SACA LA COLUMNA DE ESTADO, SE MANEJA CON COLORES EN LOS ICONOS{
        Header: '',
        minWidth: 10,
        filterable: false,
        style: { textAlign: 'left' },
        Cell: this.renderTags,
      },*/
    ];
    columns.push({
      Header: 'Acciones',
      accessor: 'actions',
      style: { textAlign: 'left' },
      className: 'frozen',
      sortable: false,
      filterable: false,
      width: 155,
    });

    return columns;
  };
}
export default withStyles(styles)(ListadoCuentas);
