export const downloadFile = (encodedUri, fileName) => {
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    'data:application/pdf;charset=utf-8;base64,' + encodedUri
  );
  link.setAttribute('download', fileName);
  document.body.appendChild(link); // Required for FF
  return Promise.resolve(link.click());
};
