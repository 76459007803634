import {handleActions} from 'redux-actions';
import * as actionTypes from './facturas-actions';
import {handleApiAction} from '../services/ApiMiddleware.reducer';
//import {remove} from "lodash";
import { flattenFacturas } from './facturas.js';

const initialState = {
    data: [
      // {
      //     "idFactura": "757585",
      //     "descripcion_factura": "INSCRIPCIO",
      //     "fecha_1vto": "2017-04-07 00:00:00",
      //     "importe_1vto": "116.13",
      //     "importe_2vto": "116.13",
      //     "tipo": "Deuda",
      //     "desc_estado": "Pagada",
      //     "estado": "2",
      //     "cuenta": {
      //     "alias_cuenta": "auto",
      //     "id_cuenta": "45457",
      //     "tipoCuenta": "TCR10",
      //     "nro": "2534",
      //     "desc_tipo_cuenta": "Automotor",
      //     "descripcion": "Automotor 081JRO MANGIAVILLANO MAYRA",
      //     "responsable": "MANGIAVILLANO MAYRA",
      //     "id_persona": "17490"
      // }
    ],
    loadingFacturas: false,
    facturasError: false,
};

export default handleActions({
  ...handleApiAction(actionTypes.getFacturas, {loadingField: 'loadingFacturas', errorField: 'facturasError'},
      (state, {payload: {data = []}}) => {
          return {
              ...state,
              data: flattenFacturas(data)
          };
      }),
}, initialState);
