import React, { useState, useEffect } from 'react';
import {
  Paper,
  Container,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Tooltip,
} from '@material-ui/core';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import { makeStyles } from '@material-ui/core/styles';
import { makeAPICall } from '../../core/services/http-request';
import Button from '../../components/Buttons/Button.jsx';
import { getFullUrl } from '../../core/globals';
import ReactTable from 'react-table';
import { ReactComponent as Bill } from '../../assets/icons/bill.svg';

const Dashboard = (props) => {
  const { parametros, user } = props;
  const msgSinCuentas = props.parametros?.AU_MSG_SIN_CUENTAS?.valor || '';
  const msgSinNroDoc = props.parametros?.AU_MSG_SIN_DOCUMENTO?.valor || '';
  const bShowCuentas =
    props.parametros?.AU_DSHBRD_CUENTAS?.valor === 'S' ? true : false;
  const bShowIraCuentas =
    props.parametros?.AU_DSHBRD_IR_A_CUENTAS?.valor === 'N' ? false : true;
  const tooltipAltFact =
    props.parametros?.APP_TOOLTIP_ALTA_FACT?.valor || 'Boleta Electrónica';
  const [person, setPerson] = useState();
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [cuentas, setCuentas] = useState([]);

  /*const handleChange = name => event => {
    setPerson({ ...person, [name]: event.target.value });
  };*/

  useEffect(() => {
    const get = async () => {
      const req = {
        requestConfig: {
          method: 'get',
          params: {
            usuario: user.id,
          },
          url: '/usuarios/perfil',
        },
        methodCall: '',
        needsToken: true,
      };
      const response = await makeAPICall(req);

      setPerson(response.data);
    };
    const getTiposDocs = async () => {
      const req = {
        requestConfig: {
          method: 'get',
          url: '/usuarios/tipoDocumentos',
        },
        methodCall: '',
        needsToken: true,
      };
      const response = await makeAPICall(req);

      setTiposDocumentos(response.data);
    };

    const getCuentas = async () => {
      const req = {
        requestConfig: {
          method: 'get',
          params: {
            usuario: user.id,
          },
          url: '/usuarios/cuentas',
        },
        methodCall: '',
        needsToken: true,
      };
      const response = await makeAPICall(req);
      setCuentas(response.data);
    };
    getTiposDocs();
    get();
    getCuentas();
  }, []);

  const useStyles = makeStyles((theme) => ({
    personInfo: {
      marginTop: '10px',
      marginBottom: '30px',
      padding: '20px',
    },
    textInput: {
      width: '80%',
      marginTop: '0px',
    },
    emailInput: {
      width: '100%',
      marginTop: '0px',
    },
    inputNroDoc: {
      marginTop: '0px',
    },
    msgNroDoc: {
      float: 'left',
      color: 'tomato',
    },
    msgCuentas: {
      padding: '20px',
      '&> h5': {
        color: 'tomato',
        textAlign: 'center',
      },
    },
    profileButton: {
      width: 'auto',
    },
    cuentasButton: {
      width: 'auto',
      color: 'white',
    },
    myPerfilButton: {
      color: 'white',
    },
    tooltip: {
      maxWidth: '80px',
      '& img': {
        borderRadius: '25px 25px 25px 25px',
      },
    },
    bajaIcon: {
      width: '30px !important',
      height: '30px !important',
    },
  }));
  const classes = useStyles();

  const irAPerfil = () => {
    props.history.replace(getFullUrl('my-profile'));
  };

  const irACuentas = () => {
    props.history.replace(getFullUrl('cuentas'));
  };

  const getColumns = () => {
    const columns = [
      {
        Header: 'Alias',
        accessor: 'aliasCuenta',
        style: { textAlign: 'left' },
        minWidth: 30,
        sortMethod: (a, b) => {
          return String(a) > String(b) ? 1 : -1;
        },
      },
      {
        Header: 'Cuenta',
        accessor: 'descripcion',
        headerStyle: {
          textAlign: 'left',
        },
        style: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: '50px',
        },
        minWidth: 90,
        sortMethod: (a, b) => {
          return String(a) > String(b) ? 1 : -1;
        },
      },
    ];

    columns.push({
      Header: '',
      accessor: 'icons',
      style: { textAlign: 'left' },
      className: 'frozen',
      sortable: false,
      filterable: false,
      width: 155,
    });

    return columns;
  };

  const getItemsInfo = (items) => {
    return items.map((item) => {
      return {
        ...item,
        icons: (
          <div className="actions-right">
            {item.debitoAutomatico === 'S' && (
              <Button simple round size="sm" color="secondary">
                <Tooltip
                  id="tooltip-da"
                  title={'Asociado a Debito Automático'}
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>{item.debitoAutomatico === 'N' ? 'DA' : 'BD'}</div>
                </Tooltip>
              </Button>
            )}

            {item.facturaElectronica === 'S' && (
              <Button
                round
                simple
                size="sm"
                color="secondary"
                className={classes.tooltip}
              >
                <Tooltip
                  id="tooltip-af"
                  title={`Adherido a ${tooltipAltFact}`}
                  placement="top"
                >
                  {props?.parametros?.APP_FACT_DIGITAL_SI?.valor ===
                  undefined ? (
                    <Bill fill="#63e457" className={classes.bajaIcon} />
                  ) : (
                    <img
                      alt="${tooltipAltFact}"
                      className={classes.rowIcon}
                      src={props?.parametros?.APP_FACT_DIGITAL_SI?.valor}
                    />
                  )}
                </Tooltip>
              </Button>
            )}
          </div>
        ),
      };
    });
  };
  
  if (!person) return (<div> </div>);
  return (
    <Container>
      <Paper className={classes.personInfo} elevation={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="nombre-input"
              label="Apellido y Nombre"
              name="nombre"
              margin="normal"
              disabled
              className={classes.textInput}
              value={person.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="tipoDocumento">Tipo Documento</InputLabel>
              <Select
                name="tipoDocumento"
                inputProps={{
                  id: 'tipoDocumento',
                }}
                disabled
                className={classes.selectEmpty}
                MenuProps={{
                  className: classes.selectMenu,
                }}
                defaultValue={person.tipoDocumento ? person.tipoDocumento : ''}
              >
                {tiposDocumentos.map((tipo, index) => {
                  return (
                    <MenuItem key={index} value={tipo.tipoDocumento}>
                      {' '}
                      {tipo.descripcion}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nroDocumento-input"
              label="Nro Documento"
              name="nroDocumento"
              margin="normal"
              disabled
              className={classes.inputNroDoc}
              value={person.nroDocumento ? person.nroDocumento : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="mail-input"
              label="Emali"
              name="mail"
              margin="normal"
              disabled
              className={classes.emailInput}
              value={person.mail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button onClick={irAPerfil} color="primary">
              Ir A Perfil
            </Button>
          </Grid>
          {msgSinNroDoc && !person.nroDocumento && (
            <Grid item xs={12}>
              <div className={classes.msgNroDoc}>
                <h5>{msgSinNroDoc}</h5>
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>

      {cuentas.length !== 0 && (
        <Paper>
          <ReactTable
            data={getItemsInfo(cuentas)}
            filterable
            resizable={true}
            columns={getColumns()}
            defaultPageSize={5}
            showPageSizeOptions={true}
            showPaginationTop={false}
            showPaginationBottom
            className="-striped -highlight"
            previousText="<"
            nextText=">"
            loadingText="Cargando..."
            pageText="Página"
            ofText="de"
            rowsText="filas"
          />
        </Paper>
      )}
      {bShowCuentas && cuentas.length == 0 && msgSinCuentas && (
        <Paper elevation={3} className={classes.msgCuentas}>
          <h5>{msgSinCuentas}</h5>
          { bShowIraCuentas && (
            <GridContainer
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
              className={classes.cuentasButton}>
              <GridItem>
                <Button onClick={irACuentas} color="primary">
                  Ir A Cuentas
                </Button>
              </GridItem>
            </GridContainer>)
          }
        </Paper>
      )}
    </Container>
  );
};

export default Dashboard;
