import React, { Component } from 'react';
import OpcionMedioPago from './OpcionMedioPago';
import GatewayView from './GatewayView';
import Header from '../../components/Header/Header.jsx';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { formatMoneda } from '../../core/helpers.js';

//Actions connect
import {
  postResumenPago,
  postPagar,
  getMediosPago,
  getGatewayPago,
} from '../../core/pagos/pagos-actions.js';
import { getPerfil } from '../../core/perfil/perfil-actions';
//Utils

import { downloadFile } from '../../core/downloadService';
import Typography from '@material-ui/core/Typography';
import MessageComponent from '../../components/Message/MessageComponent';
import Button from '../../components/Buttons/Button';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import BlockComponent from '../../components/Loading/BlockComponent';
import moment from 'moment';
import ComprobantePago from '../../components/ComprobantePago/ComprobantePago.jsx';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Done from '@material-ui/icons/Done';

const styles = theme => ({
  root: {
    marginTop: '20px',
    alignItems: 'center',
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    lineHeight: '30px',
    whiteSpace: 'nowrap',
  },
});

class Pago extends Component {
  static propTypes = {
    classes: PropTypes.any,
    headerProps: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);
    this.continuarRef = React.createRef();

    this.state = Object.assign({
      gatewayReady: false,
      gateway: null,
      /* steps            
                1: seleccion medio pago. 
                2: completar datos de pago. 
                3: pago exito. 
                4: pago error. */
      step: 1,
      resultadoPago: null,

      messageColor: null,
      message: null,
      activeStep: 1,
      medioPago: [], //this.props.pagos.MedioPago,
      gatewayPago: null,
      selectedMedioPago: null,
      fecha_actualizacion: null,
      id_operacion: null,
      pago: null,
      importe: 0,
      payment360: null,
      macroClick: null,

      pagar: {
        resultado: '',
        mensajes: [],
        fecha: '',
        nro_operacion: '',
        monto: '',
        medio_pago: '',
        estado: '',
        detalle: [],
        comprobante: '',
        user_message: '',
      },
    });
  }

  componentDidMount() {
    const usuario = this.props.user.id;
    const message = this.props.parametros.REACT_APP_PAGOS_1_MESSAGE
      ? this.props.parametros.REACT_APP_PAGOS_1_MESSAGE.valor
      : null;
    const color = this.props.parametros.REACT_APP_PAGOS_1_COLOR
      ? this.props.parametros.REACT_APP_PAGOS_1_COLOR.valor
      : 'info';

    const timeout = this.props.parametros.REACT_APP_PAGOS_1_TIMEOUT
      ? this.props.parametros.REACT_APP_PAGOS_1_TIMEOUT.valor
      : 10000;
    if (message) {
      this.setMessage({
        message: message,
        messageColor: color,
        messageTimeout: timeout,
      });
    }

    return Promise.all([
      this.props.actions.getMediosPago({}),
      this.props.actions.getPerfil({ usuario }),
    ]);
  }

  scrollToTop = () => {
    this.continuarRef.current.scrollIntoView({
      block: 'end',
    });
  };

  setErrorMessage(message) {
    this.setMessage({ messageColor: 'error', message });
  }
  setMessage = ({ message, messageColor, messageTimeout }) => {
    this.setState({ message, messageColor });
    setTimeout(this.resetMessage, messageTimeout || 6000);
  };

  resetMessage = () => {
    this.setState({ message: null, messageColor: null });
  };

  handleDescargar = () => {
    this.state.resultadoPago.comprobante !== undefined &&
      downloadFile(this.state.resultadoPago.comprobante, 'comprobante.pdf');
  };
  bindDateValue = key => {
    if (key === 'fecha_actualizacion') {
      return {
        onChange: m => {
          const fecha_actualizacion = m.format('YYYY-MM-DD');
          const params = Object.assign({
            usuario: this.props.user !== undefined ? this.props.user.id : null,
            fecha_actualizacion: fecha_actualizacion,
            deudas: this.props.pagos.resumenPrevio.map(deuda => {
              return { id: deuda.id };
            }),
            cod_gateway: this.state.selectedMedioPago.cod_gateway,
            cod_medio_pago: this.state.selectedMedioPago.cod_medio_pago,
          });


          Promise.resolve(this.props.actions.postResumenPago(params))
            .then(response => {
              if (response.id_operacion !== undefined) {
                const state = this.state;
                state.id_operacion = response.id_operacion;
                state.fecha_actualizacion = moment( response.fecha_actualizacion ,'YYYY-MM-DD');
                state.importe = response.total;
                state.payment360 = response.hasOwnProperty('payment')
                  ? response.payment
                  : null;
                state.paymentMacroClick =
                  response.hasOwnProperty('payment') &&
                  this.state.selectedMedioPago.clase === 'macroclick'
                    ? response.payment
                    : null;
                state.paymentMercadoPago =
                  response.hasOwnProperty('payment') &&
                  this.state.selectedMedioPago.clase === 'mercadopagoch'
                    ? response.payment
                    : null;
                state.paymentPagosTic =
                  response.hasOwnProperty('payment') &&
                  this.state.selectedMedioPago.clase === 'pagostic'
                    ? response.payment
                    : null;
                state.paymentEpagos =
                  response.hasOwnProperty('payment') &&
                  this.state.selectedMedioPago.clase === 'epagos'
                    ? response.payment
                    : null;
                this.setState({ state });
              }
            })
            .catch(error => {
              this.setErrorMessage(error.message);
            });
        },
        value:
          this.state[key] !== null ? this.state[key].format('DD/MM/YYYY') : '',
      };
    }
    return {
      onChange: m => {
        const state = this.state;
        state[key] = m.format('YYYY-MM-DD');
        this.setState({ state });
      },
      value: this.state[key].format('DD/MM/YYYY') || '',
    };
  };

  //Seleccion Medio Pago
  handleOnClickMedioPago = medioPago => {
    this.scrollToTop();
    ////ACORDARSE CAMBIAR!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //medioPago.clase= "PAGOS360";
    this.setState({ selectedMedioPago: medioPago });
  };

  handlerContinuar = () => {
    const params = Object.assign({
      usuario: this.props.user !== undefined ? this.props.user.id : null,
      fecha_actualizacion: null,
      deudas: this.props.pagos.resumenPrevio.map(deuda => {
        return { id: deuda.id };
      }),
      cod_gateway: this.state.selectedMedioPago.cod_gateway,
      cod_medio_pago: this.state.selectedMedioPago.cod_medio_pago,
      mi_ip: '170.81.228.182',
    });
    Promise.resolve(this.props.actions.postResumenPago(params))
      .then(response => {
        this.setState({
          fecha_actualizacion: moment(
            /*new Date(*/ response.fecha_actualizacion /*)*/,
            'YYYY-MM-DD'
          ),
          id_operacion: response.id_operacion,
          importe: response.total,
          activeStep: 2,
          payment360:
            response.hasOwnProperty('payment') &&
            this.state.selectedMedioPago.clase === 'pagos360'
              ? response.payment
              : null,
          paymentMacroClick:
            response.hasOwnProperty('payment') &&
            this.state.selectedMedioPago.clase === 'macroclick'
              ? response.payment
              : null,
          paymentMercadoPago:
            response.hasOwnProperty('payment') &&
            this.state.selectedMedioPago.clase === 'mercadopagoch'
              ? response.payment
              : null,
          paymentPagosTic:
            response.hasOwnProperty('payment') &&
            this.state.selectedMedioPago.clase === 'pagostic'
              ? response.payment
              : null,
          paymentEpagos:
            response.hasOwnProperty('payment') &&
            this.state.selectedMedioPago.clase === 'epagos'
              ? response.payment
              : null,
        });
      })
      .catch(error => {
        this.setErrorMessage(error.message);
      });
  };

  pagar = data => {
    Promise.resolve(this.props.actions.postPagar(data))
      .then(response => {
        if (
          response.reporte !== undefined &&
          response.comprobante !== undefined
        ) {
          this.setState({
            activeStep: 5,
            resultadoPago: response,
          });
          downloadFile(this.state.resultadoPago.comprobante, 'comprobante.pdf');
          return;
        }
        this.setState({
          activeStep: 3,
          resultadoPago: response,
        });
        this.state.resultadoPago.comprobante !== undefined &&
          downloadFile(this.state.resultadoPago.comprobante, 'comprobante.pdf');
      })
      .catch(error => {
        this.setState({
          activeStep: 4,
          resultadoPago: error,
        });
      });
  };

  render() {
    const { /*activeStep,*/ message, messageColor } = this.state;
    const { pagos, headerProps, classes } = this.props;
    const loading = pagos.loadingPagos;
    return (
      <div>
        <Header {...headerProps} showTitle={true}>
          <div></div>
        </Header>
        <BlockComponent blocking={loading}>
          <div className={classes.wrapper}>
            <Card className={classes.card}>
              <CardHeader></CardHeader>
              <CardBody>
                <div className={classes.root}>
                  <Grid>
                    <GridItem>
                      <div className={classes.wrapper}>{this.renderStep()}</div>
                    </GridItem>
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
          <div ref={this.continuarRef}></div>
        </BlockComponent>

        <MessageComponent
          color={messageColor}
          message={message}
          handleOnClickAceptar={this.setToken}
        />
      </div>
    );
  }

  habilitarButtonConinuar = () => {
    return !(
      this.state.selectedMedioPago !== undefined &&
      this.state.selectedMedioPago != null
    );
  };

  renderStep = () => {
    const step = this.state.activeStep;
    //const selected = { background: '#5ed9ff' }
    const { mediosPago } = this.props.pagos;
    const classes = this.props.classes;
    const alignItems = 'flex-start';
    const justify = 'flex-start';
    //const spacing = 2;
    const creditos = mediosPago.filter(i => i.tipo === 'CREDITO');
    const debitos = mediosPago.filter(i => i.tipo === 'DEBITO');
    const otros = mediosPago.filter(i => i.tipo === 'OTRO');

    const creditos_lbl = this.props.parametros.REACT_APP_PAGOS_CREDITOS
      ? this.props.parametros.REACT_APP_PAGOS_CREDITOS.valor
      : 'Tarjetas de Crédito';
    const debitos_lbl = this.props.parametros.REACT_APP_PAGOS_DEBITOS
      ? this.props.parametros.REACT_APP_PAGOS_DEBITOS.valor
      : 'Tarjetas de Débito';
    const otros_lbl = this.props.parametros.REACT_APP_PAGOS_OTROS
      ? this.props.parametros.REACT_APP_PAGOS_OTROS.valor
      : 'Pagos electrónicos';

    const importeDeuda = this.props.pagos.importeDeuda;
    const { resultadoPago } = this.state;
    const mensajeUsuario =
      resultadoPago && resultadoPago.user_message
        ? resultadoPago.user_message
        : '';

    if (importeDeuda === 0) return <Redirect to="/estado-deuda" />;

    switch (step) {
      case 1:
        return (
          <React.Fragment>
            <Grid
              container
              spacing={0}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Card>
                <CardHeader>
                  <Typography variant="h6">Total a Pagar </Typography>
                </CardHeader>
                <CardBody>
                  <Typography variant="h4">
                    $ {formatMoneda(importeDeuda)}
                  </Typography>
                </CardBody>
              </Card>
              {(creditos.length > 0 ||
                debitos.length > 0 ||
                otros.length > 0) && (
                <Card>
                  <CardBody>
                    <Grid
                      container
                      justify={justify}
                      alignItems={alignItems}
                      direction="row"
                      spacing={2}
                    >
                      {creditos.map((item, index) => {
                        return (
                          <Grid item lg='auto' xs={3} md={2} sm={2} key={index}>
                            <OpcionMedioPago
                              handleClick={() =>
                                this.handleOnClickMedioPago(item)
                              }
                              key={index}
                              icono={item.icono}
                              selected={
                                this.state.selectedMedioPago === item
                                  ? true
                                  : false
                              }
                              item={item}
                            />
                          </Grid>
                        );
                      })}

                      {debitos.map((item, index) => {
                        return (
                          <Grid item lg='auto' xs={3} md={2} sm={2} key={index}>
                            <OpcionMedioPago
                              handleClick={() =>
                                this.handleOnClickMedioPago(item)
                              }
                              key={index}
                              icono={item.icono}
                              selected={
                                this.state.selectedMedioPago === item
                                  ? true
                                  : false
                              }
                              item={item}
                            />
                          </Grid>
                        );
                      })}

                      {otros.map((item, index) => {
                        return (
                          <Grid item lg='auto' xs={3} md={2} sm={2} key={index}>
                            <OpcionMedioPago
                              handleClick={() =>
                                this.handleOnClickMedioPago(item)
                              }
                              key={index}
                              icono={item.icono}
                              selected={
                                this.state.selectedMedioPago === item
                                  ? true
                                  : false
                              }
                              item={item}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </CardBody>
                </Card>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <GridItem>
                <Button
                  disabled={this.habilitarButtonConinuar()}
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  size="lg"
                  onClick={this.handlerContinuar}
                >
                  CONTINUAR
                </Button>
              </GridItem>
            </Grid>
            {/* { 
                            mediosPago.map((mp,index) =>
                                    <button
                                        style={this.state.selectedMedioPago === mp ? selected : {}} 
                                        key = { index }
                                        onClick = { () => this.handlerSelectMedioPago(mp) }
                                    >{ mp.desc_medio_pago }</button>
                            )  
                        } 
                        { 
                            <button 
                                onClick  = { this.handlerContinuar }
                                disabled = { this.state.selectedMedioPago ? false : true }
                            >Continuar</button>
                        } */}
          </React.Fragment>
        );
      //                break;
      case 2:
        return (
          <div>
            <GatewayView
              medioPago={this.state.selectedMedioPago}
              cod_gateway={this.state.selectedMedioPago.cod_gateway}
              importe={this.state.importe}
              fecha={this.state.fecha_actualizacion}
              payment360={this.state.payment360}
              paymentMacroClick={this.state.paymentMacroClick}
              paymentMercadoPago={this.state.paymentMercadoPago}
              paymentPagosTic={this.state.paymentPagosTic}
              paymentEpagos={this.state.paymentEpagos}
              email={this.props.perfil.mail}
              cardholderName={this.props.perfil.nombre}
              id_operacion={this.state.id_operacion}
              handlerVolver={() => this.setState({ activeStep: 1 })}
              handlerPagar={this.pagar}
              bindDateValue={this.bindDateValue}
            />
          </div>
        );
      //                break;

      case 3:
        //Pago exitoso
        return (
          <div>
            <Grid container justify="center">
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Done style={{ color: 'green', fontSize: '4.6em' }} />
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="headline">
                      Operación Realizada.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={12} sm={6} md={6}>
                <ComprobantePago
                  titulo={
                    (this.props.parametros.AU_NOMBRE_MUNICIPIO &&
                      this.props.parametros.AU_NOMBRE_MUNICIPIO.valor) ||
                    'Oficina Virtual'
                  }
                  fecha={moment(
                    new Date(this.state.resultadoPago.fecha),
                    'YYYY-MM-DD HH:MM:SS'
                  ).format('DD/MM/YYYY')}
                  hora={this.state.resultadoPago.fecha.substr(11, 18)}
                  transaccion={this.state.id_operacion}
                  usuario={this.props.user.nombre}
                  importe={this.state.resultadoPago.monto}
                  fechaActualizacion={
                    this.state.resultadoPago.fechaActualizacion
                  }
                  medioPago={this.state.resultadoPago.medio_pago}
                  estado={this.state.resultadoPago.estado}
                  user_message={this.state.resultadoPago.user_message}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Button
                    color="primary"
                    onClick={this.handleDescargar}
                    /*color="transparent"*/
                  >
                    Descargar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      //                break;
      case 4:
        //Pago error
        return (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <ErrorOutline style={{ color: '#ff4740', fontSize: '4.6em' }} />
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="headline">
                Se produjo un error en la operación.
              </Typography>
            </Grid>
            <Grid item>
              {this.state.resultadoPago.error &&
                this.state.resultadoPago.error.descripcion && (
                  <Typography gutterBottom variant="headline">
                    {this.state.resultadoPago.error.descripcion}
                  </Typography>
                )}
              <Typography gutterBottom variant="headline">
                {mensajeUsuario}
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={this.handleReset}>VOLVER</Button>
            </Grid>
          </Grid>
        );
      //              break;

      case 5:
        //Pago error
        return (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Grid item>
                <Done style={{ color: 'green', fontSize: '4.6em' }} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="headline">
                Comprobante Generado.
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={this.handleReset}>VOLVER</Button>
            </Grid>
          </Grid>
        );
      //                break;

      default:
        return <React.Fragment></React.Fragment>;
      //                break;
    }
  };
}

export default connect(
  state => ({
    pagos: state.pagos,
    user: state.auth.user,
    parametros: state.parametros.data,
    perfil: state.perfil.data,
  }),
  dispatch => ({
    // mapDispatchToProps
    actions: bindActionCreators(
      {
        postResumenPago,
        postPagar,
        getMediosPago,
        getGatewayPago,
        getPerfil,
      },
      dispatch
    ),
  })
)(withStyles(styles)(Pago));
