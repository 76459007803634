import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeAPICall } from '../../../core/services/http-request';
import { downloadFile } from '../../../core/downloadService';
import { Container, Grid, Paper, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import ComprobantePago from '../../../components/ComprobantePago/ComprobantePago';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const PagoMacroclickSuccess = props => {
  const history = useHistory();
  const id_operacion = history.location.search.substr(
    history.location.search.indexOf('=') + 1
  );
  const [resultadoPago, setResultadoPago] = useState();
  const parametros = useSelector(state => {
    return state.parametros;
  });
  const user = useSelector(state => {
    console.log('state', state);
    return state.auth.user;
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const get = async () => {
      const req = {
        requestConfig: {
          method: 'get',
          params: {
            id_operacion: id_operacion,
          },
          url: '/pagos/verificar',
        },
        methodCall: '',
        needsToken: true,
      };
      const response = await makeAPICall(req);
      setResultadoPago(response.data);
      setLoading(false);
    };
    if (user) get();
  }, [user]);

  const handleClose = () => {
    window.parent.location.href = 'autogestion/estado-deuda';
  };

  const handleDescargar = async () => {
    const req = {
      requestConfig: {
        method: 'get',
        params: {
          idOperacion: id_operacion,
        },
        url: '/pagos/reporteOperacion',
      },
      methodCall: '',
      needsToken: true,
    };
    const response = await makeAPICall(req);

    if (response.statusText === 'OK' && response.data)
      downloadFile(response.data, 'comprobante.pdf');
  };

  const useStyles = makeStyles(theme => ({
    main: {
      textAlign: 'center',
      '& div.headerDiv': {
        backgroundColor: '#c7d927',
        color: 'white',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '500',
      },
      '& .MuiAvatar-root': {
        margin: '0 auto',
        width: '100px',
        height: '100px',
        backgroundColor: '#c7d927',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '100px',
      },
    },
    boton_s: {
      marginLeft: '10px',
      marginTop: '15px',
      marginBottom: '15px',
      width: '30%',
    },
    loadingDiv: {
      marginTop: '30%',
      marginLeft: '50%',
      width: '80px !important',
      height: '80px !important',
    },
  }));
  const classes = useStyles();

  if (loading) return <CircularProgress className={classes.loadingDiv} />;

  return (
    <Container>
      <Paper elevation={3} className={classes.main}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Done style={{ color: 'green', fontSize: '4.6em' }} />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="headline">
                  Operación Realizada.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <Grid container justify="center">
            <Grid item xs={12} sm={6} md={6}>
              <ComprobantePago
                titulo={
                  (parametros.AU_NOMBRE_MUNICIPIO &&
                    parametros.AU_NOMBRE_MUNICIPIO.valor) ||
                  'Oficina Virtual'
                }
                fecha={moment(
                  new Date(resultadoPago.fecha),
                  'YYYY-MM-DD HH:MM:SS'
                ).format('DD/MM/YYYY')}
                hora={resultadoPago.fecha.substr(11, 18)}
                transaccion={id_operacion}
                usuario={user.nombre}
                importe={resultadoPago.monto}
                fechaActualizacion={resultadoPago.fechaActualizacion}
                medioPago={resultadoPago.medio_pago}
                estado={resultadoPago.estado}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                id="pagos360-download-button"
                className={classes.boton_s}
                onClick={handleDescargar}
                size="lg"
              >
                Descargar
              </Button>

              <Button
                variant="contained"
                color="primary"
                id="pagos360-close-button"
                className={classes.boton_s}
                size="lg"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Container>
  );
};

export default PagoMacroclickSuccess;
