import React from 'react';
import EstadoDeudaPage from './EstadoDeudaPage.jsx';

function ProxVencimientos({ ...props }){
  props.tipoDeuda = 'prox';
  return(
    <EstadoDeudaPage
     {...props}
    />
  );
};
export default ProxVencimientos;
