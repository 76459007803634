import { createActions } from "redux-actions";

export const {getFacturas} = createActions({
    'GET_FACTURAS': (params) => ({
      API: {
        endpoint: {
          url: `/facturas`,
          method: 'get',
          params
        },
        needsToken: false,
      }
    }),
});
