import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthService from '../../core/auth/auth-actions';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Header from '../../components/Header/Header.jsx';
import BlockComponent from '../../components/Loading/BlockComponent';

import {
  getPaginaInicio,
  getPaginaInicioUsuario,
} from '../../core/parametros/parametros-actions.js';
//import { getPerfil, getTipoDocumento} from '../../core/perfil/perfil-actions';

import pageStyle from '../../components/Page/PageStyle';
import Dashboard from './Dashboard';
//import imagen from "../../assets/img/smartcity.jpg";

class HomePage extends React.Component {
  static propTypes = {
    classes: PropTypes.any,
    style: PropTypes.any,
    actions: PropTypes.any.isRequired,
    headerProps: PropTypes.any.isRequired,
  };

  state = { url: null, tipos_documentos: [] };

  bindPropsToState() {
    var url = null;

    if (AuthService.isLoggedIn())
      url =
        (this.props.parametros.AU_PAGINA_INICIO_USUARIO &&
          this.props.parametros.AU_PAGINA_INICIO_USUARIO.valor) ||
        null;
    else
      url =
        (this.props.parametros.AU_PAGINA_INICIO &&
          this.props.parametros.AU_PAGINA_INICIO.valor) ||
        null;

    this.setState({
      ...this.state,
      url,
    });
  }

  componentDidMount() {
    if (this.state.url === null) {
      if (this.props.parametros.AU_PAGINA_INICIO)
        return this.bindPropsToState();

      Promise.all([
        this.props.actions.getPaginaInicio(),
        this.props.actions.getPaginaInicioUsuario(),
      ]).then((response) => {
        return this.bindPropsToState();
      });
    }
  }

  render() {
    const { classes, headerProps } = this.props;
    const loading = this.props.parametros.loadingParametros;

    return (
      <div>
        <Header {...headerProps} showTitle={true}>
          <div></div>
        </Header>
        <BlockComponent blocking={loading}>
          <div className={classes.wrapper}>
            {this.state.url !== null && !this.props.auth.popUpLogin && (
              <iframe
                title="main-frame"
                style={{ border: '0px' }}
                src={this.state.url}
                width="100%"
                height="100%"
              />
            )}
            {this.props.user &&
              this.state.url === null &&
              !this.props.auth.popUpLogin && <Dashboard {...this.props} />}
          </div>
        </BlockComponent>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
    user: state.auth.user,
    parametros: state.parametros.data,
  }),
  (dispatch) => ({
    // mapDispatchToProps
    actions: bindActionCreators(
      {
        getPaginaInicio,
        getPaginaInicioUsuario,
      },
      dispatch
    ),
  })
)(withStyles(pageStyle)(HomePage));
