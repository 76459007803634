import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import AssignmentLateTwoToneIcon from '@material-ui/icons/AssignmentLateTwoTone';
import DateRangeTwoTone from '@material-ui/icons/DateRangeTwoTone';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';
import ShopTwoToneIcon from '@material-ui/icons/ShopTwoTone';
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone';
//import EmojiTransportationTwoToneIcon from '@material-ui/icons/EmojiTransportationTwoTone';
//import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import GroupIcon from '@material-ui/icons/Group';

import Person from '@material-ui/icons/Person';
import Message from '@material-ui/icons/Message';
//import EventNoteIcon from '@material-ui/icons/EventNote';
//import Payment from '@material-ui/icons/Payment';
//import Report from '@material-ui/icons/Report';
import Notes from '@material-ui/icons/Notes';
import ReceiptIcon from '@material-ui/icons/Receipt';
//import Store from '@material-ui/icons/Store';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
//import Toc from '@material-ui/icons/Toc';
import Note from '@material-ui/icons/Note';
//import Work from '@material-ui/icons/Work';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import HomePage from '../views/Home/HomePage';
import ProxVencimientos from '../views/EstadoDeuda/ProxVencimientos.jsx';
import Deudas from '../views/EstadoDeuda/Deudas.jsx';
import Pago from '../views/Pagos/Pagos.jsx';
import MisFacturas from '../views/Facturas/MisFacturas.jsx';
import Planes from '../views/PlanAdelanto/Planes.js';
import MyProfile from '../views/User/MyProfile';
import Cuentas from '../views/User/Cuentas';
import ComerciosResponsablesInscriptosPage from '../views/ComerciosResponsablesInscriptos/ComerciosResponsablesInscriptosPage';
import MisPagos from '../views/MisPagos/MisPagos.jsx';
//import Noticias from '../views/Noticias/Noticias.jsx';
import { getFullUrl, MODO_ANONIMO } from '../core/globals';
//import PagosPro from '../views/Proveedores/Pagos/PagosPro';
import Pago360Success from '../views/Pagos/Pagos360/Pago360Success';
import Pago360Reject from '../views/Pagos/Pagos360/Pago360Reject';
import Pago360Pending from '../views/Pagos/Pagos360/Pago360Pending';
import PagoMacroclickSuccess from '../views/Pagos/Macroclick/PagoMacroclickSuccess';
import PagoMacroclickReject from '../views/Pagos/Macroclick/PagoMacroclickReject';
import PagoMacroclickPending from '../views/Pagos/Macroclick/PagoMacroclickPending';
import PagoMPSuccess from '../views/Pagos/PagosMP/PagoMPSuccess';
import PagoMPReject from '../views/Pagos/PagosMP/PagoMPReject';
import PagoMPPending from '../views/Pagos/PagosMP/PagoMPPending';
import PagoTicSuccess from '../views/Pagos/PagosTic/PagoTicSuccess';
import PagoTicReject from '../views/Pagos/PagosTic/PagoTicReject';
import PagoTicPending from '../views/Pagos/PagosTic/PagoTicPending';
import PagoSuccess from '../views/Pagos/Epagos/PagoSuccess';
import PagoError from '../views/Pagos/Epagos/PagoError';

const defaultPath = 'inicio';

const routes = (parametros = {}) => {
  /*
		Parametros contiene la configuracion de todas las opciones del menú
		Basicamente se configuran dos opciones por opcion de menu con el siguiente formato:
		MENU_SHOW_opcion y MENU_ACTIVE_opcion que configuran si la opcion se muestra y esta activa
		respectivamente.
		Estas opciones se consulta cuando el usuario se loguea exitosamente.
		De lo contrario ó si el paremtro de configuracion no se encuentra, se consideran como false
	*/
  const MENU_DESC_CUENTAS = parametros.REACT_APP_MENU_DESC_CUENTAS
    ? parametros.REACT_APP_MENU_DESC_CUENTAS.valor
    : 'Cuentas';

  const MENU_SHOW_DDJJ =
    (parametros.REACT_APP_MENU_SHOW_DDJJ &&
      parametros.REACT_APP_MENU_SHOW_DDJJ.valor === 'S') ||
    false;
  const MENU_ACTIVE_DDJJ =
    (parametros.REACT_APP_MENU_ACTIVE_DDJJ &&
      parametros.REACT_APP_MENU_ACTIVE_DDJJ.valor === 'S') ||
    false;
  const MENU_DESC_DDJJ = parametros.REACT_APP_MENU_DESC_DDJJ
    ? parametros.REACT_APP_MENU_DESC_DDJJ.valor
    : 'Mis DDJJ';

  const MENU_SHOW_FACTURAS =
    (parametros.REACT_APP_MENU_SHOW_FACTURAS &&
      parametros.REACT_APP_MENU_SHOW_FACTURAS.valor === 'S') ||
    false;
  const MENU_ACTIVE_FACTURAS =
    (parametros.REACT_APP_MENU_ACTIVE_FACTURAS &&
      parametros.REACT_APP_MENU_ACTIVE_FACTURAS.valor === 'S') ||
    false;
  const MENU_DESC_FACTURAS = parametros.REACT_APP_MENU_DESC_FACTURAS
    ? parametros.REACT_APP_MENU_DESC_FACTURAS.valor
    : 'Mis Facturas';

  const MENU_SHOW_PLANES =
    (parametros.REACT_APP_MENU_SHOW_PLANES &&
      parametros.REACT_APP_MENU_SHOW_PLANES.valor === 'S') ||
    false;
  const MENU_ACTIVE_PLANES =
    (parametros.REACT_APP_MENU_ACTIVE_PLANES &&
      parametros.REACT_APP_MENU_ACTIVE_PLANES.valor === 'S') ||
    false;
  const MENU_DESC_PLANES = parametros.REACT_APP_MENU_DESC_PLANES
    ? parametros.REACT_APP_MENU_DESC_PLANES.valor
    : 'Plan Adelanto';

  const MENU_SHOW_VENCIMIENTOS =
    (parametros.REACT_APP_MENU_SHOW_VENCIMIENTOS &&
      parametros.REACT_APP_MENU_SHOW_VENCIMIENTOS.valor === 'S') ||
    false;
  const MENU_ACTIVE_VENCIMIENTOS =
    (parametros.REACT_APP_MENU_ACTIVE_VENCIMIENTOS &&
      parametros.REACT_APP_MENU_ACTIVE_VENCIMIENTOS.valor === 'S') ||
    false;
  const MENU_DESC_VENCIMIENTOS = parametros.REACT_APP_MENU_DESC_VENCIMIENTOS
    ? parametros.REACT_APP_MENU_DESC_VENCIMIENTOS.valor
    : 'Próx.Vencimientos';

  //const MENU_SHOW_DEUDA = parametros.REACT_APP_MENU_SHOW_DEUDA || false;
  //const MENU_ACTIVE_DEUDA = parametros.REACT_APP_MENU_ACTIVE_DEUDA || false;
  const MENU_DESC_DEUDA = parametros.REACT_APP_MENU_DESC_DEUDA
    ? parametros.REACT_APP_MENU_DESC_DEUDA.valor
    : 'Estado de Deuda';

  const MENU_SHOW_PAGOS =
    (parametros.REACT_APP_MENU_SHOW_PAGOS &&
      parametros.REACT_APP_MENU_SHOW_PAGOS.valor === 'S') ||
    false;
  const MENU_ACTIVE_PAGOS =
    (parametros.REACT_APP_MENU_ACTIVE_PAGOS &&
      parametros.REACT_APP_MENU_ACTIVE_PAGOS.valor === 'S') ||
    false;
  const MENU_DESC_PAGOS = parametros.REACT_APP_MENU_DESC_PAGOS
    ? parametros.REACT_APP_MENU_DESC_PAGOS.valor
    : 'Mis Pagos';

  return [
    {
      path: getFullUrl('inicio'),
      name: 'Inicio',
      component: HomePage,
      public: true,
      icon: HomeTwoToneIcon,
    },
    {
      path: getFullUrl('estado-deuda'),
      name: MENU_DESC_DEUDA,
      component: Deudas,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      icon: AssignmentLateTwoToneIcon,
    },
    {
      path: getFullUrl('PlanAdelanto/Planes'),
      name: MENU_DESC_PLANES,
      component: Planes,
      public: false,
      private: true,
      disabled: !MENU_ACTIVE_PLANES,
      notShowOnMenu: !MENU_SHOW_PLANES,
      icon: EventTwoToneIcon,
    },
    {
      path: getFullUrl('proximos-vencimientos'),
      name: MENU_DESC_VENCIMIENTOS,
      component: ProxVencimientos,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: !MENU_ACTIVE_VENCIMIENTOS,
      notShowOnMenu: !MENU_SHOW_VENCIMIENTOS,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('responsables-inscriptos'),
      name: MENU_DESC_DDJJ,
      component: ComerciosResponsablesInscriptosPage,
      public: false,
      private: true,
      notShowOnMenu: !MENU_SHOW_DDJJ,
      disabled: !MENU_ACTIVE_DDJJ,
      icon: PermContactCalendarIcon,
    },
    {
      path: getFullUrl('estado-deuda-pago'),
      name: 'Pagar Deuda',
      component: Pago,
      public: true,
      private: false,
      notShowOnMenu: true,
      disabled: false,
      icon: PermContactCalendarIcon,
    },
    {
      path: getFullUrl('my-profile'),
      name: 'Mi Perfil',
      component: MyProfile,
      public: true,
      private: false,
      notShowOnMenu: true,
      icon: Person,
    },
    {
      path: getFullUrl('cuentas'),
      name: MENU_DESC_CUENTAS,
      component: Cuentas,
      public: true,
      private: false,
      notShowOnMenu: true,
      icon: Person,
    },
    {
      path: getFullUrl('mis-facturas'),
      name: MENU_DESC_FACTURAS,
      component: MisFacturas,
      public: false,
      private: true,
      disabled: !MENU_ACTIVE_FACTURAS,
      notShowOnMenu: !MENU_SHOW_FACTURAS,
      icon: ReceiptIcon,
    },
    {
      path: getFullUrl('mis-pagos'),
      name: MENU_DESC_PAGOS,
      component: MisPagos,
      public: false,
      private: true,
      disabled: !MENU_ACTIVE_PAGOS,
      notShowOnMenu: !MENU_SHOW_PAGOS,
      icon: PaymentTwoToneIcon,
    },
    {
      path: getFullUrl('Pagos/Pagos360/Pago360Success'),
      name: '',
      component: Pago360Success,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/Pagos360/Pago360Pending'),
      name: '',
      component: Pago360Pending,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/Pagos360/Pago360Reject'),
      name: '',
      component: Pago360Reject,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/Macroclick/PagoMacroclickSuccess'),
      name: '',
      component: PagoMacroclickSuccess,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/Macroclick/PagoMacroclickReject'),
      name: '',
      component: PagoMacroclickReject,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/Macroclick/PagoMacroclickPending'),
      name: '',
      component: PagoMacroclickPending,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/PagosMP/PagoMPSuccess'),
      name: '',
      component: PagoMPSuccess,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/PagosMP/PagoMPReject'),
      name: '',
      component: PagoMPReject,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/PagosMP/PagoMPPending'),
      name: '',
      component: PagoMPPending,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },

    {
      path: getFullUrl('Pagos/PagosTic/PagoTicSuccess'),
      name: '',
      component: PagoTicSuccess,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/PagosTic/PagoTicReject'),
      name: '',
      component: PagoTicReject,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/PagosTic/PagoTicPending'),
      name: '',
      component: PagoTicPending,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/ePagos/PagoSuccess'),
      name: '',
      component: PagoSuccess,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      path: getFullUrl('Pagos/ePagos/PagoError'),
      name: '',
      component: PagoError,
      public: MODO_ANONIMO,
      private: !MODO_ANONIMO,
      disabled: true,
      notShowOnMenu: true,
      icon: DateRangeTwoTone,
    },
    {
      redirect: true,
      path: getFullUrl(),
      pathTo: getFullUrl(defaultPath),
      name: 'Inicio',
    },
  ];
};

export default routes;
