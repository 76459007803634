import React , { Component } from 'react';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FilterList from '@material-ui/icons/FilterList';



const styles = (theme) => ({
	root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
	chipContainer:{
		margin: theme.spacing(1),
	}
});

class FiltroPagos extends Component {

	static propTypes = {
		classes: PropTypes.any,
		style: PropTypes.any,
		filtros: PropTypes.array.isRequired,
		onClickHandler: PropTypes.func.isRequired,
	};

	state = {
    open: false,
  };

	/*
  handleDelete = (index, e) => {
		const items = Object.assign([], this.state.items);
		items.splice(index, 1);
		this.setState({items:items});
  }
	*/

  handleChange = () => {
    this.setState(state => ({ open: !state.open }));
  };

	render(){
		const { classes, filtros } = this.props
		const { open } = this.state;
  	return (
	    <div className={classes.root}>
				<List
					component="nav"
			    aria-labelledby="nested-list-subheader"
      		className={classes.root}
				>
				<ListItem button onClick={this.handleChange}>
					<ListItemIcon>
						<FilterList />
					</ListItemIcon>
					<ListItemText primary="Filtros" />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<div className={classes.chipContainer}>
						{
							filtros.map((item,index)=>{
								return (<Chip
									key={index}
					        size="small"
					        label={item.tag}
					        clickable
					        className={classes.chip}
					        color={item.active ? "primary" : "default" }
									onClick={() => this.props.onClickHandler(item, index)}
					      />)
							})
						}
					</div>
	      </Collapse>
				</List>
	    </div>
	  );
	}
}

export default (withStyles(styles)(FiltroPagos));
