import { GatewayPagoInterface } from '../interfaces/GatewayPagoInterface';
import { loadDynamicScript } from '../helpers';
import { MedioPago }  from './MedioPago';

/**
 * Referencia API Mercado Pago: 
 * https://www.mercadopago.com.ar/developers/es/plugins_sdks/sdks/official/js/
 */
export default class MercadoPagoGateway implements GatewayPagoInterface {

    sdk_url = "https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js";
    api_url = "https://api.mercadopago.com/v1/advanced_payments";
    publicKey = "";

    constructor(public_key: string) {
        
        if (!public_key)
            throw new Error("Public key no puede ser null");

        this.publicKey = public_key;
    }

    inicializar(callback: Function) {
            
        loadDynamicScript('mercadoPagoScript', this.sdk_url, 'MercadoPagoLib', () => {

            (window as any).Mercadopago.setPublishableKey(this.publicKey);
            if (callback)
                callback();

        });
    }

    tiposDocumento(callback: Function) {
        (window as any).Mercadopago.getIdentificationTypes((status: number, data: any) => {

            if (status === 200) {

                callback(data);

            } else {

                console.error('No fue posible obtener tipos de documentos.');
                callback([]);
            }
        });
    }

    /**
     * Retorna el medio de pago que puede ser consultado por el bin number
     * ó su identificador
     * @param params { bin : number } || { payment_method_id : number }
     * @param callback 
     */
    medioPago(params: any, callback: Function) {
        if (params.bin || params.payment_method_id) {

            (window as any).Mercadopago.getPaymentMethod(params, (status: number, data: any) => {

                if (status === 200) {

                    callback(status, data);

                } else {

                    callback(status, { error: 'No fue posible obtener el medio de pago.', data: data });

                }
            })
        }
    }

    emisoresTarjetas(metodoPago: string, callback: Function) {
        (window as any).Mercadopago.getIssuers(metodoPago, (status: number, data: any) => {

            if (status === 200) {
                callback(data);
            } else {
                callback([]);
            }

        });
    }

    getInstallments(bin: string, amount: Number, callback: Function) {
        (window as any).Mercadopago.getInstallments({

            "bin": bin,
            "amount": amount,

        }, (status: number, data: any) => {
            if (status === 200) {
                callback(data);
            }

        });
    }

    tokenizar(params: {}, medioPago : MedioPago, callback: Function) {
        const $form = document.createElement('form');

        for (const [key, value] of Object.entries(params)) {
            let input = document.createElement('input')
            input.setAttribute('data-checkout', key)
            input.setAttribute('value', value as string)
            $form.appendChild(input);
        }

        (window as any).Mercadopago.createToken($form, (status: number, data: any) => {

            if (status !== 200 && status !== 201) {

                callback(status, { error: 'Datos incorrectos.', data: data })

            } else {

                callback(status, data)

            }

        });
    }
}
