export const asociativeParams = (parametros) => {
  const obj = {};
  /*
    parametros.push({codigo:'AU_PAGINA_INICIO_USUARIO', valor:'https://www.intervan.com.ar/', observaciones:null})
    parametros.push({codigo:'AU_PAGINA_INICIO', valor:'http://viedma.intervan.com.ar:8000/documentacion', observaciones:null})
*/
  parametros.map((d) => {
    obj[d.codigo] = {
      valor: d.valor,
      observaciones: d.observaciones,
    };
    return null;
  });
  return obj;
};
