import { createActions } from "redux-actions";

export const {getCuentas, postCuentaDebitoAu, postFacturaElectronica, deleteFacturaElectronica} = createActions({
    'GET_CUENTAS' : (params) => ({
      API:{
        endpoint: {
          url:`/cuentas`,
          method: 'get',
          params
        }
      }
    }),
    'POST_CUENTA_DEBITO_AU' : (data) => ({
      API:{
        endpoint: {
          url:`/cuentas/debitoAutomatico`,
          method: 'post',
          data
        },
        needsToken: true,
      }
    }),
    'POST_FACTURA_ELECTRONICA' : (data) => ({
      API:{
        endpoint: {
          url:`/cuentas/facturaElectronica`,
          method: 'post',
          data
        },
        needsToken: true,
      }
    }),
    'DELETE_FACTURA_ELECTRONICA' : (data) => ({
      API:{
        endpoint: {
          url:`/cuentas/facturaElectronica`,
          method: 'delete',
          data
        },
        needsToken: true,
      }
    }),
});
