export const makeFilters = facturas => {
  const items = [];
  /*
    Los filtros de estado son fijos (Canceladas e Impagas)
    Cuando se hace el filtrado se evalua el estado en:
    1 o 3 para las "impagas"
    para el resto son "Canceladas"
  */
  facturas.forEach(f => {
    if (
      items.filter(i => i.campo === 'impuesto' && i.valor === f.impuesto)
        .length === 0
    ) {
      items.push({
        campo: 'impuesto',
        valor: f.impuesto,
        tag: f.impuesto,
        active: true,
        orden: 2,
      });
    }

    if (
      items.filter(
        i => i.campo === 'id_cuenta' && i.valor === f.cuenta.id_cuenta
      ).length === 0
    ) {
      items.push({
        campo: 'id_cuenta',
        valor: f.cuenta.id_cuenta,
        tag:
          'Cta: ' +
          (f.cuenta.alias_cuenta
            ? f.cuenta.alias_cuenta
            : f.cuenta.descripcion),
        active: true,
        orden: 1,
      });
    }

    if (
      items.filter(i => i.campo === 'desc_estado' && i.valor === f.desc_estado)
        .length === 0
    ) {
      items.push({
        campo: 'desc_estado',
        valor: f.desc_estado,
        tag: f.desc_estado,
        active: true,
        orden: 3,
      });
    }

    if (
      items.filter(i => i.campo === 'anio' && i.valor === f.anio).length === 0
    ) {
      items.push({
        campo: 'anio',
        valor: f.anio,
        tag: f.anio,
        active: true,
        orden: 4,
      });
    }
  });

  const items_1 = items
    .filter(i => i.orden === 1)
    .sort((a, b) => sortFunction(a, b));
  const items_2 = items
    .filter(i => i.orden === 2)
    .sort((a, b) => sortFunction(a, b));
  const items_3 = items
    .filter(i => i.orden === 3)
    .sort((a, b) => sortFunction(a, b));
  const items_4 = items
    .filter(i => i.orden === 4)
    .sort((a, b) => sortFunction(a, b));

  return items_1.concat(items_2, items_3, items_4);
};

function sortFunction(a, b) {
  if (a.valor < b.valor) {
    return -1;
  }
  if (a.valor > b.valor) {
    return 1;
  }
  return 0;
}

//Recupera los filtros activos.
//Se distinguen los tipos de filtros (cuentas, tipo, estado) se inicilizan los flags en false.
//Luego por cada dato se recorren los filtros
export const filtrar = (data, filtros) => {
  var flags = {
    id_cuenta: false,
    impuesto: false,
    desc_estado: false,
    anio: false,
  };
  return data.filter(dato => {
    //Si no hay filtros activos, no debe mostrarse ninguno
    // if (filtros.length == 0)
    //   return false;

    const contadores = {
      id_cuenta: 0,
      impuesto: 0,
      desc_estado: 0,
      anio: 0,
    };

    filtros.forEach(f => {
      flags[f.campo] = true;
      if (dato[f.campo] === f.valor && flags[f.campo] !== undefined)
        contadores[f.campo]++;
    });

    return (
      (flags['id_cuenta'] ? contadores['id_cuenta'] > 0 : true) &&
      (flags['impuesto'] ? contadores['impuesto'] > 0 : true) &&
      (flags['desc_estado'] ? contadores['desc_estado'] > 0 : true) &&
      (flags['anio'] ? contadores['anio'] > 0 : false)
    );
  });
};
