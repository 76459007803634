import React, { Component } from 'react';
import NodoDeuda from './NodoDeuda.jsx';
import Header from '../../components/Header/Header.jsx';
import {
  getResumenDeuda,
  postPagoResumen,
} from '../../core/deudas/deudas-actions';
import { setOffline } from '../../core/system/system-actions';
import { setResumenPrevio } from '../../core/pagos/pagos-actions';
import FiltroEstadoDeudaComponent from './FiltroEstadoDeudaComponent';
import * as AuthService from '../../core/auth/auth-actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader';
import withStyles from '@material-ui/styles/withStyles';
import pageStyle from '../../components/Page/PageStyle';
import BlockComponent from '../../components/Loading/BlockComponent';
import { browserHistory, getFullUrl } from '../../core/globals';
import { getPerfilCuentas } from "../../core/perfil/perfil-actions.js";

const styles = theme => ({
  ...pageStyle(theme),
});

class EstadoDeudaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumenDeudas: [],
    };
  }
  componentDidMount() {
    if (AuthService.isLoggedIn()) {
      const params = {
        usuario: this.props.user.id !== undefined ? this.props.user.id : null,
        tipoDeuda: this.props.tipoDeuda,
      };
      Promise.resolve(this.props.actions.getResumenDeuda(params)).then(data => {
        if(this.props.parametros?.AU_REDIRECCIONAR_SIN_CUENTAS?.valor === 'S') {
          let sinDeuda = true;
          for(const item of data) {
            sinDeuda = item.total === 0;
            if(!sinDeuda) break;
          }
          if(sinDeuda) {
            Promise.resolve(this.props.actions.getPerfilCuentas({ usuario: params.usuario }))
            .then(cuentas => {
              if(cuentas.length === 0) browserHistory.push(getFullUrl('inicio'));
            });
          }
        }
        this.setState({ resumenDeudas: data });
      });
    }
  }

  onSearch = (tipoImponible, nroImponible) => {
    const usuario = this.props.user !== undefined ? this.props.user.id : null;
    return this.props.actions.getResumenDeuda({
      tipoImponible,
      nroImponible,
      usuario,
    });
  };

  handlePagarDeudas = (deuda, importeDeuda) => {
    Promise.all([this.props.actions.setResumenPrevio({ deuda, importeDeuda })]);
    browserHistory.push(getFullUrl('estado-deuda-pago'));
  };

  render() {
    const { classes, headerProps } = this.props;
    const resumenDeudas = this.state.resumenDeudas;
    const loading = this.props.resumenDeudas.loadingResumenDeudas;

    return (
      <div>
        <Header {...headerProps} showTitle={true}>
          <div></div>
        </Header>
        <BlockComponent blocking={loading}>
          <div className={classes.wrapper}>
            {!AuthService.isLoggedIn() && (
              <div>
                <Card className={classes.card}>
                  <CardHeader>
                    <FiltroEstadoDeudaComponent onSearch={this.onSearch} />
                  </CardHeader>
                  <CardBody></CardBody>
                </Card>
              </div>
            )}
            {resumenDeudas &&
              resumenDeudas.length > 0 &&
              resumenDeudas.map((deuda, key) => {
                return (
                  <NodoDeuda
                    key={key}
                    datos={deuda}
                    handleOnClickPagar={this.handlePagarDeudas}
                  />
                );
              })}
          </div>
        </BlockComponent>
      </div>
    );
  }
}
export default connect(
  state => ({
    resumenDeudas: state.resumenDeudas,
    user: state.auth.user,
    system: state.system,
    parametros: state.parametros.data,
  }),
  dispatch => ({
    // mapDispatchToProps
    actions: bindActionCreators(
      {
        getResumenDeuda,
        postPagoResumen,
        setResumenPrevio,
        setOffline,
        getPerfilCuentas,
      },
      dispatch
    ),
  })
)(withStyles(styles)(EstadoDeudaPage));
