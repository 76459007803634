import {
  DEFAULT_COLOR, DEFAULT_CONTRAST_TEXT, ERROR_COLOR, ERROR_CONTRAST_TEXT, INFO_COLOR, INFO_CONTRAST_TEXT,
  PRIMARY_COLOR,
  PRIMARY_CONTRAST_TEXT,
  SECONDARY_COLOR,
  SECONDARY_CONTRAST_TEXT, SUCCESS_COLOR, SUCCESS_CONTRAST_TEXT
} from "./core/globals";

// https://material-ui.com/customization/theming/
// https://material-ui.com/customization/color/
export const customTheme = {
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
      main: SECONDARY_COLOR,
      contrastText: SECONDARY_CONTRAST_TEXT,
    },
    default: {
      main: DEFAULT_COLOR,
      contrastText: DEFAULT_CONTRAST_TEXT,
    },
    info: {
      main: INFO_COLOR,
      contrastText: INFO_CONTRAST_TEXT,
    },
    success: {
      main: SUCCESS_COLOR,
      contrastText: SUCCESS_CONTRAST_TEXT,
    },
    error: {
      main: ERROR_COLOR,
      contrastText: ERROR_CONTRAST_TEXT,
    },
  },
  defaultFont: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em"
  },
  containerFluid: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  drawerWidth: 260,
  drawerMiniWidth: 80,
  drawerBackground: '#FFFFFF'
};
