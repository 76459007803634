import { createActions } from "redux-actions";

export const { getMisPagos, getCuponPDF } = createActions({
    'GET_MIS_PAGOS': (params) => ({
      API: {
        endpoint: {
          url: `/pagos`,
          method: 'get',
          params
        },
        needsToken: false,
      }
    }),
    'GET_CUPON_P_D_F': (params) => ({
      API: {
        endpoint: {
          url: `/pagos/reporteOperacion`,
          method: 'get',
          params
        },
        needsToken: false,
      }
    }),
});
