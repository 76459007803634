import React/*, { Component }*/ from 'react'
import Header from "../../components/Header/Header.jsx";
import { getMisPagos, getCuponPDF } from "../../core/misPagos/misPagos-actions.js";
import { withStyles } from '@material-ui/core/styles';
import pagesStyle from "../../components/Page/PageStyle";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import Card from '@material-ui/core/Card';
import CardBody from "../../components/Card/CardBody.jsx";
import BlockComponent from "../../components/Loading/BlockComponent";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../../components/Card/CardHeader.jsx";

import Dialog from '@material-ui/core/Dialog';
import moment from "moment";
import {downloadFile} from "../../core/downloadService";
import ComprobantePago from "../../components/ComprobantePago/ComprobantePago.jsx";
import ListadoPagos from "./ListadoPagos.jsx";



const styles = (theme) => ({
	...pagesStyle(theme),
});

class MisPagos extends React.Component {

	static propTypes = {
		classes: PropTypes.any,
		style: PropTypes.any,
		actions: PropTypes.any.isRequired,
		headerProps: PropTypes.any.isRequired,
	};

	constructor(props){
		super(props)
		this.state = {
			messageColor:null,
			message:null,
			openComprobante:false,
			selectedComprobante:null,
		}
	}

	componentWillMount(){
		const params = {
      usuario: this.props.user.id,
    }
		Promise.resolve(
			this.props.actions.getMisPagos(params)
		).catch((error)=>{
			this.setErrorMessage(error.message);
		})
	}

	setErrorMessage(message) {
		this.setMessage({messageColor: 'danger', message});
	}
	setMessage = ({message, messageColor}) => {
		this.setState({message, messageColor});
		setTimeout(this.resetMessage, 6000);
	};
	resetMessage = () => {
		this.setState({message: null, messageColor: null});
	};

	verComprobante = (comprobante) => {
		this.setState({openComprobante: true, selectedComprobante: comprobante});
	}

	handleCloseModal = ()=>{
		this.setState({openComprobante:false})
	}

	handleDescargar = (idOperacion) => {
		Promise.resolve(
			this.props.actions.getCuponPDF({idOperacion})
		).then(comprobante =>{
			downloadFile(comprobante, "cuponPago.pdf");
		}).catch(error => this.setErrorMessage(error.messagge))
	}

  	render (){
		  
		const { classes, headerProps } = this.props;
		const loading = this.props.misPagos.loadingMisPagos;
		const comprobante = this.state.selectedComprobante;

		return (
		<BlockComponent blocking={loading}>
			<Header {...headerProps} showTitle={true}>
			</Header>
			<div className={classes.wrapper}>
				<Dialog fullWidth onClose={this.handleCloseModal} aria-labelledby="customized-dialog-title" open={this.state.openComprobante}>
					<div style={{margin:'10px'}}>
						{
							comprobante &&
							<Grid container justify="center">
								<Grid item xs={12}>
									<ComprobantePago
										titulo={(this.props.parametros.AU_NOMBRE_MUNICIPIO && this.props.parametros.AU_NOMBRE_MUNICIPIO.valor) || 'Oficina Virtual'}
										fecha={moment(new Date(comprobante.fecha), 'YYYY-MM-DD HH:MM:SS').format('DD/MM/YYYY')}
										hora={comprobante.fecha.substr(11,18)}
										transaccion={comprobante.nro_operacion}
										usuario={this.props.user.nombre}
										importe={comprobante.monto}
										fechaActualizacion={comprobante.fechaActualizacion}
										medioPago={comprobante.medio_pago}
										estado={comprobante.estado}
										user_message={comprobante.user_message}
									/>
								</Grid>
							</Grid>
						}
					</div>
				</Dialog>

			<Grid container >
				<Grid item xs={12}>
				<Card className={classes.card}>
					<CardHeader>

					</CardHeader>
					<CardBody>
						<ListadoPagos
							data={this.props.misPagos.data}
							onClickVerComprobante={this.verComprobante}
							download={this.handleDescargar}
						/>
					</CardBody>
				</Card>
				</Grid>
			</Grid>
			</div>
		</BlockComponent>
		)
  	}
}
export default connect(
  state => ({
    user:state.auth.user,
		misPagos : state.misPagos,
        parametros : state.parametros.data 
    //facturas: state.facturas,
		//deudas: state.resumenDeudas,
  }),
  dispatch => ({
    actions: bindActionCreators({
			getMisPagos,
			getCuponPDF,
      //getFacturas,
    }, dispatch)
  })
)(withStyles(styles)(MisPagos));
