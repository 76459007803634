import { combineReducers } from 'redux';
import auth from './auth/auth-reducer';
import tiposCuentas from './tiposCuentas/tiposCuentas.reducer';
import cuentas from './cuentas/cuentas.reducer';
import resumenDeudas from './deudas/deudas.reducer';
import cuentasComerciosRI from './cuentasComerciosRI/cuentasComerciosRI.reducer';
import DDJJComerciosRI from './DDJJComerciosRI/DDJJComerciosRI.reducer';
import configuraciones from './configuraciones/configuraciones.reducer';
import pagos from './pagos/pagos-reducer';
import perfil from './perfil/perfil-reducer';
import facturas from './facturas/facturas.reducer';
import misPagos from './misPagos/misPagos-reducer';
import parametros from './parametros/parametros.reducer';
import noticias from './noticias/noticias-reducer';
import mensajes from './mensajes/mensajes-reducer';
import proveedores from './proveedores/proveedores-reducer';
import systemReducer from './system/system-reducer';

export const getRootReducer = () => {
  return combineReducers({
    auth,
    tiposCuentas,
    cuentas,
    resumenDeudas,
    cuentasComerciosRI,
    DDJJComerciosRI,
    configuraciones,
    pagos,
    perfil,
    facturas,
    misPagos,
    parametros,
    noticias,
    mensajes,
    proveedores,
    system: systemReducer,
  });
};
