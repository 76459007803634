import {handleActions} from 'redux-actions';
import * as actionTypes from './misPagos-actions';
import {handleApiAction} from '../services/ApiMiddleware.reducer';

const initialState = {
    data: [
    ],
    loadingMisPagos: false,
    MisPagosError: false,
};


export default handleActions({
    ...handleApiAction(actionTypes.getMisPagos, {loadingField: 'loadingMisPagos', errorField: 'MisPagosError'},
    (state, {payload: {data = []}}) => {
        return {
            ...state,
            data
        };
    }),
    ...handleApiAction(actionTypes.getCuponPDF, {loadingField: 'loadingMisPagos', errorField: 'MisPagosError'},
    (state, {payload: {data = []}}) => {
        return {
            ...state,
        };
    }),

}, initialState);
