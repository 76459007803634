import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../components/Buttons/Button.jsx';
import MessageComponent from '../../components/Message/MessageComponent';
import BlockComponent from '../../components/Loading/BlockComponent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const styles = theme => {
  console.log('theme', theme);
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      minWidth: 150,
      marginTop: 10,
    },
    titulo: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      textAlign: 'center',
      padding: '5px',
      borderRadius: '5px',
      '& h5': {
        fontWeight: '900',
      },
    },
    cuenta: {
      fontWeight: '900',
      marginTop: '5px',
    },
  };
};

class FormFacturaElectronica extends Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.state = {
      message: null,
      messageColor: null,
      isLoading: false,
      mail: this.props.defaultMail,

      validate: {
        mail: {
          error: false,
          helpText: '',
        },
      },
    };
  }

  componentDidMount() {}

  componentWillMount() {
    const item = this.props.item;
    this.setState({ item });
  }

  /* Mensajes de Validacion */
  setErrorMessage(message) {
    this.setMessage({ messageColor: 'error', message });
  }

  setMessage = ({ message, messageColor }) => {
    this.setState({ message, messageColor });
    setTimeout(this.resetMessage, 6000);
  };

  resetMessage = () => {
    this.setState({ message: null, messageColor: null });
  };

  handleOnChangeModoPago = key => {};

  handleOnChangeNumber = key => {};

  validateNumber = e => {
    return true;
  };
  validateTextNumber = e => {
    return true;
  };

  bindValue = key => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value: this.state[key] || '',
      validate: "'" + this.validarItem.bind(this)(key) + "'",
    };
  };

  getItemValue = key => {
    return this.state[key] || '';
  };

  bindNumberValue = key => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value: this.state[key] === undefined ? 0 : Number(this.state[key] || ''),
    };
  };

  bindDateValue = key => {
    return {
      onChange: m => {
        const state = this.state;
        state[key] = m.format('DD/MM/YYYY');
        this.setState({ state });
      },
      value: this.state[key] || '',
    };
  };

  handleOnChangeValue(key) {
    if (this.validateNumber(key))
      return e => {
        const state = this.state;
        state[key] = e.target.value;
        state.validate[key] = this.validarItem(e.target.value, key);
        this.setState({
          ...state,
        });
      };
  }
  validarItem = (value, key) => {
    const validate = { error: false, message: '' };

    if (key === 'mail' && !this.validarMail()) {
      validate.error = true;
      validate.helpText = 'El email es incorrecto.';
    }
    return validate;
  };

  validarMail = () => {
    const state = this.state;
    if (state.mail == null) {
      return false;
    }
    return true;
  };

  habilitarDocumento() {
    return this.state.tipoDocumento !== undefined ||
      this.state.tipoDocumento != null
      ? false
      : true;
  }

  habilitarButtonAceptar = () => {
    return !this.props.habilitarButtonAceptar;
  };

  handleOnClickAceptar = () => {
    if (!this.validarMail()) {
      this.setErrorMessage('Verifique los campos.');
      return;
    }

    const data = {
      tipoCuenta: this.props.item.tipoCuenta,
      nroCuenta: this.props.item.nroCuenta,
      mail: this.state.mail,
    };
    this.props.handleOnClickAceptar(data);
  };

  render() {
    const { classes } = this.props;
    const item = this.props.item;
    const isLoading = this.state.isLoading;
    const message = this.state.message;
    const messageColor = this.state.messageColor;
    const titulo = this.props.defaultTitle;

    return (
      <Grid ref={this.rootRef}>
        <BlockComponent blocking={isLoading}>
          <div className={classes.titulo}>
            <Typography variant="h5" gutterBottom>
              {titulo}
            </Typography>
          </div>

          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.cuenta}
          >
            {this.props.item.aliasCuenta
              ? this.props.item.aliasCuenta +
                ' - ' +
                this.props.item.descripcion
              : this.props.item.descripcion}
          </Typography>

          <Divider />

          <div className={classes.root}>
            <Grid container>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Correo Electrónico"
                  id="mail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    disabled: true,
                    required: true,
                    type: 'mail',
                    ...this.bindValue('mail'),
                  }}
                />
              </GridItem>
              {this.props.mensaje && (
                <GridItem xs={12} sm={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    paragraph={true}
                    className={classes.mensaje}
                  >
                    {this.props.mensaje}
                  </Typography>
                </GridItem>
              )}
            </Grid>

            <Divider />

            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <GridItem xs={6} sm={3}>
                <FormControl fullWidth={true}>
                  <Button
                    variant="contained"
                    color="transparent"
                    fullWidth={false}
                    size="md"
                    onClick={this.props.handleOnClickCancelar}
                  >
                    VOLVER
                  </Button>
                </FormControl>
              </GridItem>

              <GridItem xs={6} sm={3}>
                <FormControl fullWidth={true}>
                  <Button
                    disabled={this.habilitarButtonAceptar()}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    size="md"
                    onClick={this.handleOnClickAceptar}
                  >
                    Adherir
                  </Button>
                </FormControl>
              </GridItem>
            </Grid>
          </div>

          <MessageComponent color={messageColor} message={message} />
        </BlockComponent>
      </Grid>
    );
  }
}

export default withStyles(styles)(FormFacturaElectronica);
