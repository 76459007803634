import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// @material-ui/core components
import ReactTable from "react-table";
import Button from "../../components/Buttons/Button";
import extendedFormsStyle from "../../components/Form/FormsStyle";

// @material-ui/icons
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import pagesStyle from "../../components/Page/PageStyle";
import tooltip from "../../components/Tooltip/TooltipStyle";
import { formatMoneda } from '../../core/helpers.js';
import Moment from 'moment';
import { getScreenType } from '../../core/services/infoScreen.js';
import { Search } from '@material-ui/icons';

const styles = (theme) => ({
	...pagesStyle(theme),
	...extendedFormsStyle,
	...tooltip,
});



class ListadoFacturas extends Component {
	static propTypes = {
		classes: PropTypes.any,
		style: PropTypes.any,
		data: PropTypes.array.isRequired,
	};

	constructor (props){
		super(props);
		this.state = {
			showAll:true,
		}
	}

	toogleShowAll() {
		const showAll = getScreenType() === 'sm' ? false : true;
		if (showAll !== this.state.showAll){
	    this.setState(prevState => ({
	      showAll
	    }));
		}
	}
	componentDidMount() {
		this.interval = setInterval(() => this.toogleShowAll(), 600);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}


	onDownloadClick(item){
		this.props.download(item.id_operacion);
	}

	defaultFilter= (filter, row, column) => {
		const id = filter.pivotId || filter.id;
		return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
	  };

  render (){
    const data = this.props.data;
    return (
      <div>

        <ReactTable
		  data={this.getItemsInfo(data)}
		  renderPageJump={this.renderPageJump}
		  showPageSizeOptions={true}
          filterable
					resizable={true}
          columns={this.getColumns()}
          defaultPageSize={10}
          showPaginationTop={false}
          showPaginationBottom
          className="-striped -highlight"
          previousText="<"
          nextText=">"
          loadingText="Cargando..."
          noDataText="No se encontraron datos"
          pageText="Página"
          ofText="de"
          rowsText="filas"
					defaultSorted={[{
					  id   : 'fecha_1vto',
					  desc : true,
					}]}
		  defaultFilterMethod= {this.defaultFilter}
        />
      </div>
    )
  }

	renderPageJump = (options) => {
		return (
			<input 
				style={{maxWidth:'60px'}}
				type="text" 
				value={options.value} 
				onBlur={options.onBlur}
				onChange={options.onChange}
				onKeyPress={options.onKeyPress}
			/>
		);
	}
  getItemsInfo = (items) => {
		//const { classes } = this.props;
		return items.map(item => {
			//item.alias_cuenta = item.cuenta.alias_cuenta;
			return ({
				...item,
				actions: (
					<div className="actions-right">
						<Button
							simple
							round
							size="sm"
							color="info"
							onClick={() => this.props.onClickVerComprobante(item.comprobante)}
						>
							<Search/>
						</Button>
						<Button
							justIcon
							round
							simple
							onClick={() => this.onDownloadClick(item)}
							className="download"
							color="primary"
						>
							<CloudDownloadIcon/>
						</Button>
					</div>
				)
			});
		});
	};

	makePlaceholderFilter(placeholder) {
		return ({filter, onChange}) => (
			<input type='text'
			  placeholder={placeholder}
			  style={{
				width: '100%'
			  }}
			  value={filter ? filter.value : ''}
			  onChange={(event) => onChange(event.target.value)}
			/>
		  )
	  }

  getColumns = () => {
		const columns = [
			{
				Header: "Transacción",
				accessor: "id_operacion",
				style: { textAlign: "left"},
				show: this.state.showAll,
				sortMethod: (a, b) => {
					return Number(a) > Number(b) ? 1 : -1;
				},
				Filter: this.makePlaceholderFilter('Filtrar'),
			},
			{
				Header: "Fecha",
				accessor: "fecha",
				style: { textAlign: "left"},
				show: this.state.showAll,
				Cell: row => (
					<span>
						{Moment(row.value).format('DD/MM/YYYY')}
					</span>
				),

				sortMethod: (a, b) => {
					return new Date(a) > new Date(b) ? 1 : -1;
				},
				Filter: this.makePlaceholderFilter('Filtrar'),
			},
			{
				Header: "Medio Pago",
				accessor: "desc_medio_pago",
				style: { textAlign: "left"},
				sortMethod: (a, b) => {
					return String(a) > String(b) ? 1 : -1;
				},
				Filter: this.makePlaceholderFilter('Filtrar'),
			},
			{
				Header: "Importe",
				accessor: "importe",
				Cell: row => (
					<div>
						{formatMoneda(row.value)}
					</div>
				),
				style:{textAlign: "right"},
				sortMethod: (a, b) => {
					return String(a) > String(b) ? 1 : -1;
				},
				Filter: this.makePlaceholderFilter('Filtrar'),
			},
		];
		columns.push({
			Header: "Descargar",
			accessor: "actions",
			sortable: false,
			filterable: false
		});

		return columns;
	};
}
export default (withStyles(styles)(ListadoFacturas));
