import { handleActions } from 'redux-actions';
import * as actionTypes from './perfil-actions';
import { handleApiAction } from '../services/ApiMiddleware.reducer';
//import {flattenCuentas} from "../cuentas/cuentas";

const initialState = {
  data: [],
  cuentas: [],
  tiposDocumento: [],
  loadingPerfil: false,
  PerfilError: false,
};
export default handleActions(
  {
    ...handleApiAction(
      actionTypes.getPerfil,
      { loadingField: 'loadingPerfil', errorField: 'PerfilError' },
      (state, { payload: { data = [] } }) => {
        return {
          ...state,
          data,
        };
      }
    ),
    ...handleApiAction(
      actionTypes.postPerfil,
      { loadingField: 'loadingPerfil', errorField: 'PerfilError' },
      (state, { payload: { data = [] } }) => {
        return {
          ...state,
          data,
        };
      }
    ),
    ...handleApiAction(
      actionTypes.getPerfilCuentas,
      { loadingField: 'loadingPerfil', errorField: 'PerfilError' },
      (state, { payload: { data = [] } }) => {
        return {
          ...state,
          cuentas: data,
        };
      }
    ),
    ...handleApiAction(
      actionTypes.postPerfilCuentas,
      { loadingField: 'loadingPerfil', errorField: 'PerfilError' },
      (state, { payload: { data = [] } }) => {
        const { cuentas } = state;
        const cuentasAct = cuentas.map(c =>
          !(c.tipoCuenta === data.tipoCuenta && c.nroCuenta === data.nroCuenta)
            ? c
            : { ...c, aliasCuenta: data.aliasCuenta }
        );
        return {
          ...state,
          cuentas: cuentasAct,
        };
      }
    ),
    ...handleApiAction(
      actionTypes.postPerfilCuenta,
      { loadingField: 'loadingPerfil', errorField: 'PerfilError' },
      (state, { payload: { data = [] } }) => {
        const { cuentas } = state;
        cuentas.unshift(data);
        return {
          ...state,
          cuentas,
        };
      }
    ),
    ...handleApiAction(
      actionTypes.deletePerfilCuenta,
      { loadingField: 'loadingPerfil', errorField: 'PerfilError' },
      (state, { payload: { data = [] } }) => {
        const { cuentas } = state;
        const newCuentas = cuentas.filter(
          c =>
            !(
              c.tipoCuenta === data.tipoCuenta && c.nroCuenta === data.nroCuenta
            )
        );
        return {
          ...state,
          cuentas: newCuentas,
        };
      }
    ),
    ...handleApiAction(
      actionTypes.getTipoDocumento,
      { loadingField: 'loadingPerfil', errorField: 'PerfilError' },
      (state, { payload: { data = [] } }) => {
        return {
          ...state,
          tiposDocumento: data,
        };
      }
    ),
  },
  initialState
);
