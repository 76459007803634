
export const formatNumber = (num) => {
  return (Math.round(num * 100) / 100).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const formatMoneda = (num,digits) => {
  return Intl.NumberFormat("de-AR",{
    style:'decimal',
    minimumFractionDigits: digits ? digits : 2,
  }).format(num);
}

export const stripTag = (string) =>{
  return string.replace(/(<([^>]+)>)/ig,"");
}

export const loadDynamicScript = (scriptId, src, nombre, callback) => {
  
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src; 
    script.id = nombre; 
    document.body.appendChild(script);

    script.onload = (script) => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

