import React from 'react';
import EstadoDeudaPage from './EstadoDeudaPage.jsx';

function Deudas({ ...props }){
  props.tipoDeuda = 'deuda';
  return(
    <EstadoDeudaPage
     { ...props}
    />
  );
};
export default Deudas;
