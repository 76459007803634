import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardBody from '../../components/Card/CardBody.jsx';
import Button from '../../components/Buttons/Button.jsx';
import Typography from '@material-ui/core/Typography';
import { Search } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import { formatMoneda } from '../../core/helpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import EmojiTransportationTwoToneIcon from '@material-ui/icons/EmojiTransportationTwoTone';
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';

require('./stiloCard.css');

const styles = {
  card: {
    minWidth: 275,
    width: '100%',
  },
  danger: {
    color: '#ff2d00',
    fontSize: '20px',
    fontFamily: 'sans-serif',
  },
};

const CardDeuda = props => {
  const { classes, data, deuda, importeDeuda, handleOnClickPagar } = props;

  const parametros = useSelector(state => {
    return state.parametros.data;
  });
  const [checkedTodos, setCheckTodos] = useState(true);

  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const HomeIcon = props => {
    const iconoCuentas =
      parametros.REACT_APP_MENU_ICON_CUENTAS &&
      parametros.REACT_APP_MENU_ICON_CUENTAS.valor === 'municipios' ? (
        <EmojiTransportationTwoToneIcon />
      ) : parametros.REACT_APP_MENU_ICON_CUENTAS &&
        parametros.REACT_APP_MENU_ICON_CUENTAS.valor === 'creditos' ? (
        <BallotTwoToneIcon />
      ) : (
        <AccountTreeTwoToneIcon />
      );

    return <span style={{ paddingRight: '10px' }}>{iconoCuentas}</span>;
  };

  const checkTodos = e => {
    setCheckTodos(!checkedTodos);
    props.handleCheckBoxTodos(!checkedTodos);
  };

  const handleClickDetalle = (det, id_padre, e) => {
    det.id_padre = id_padre;
    props.handleSeleccion(det);
  };

  return (
    <Card className={classes.card}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <Typography className={classes.pos} color="textSecondary">
              <span style={{ display: 'inline-flex' }}>
                {HomeIcon(props)}
                <span>{data.tipo.descripcion}</span>
              </span>
            </Typography>
          </GridItem>
          {data.descripcion1 !== '' && (
            <GridItem xs={12}>
              <Typography variant="h6" component="h3">
                {data.descripcion1}
              </Typography>
            </GridItem>
          )}
          {data.descripcion2 !== '' && (
            <GridItem xs={12}>
              <Typography variant="h6" component="h4">
                {data.descripcion2}
              </Typography>
            </GridItem>
          )}
          {data.total !== '' && (
            <GridItem xs={12} sm={9}>
              <Typography variant="h6" component="h2" color="textSecondary">
                <span className={classes.danger}>
                  {data.tipo.detalle !== undefined &&
                  data.tipo.detalle === 'res'
                    ? 'Total $ ' + formatMoneda(data.total)
                    : 'Total $ ' + formatMoneda(importeDeuda)}
                </span>
              </Typography>
            </GridItem>
          )}

          <GridItem xs={12} sm={3}>
            <Button
              disabled={importeDeuda === 0}
              fullWidth={true}
              color="primary"
              onClick={handleOnClickPagar}
            >
              Pagar
            </Button>
          </GridItem>

          {/* ---------- Detalle --------- */}

          <GridItem xs={12}>
            <List dense={true}>
              {/* Item titulo  */}
              <ListItem
                // divider
                alignItems="flex-start"
                dense
                divider
              >
                <ListItemText primary="Detalle" />
                {data.tipo.detalle !== undefined &&
                  data.tipo.detalle === 'det' && (
                    <>
                      {parametros.APP_DEUDAS_DET?.value === 'S' &&
                        windowSize.width > 600 && (
                          <>
                            <ListItemText primary="Deuda Original" />
                            <ListItemText primary="Intereses" />
                          </>
                        )}

                      <ListItemSecondaryAction>
                        <FormControlLabel
                          value="checkTodos"
                          control={
                            <Checkbox
                              color="primary"
                              checked={checkedTodos}
                              onClick={checkTodos}
                            />
                          }
                          label="Todos"
                          labelPlacement="start"
                        />
                      </ListItemSecondaryAction>
                    </>
                  )}
              </ListItem>
              {data.detalle !== undefined &&
                data.detalle.map((det, index) => {
                  return data.tipo.detalle !== undefined &&
                    data.tipo.detalle === 'det' ? (
                    <ListItem key={index} dense>
                      <ListItemText
                        secondary={det.descripcion1}
                        style={{
                          maxWidth:
                            parametros.APP_DEUDAS_DET?.value === 'S' &&
                            windowSize.width > 600
                              ? '30%'
                              : 'inherit',
                        }}
                      />
                      {parametros.APP_DEUDAS_DET?.value === 'S' &&
                        windowSize.width > 600 &&
                        det.capital && (
                          <>
                            <ListItemText
                              secondary={'$ ' + formatMoneda(det.capital)}
                              style={{
                                textAlign: 'end',
                                maxWidth: '15%',
                              }}
                            />
                            <ListItemText
                              secondary={'$ ' + formatMoneda(det.recargo)}
                              style={{
                                textAlign: 'center',
                              }}
                            />
                          </>
                        )}
                      <div
                        style={{
                          minWidth: '95px',
                          textAlign: 'right',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          color: '#fb5f4e',
                        }}
                      >
                        $ {formatMoneda(det.total)}{' '}
                      </div>
                      <ListItemSecondaryAction>
                        <Checkbox
                          className=""
                          edge="end"
                          color="primary"
                          checked={deuda.includes(det)}
                          onClick={() => props.handleCheckBox(det)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : (
                    <ListItem key={index} dense>
                      <ListItemText secondary={det.descripcion1} />
                      <div
                        style={{
                          minWidth: '95px',
                          textAlign: 'right',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          color: '#fb5f4e',
                        }}
                      >
                        $ {formatMoneda(det.total)}
                      </div>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="search"
                          color="primary"
                          onClick={e =>
                            handleClickDetalle(det, props.data.id, e)
                          }
                        >
                          <Search />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default withStyles(styles)(CardDeuda);
