import React, { Component } from 'react';
import Header from '../../components/Header/Header.jsx';
//import {getResumenDeuda, postPagoResumen} from "../../core/deudas/deudas-actions";
//import * as AuthService from '../../core/auth/auth-actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPerfil,
  getTipoDocumento,
  postPerfil,
} from '../../core/perfil/perfil-actions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardFooter from '../../components/Card/CardFooter.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import {
  FormControl,
  MenuItem,
  Input,
  InputAdornment,
  TextField,
} from '@material-ui/core';
//import Moment from 'moment';
import Button from '../../components/Buttons/Button.jsx';
//import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MessageComponent from '../../components/Message/MessageComponent';
import BlockComponent from '../../components/Loading/BlockComponent';
import pageStyle from '../../components/Page/PageStyle';

import { reNombre } from '../../core/expRegulares.js';
import countries from 'countries-list';
import Axios from 'axios';
import MaskedInput from 'react-text-mask';
import PhoneIcon from '@material-ui/icons/Phone';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = theme => ({
  ...pageStyle(theme),
});

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: null,
      messageColor: null,
      tipos_documentos: [],
      paises: [{ id: 'AR', descripcion: 'Argentina' }],
      provincias: [],
      localidades: [],
      data: {
        nombre: '',
        tipo_documento: '',
        nro_documento: '',
        mail: '',
        pais: '',
        provincia: '',
        localidad: '',
        cod_postal: '',
        calle: '',
        nro: '',
        telefono1: '',
        telefono2: '',
        campo1: '',
        campo2: '',
        AU_PERFIL_LBL_CAMPO3: '',
      },
      validate: {
        nombre: {
          error: false,
          helpText: '',
        },
        nro_documento: {
          error: false,
          helpText: '',
        },
      },
      openAgregar: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      loading: nextProps.loadingPerfil,
      cuentas: nextProps.data.cuentas,
      data: nextProps.data.data,
    });
  }

  setErrorMessage(message) {
    this.setMessage({ messageColor: 'error', message });
  }

  setMessage = ({ message, messageColor }) => {
    this.setState({ message, messageColor });
    setTimeout(this.resetMessage, 6000);
  };

  resetMessage = () => {
    this.setState({ message: null, messageColor: null });
  };

  /*componentWillMount() {
    const usuario = this.props.user.id;
    Promise.all([
      this.props.actions.getPerfil({ usuario }),
      this.props.actions.getTipoDocumento(),
    ]).then(data => {
      const state = this.state;
      //state.data = data[0] || {nombre:'', tipo_documento:'', nro_documento:'', mail:''};
      state.tipos_documentos = data[1] || [];
      this.setState({
        ...state,
      });

      console.log('STATE 2', this.state);
    });
  }*/

  bindValue = key => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value: this.state.data[key] || '',
    };
  };

  bindNumberValue = key => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value:
        this.state.data[key] === undefined
          ? 0
          : Number(this.state.data[key] || ''),
    };
  };

  bindDateValue = key => {
    return {
      onChange: m => {
        const state = this.state;
        state.data[key] = m.format('DD/MM/YYYY');
        this.setState({ state });
      },
      value: this.state.data[key] || '',
    };
  };

  handleOnChangeValue(key) {
    return e => {
      const state = this.state;
      state.data[key] = e.target.value;
      state.validate[key] = this.validarItem(e.target.value, key);
      this.setState({
        ...state,
      });
    };
  }

  handleChangePais = data => {
    const state = this.state;
    state.data['pais'] = data;
    state.data['provincia'] = '';
    state.data['localidad'] = '';

    this.setState({
      ...state,
    });
  };

  handleChangeProvincia = nombre => {
    if (!nombre || nombre === '') return;

    this.setState({ ...this.state, loading: true });
    const state = { ...this.state };
    var id = this.state.provincias.find(p => p.nombre === nombre).id2;
    state.data['provincia'] = nombre;
    state.data['localidad'] = '';
    state.loading = true;
    this.setState({
      ...state,
    });

    Axios.get(
      `https://apis.datos.gob.ar/georef/api/localidades?provincia=${id}&campos=id,nombre,municipio&max=2000`
    ).then(resp => {
      let locs = resp.data.localidades;
      locs.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
      //locs = locs.filter(l => l.categoria.indexOf('compuesta') === -1);
      /*locs = locs.filter(l => {
        if (l.categoria.indexOf('Entidad') === -1) return l;
        else {
          if (l.municipio.id !== null) return l;
        }
      });*/
      //console.log('locs', locs);
      /*var aux = locs.reduce((acc, current) => {
        const x = acc.find(item =>
          item.municipio.nombre !== null && current.municipio.nombre !== null
            ? item.nombre +
                ' - Municipio: ' +
                item.municipio.nombre.toUpperCase() ===
              current.nombre +
                ' - Municipio: ' +
                current.municipio.nombre.toUpperCase()
            : false
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);*/

      var aux = locs.map(l => {
        return {
          id: l?.municipio?.nombre
            ? l.nombre + ' - Municipio: ' + l.municipio.nombre
            : l.nombre,
          nombre: l?.municipio?.nombre
            ? l.nombre + ' - Municipio: ' + l.municipio.nombre
            : l.nombre,
          municipio: l.municipio,
        };
      });

      this.setState({
        ...state,
        localidades: aux,
        loading: false,
      });
    });
  };

  handleTelefono = (e, tel) => {
    const state = this.state;
    let aux = e.target.value
      .replace('(', '')
      .replace(')', '')
      .replace('+', '')
      .replace(/\s/g, '');
    state.data[tel] = aux;
  };

  validarItem = (value, key) => {
    const validate = { error: false, message: '' };
    if (key === 'nombre') {
      validate.error = !reNombre.test(value);
      validate.error
        ? (validate.helpText = 'Nombre inválido.')
        : (validate.helpText = '');
    }
    return validate;
  };

  validateNumber = e => {
    return true;
  };

  validarNombre = (key, value) => {
    return {
      validate: reNombre.test(value),
      message: 'Nombre no valido.',
    };
  };

  validarPerfil = () => {
    //		const data = this.state.data;
    const errors = [];
    if (!reNombre.test(this.state.data.nombre)) {
      errors['nombre'] = 'El valor no es valido.';
    }
    return errors;
  };

  habilitarDocumento() {
    return this.state.data.tipoDocumento !== undefined ||
      this.state.data.tipoDocumento != null
      ? false
      : true;
  }
  onSavePerfil = e => {
    this.setState({ ...this.state, loading: true });
    
    var errores = 0;
    Object.values(this.state.validate).map(v => (v.error ? errores++ : true));
    if (errores > 0) {
      this.setErrorMessage('Hay campos incorrectos.');
      return;
    }

    if (this.props?.parametros?.AU_PERFIL_EXTENDIDO?.valor === 'S' && this.state.data.pais === 'AR' && !this.state.data.provincia) {
      this.setErrorMessage('Debe ingresar una provincia');
      return;
    }

    const perfil = {
      usuario: this.props.user.id,
      nombre: this.state.data.nombre,
      mail: this.state.data.mail,
      tipoDocumento: this.state.data.tipoDocumento,
      nroDocumento: this.state.data.nroDocumento,
      pais: this.state.data.pais || 'AR',
      provincia: this.state.data.provincia,
      localidad: this.state.data.localidad,
      cod_postal: this.state.data.cod_postal,
      calle: this.state.data.calle,
      nro: this.state.data.nro,
      telefono1: this.state.data.telefono1,
      telefono2: this.state.data.telefono2,
      campo1: this.state.data.campo1,
      campo2: this.state.data.campo2,
      campo3: this.state.data.campo3,
    };
    this.setState({ ...this.state, loading: true });

    Promise.all([this.props.actions.postPerfil(perfil)])
      .then(data => {
        this.setMessage({
          messageColor: 'success',
          message: 'Perfil actualizado.',
        });
        this.setState({ ...this.state, loading: false });
      })
      .catch(error => {
        this.setErrorMessage(error.message || error);
        this.setState({ ...this.state, loading: false });
      });
    return false;
  };

  componentDidMount = () => {
    const usuario = this.props.user.id;
    this.setState({ ...this.state, loading: true });
    Promise.all([
      this.props.actions.getPerfil({ usuario }),
      this.props.actions.getTipoDocumento(),
    ]).then(data => {
      //const state = { ...this.state };
      //console.log('DATA', data);
      //state.data = data[0] || {nombre:'', tipo_documento:'', nro_documento:'', mail:''};
      //state.tipos_documentos = data[1] || [];
      this.setState({
        ...this.state,
        data: data[0],
        tipos_documentos: data[1],
      });

      Axios.get(
        'https://apis.datos.gob.ar/georef/api/provincias?campos=id,nombre'
      ).then(resp => {
        let provs = resp.data.provincias;
        provs.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));

        var aux = provs.map(p => {
          return { id: p.nombre, nombre: p.nombre, id2: p.id };
        });

        this.setState({ ...this.state, provincias: aux });

        if (this.state.data.pais === 'AR' && this.state.data.provincia) {
          var id = this.state.data.provincia;

          Axios.get(
            `https://apis.datos.gob.ar/georef/api/localidades?provincia=${id}&campos=id,nombre,municipio&max=2000`
          ).then(resp => {
            let locs = resp.data.localidades;
            locs.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));

            /*var aux = locs.reduce((acc, current) => {
              const x = acc.find(item =>
                item.municipio.nombre !== null &&
                current.municipio.nombre !== null
                  ? item.nombre +
                      ' - Municipio: ' +
                      item.municipio.nombre.toUpperCase() ===
                    current.nombre +
                      ' - Municipio: ' +
                      current.municipio.nombre.toUpperCase()
                  : false
              );
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);*/

            var aux = locs.map(l => {
              return {
                id: l?.municipio?.nombre
                  ? l.nombre + ' - Municipio: ' + l.municipio.nombre
                  : l.nombre,
                nombre: l?.municipio?.nombre
                  ? l.nombre + ' - Municipio: ' + l.municipio.nombre
                  : l.nombre,
                municipio: l.municipio,
              };
            });

            this.setState({
              ...this.state,
              localidades: aux,
              loading: false,
            });
          });
        } else this.setState({ ...this.state, loading: false });
      });
    });
  };

  render() {
    const { classes, headerProps, parametros } = this.props;
    const { message, messageColor } = this.state;
    const countriesList = Object.keys(countries.countries);
    const provinciasList = Object.keys(this.state.provincias).map(
      p => this.state.provincias[p].id
    );
    const localidadesList = Object.keys(this.state.localidades).map(
      p => this.state.localidades[p].id
    );

    const styles = {
      defaultSelect: {
        marginTop: '9px',
      },
      defaultCombo: {
        marginTop: '9px',
      },
    };

    if (
      this.state.tipos_documentos.length === 0 ||
      provinciasList.length === 0 ||
      (this.state.data.pais === 'AR' &&
        this.state.data.provincia &&
        this.state.data.localidad &&
        localidadesList.length === 0)
    )
      return (
        <BlockComponent blocking={true}>
          <Header {...headerProps} showTitle={true}>
            <div></div>
          </Header>
        </BlockComponent>
      );

    return (
      <BlockComponent blocking={this.state.loading}>
        <Header {...headerProps} showTitle={true}>
          <div></div>
        </Header>
        <MessageComponent color={messageColor} message={message} />
        <div className={classes.wrapper}>
          <Grid container spacing={4}>
            <Card className={classes.card}>
              <CardHeader>Datos Personales</CardHeader>
              <CardBody>
                <form autoComplete="off" className={classes.root}>
                  <Grid container>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        labelText="Apellido y Nombre"
                        helpText={this.state.validate.nombre.helpText}
                        error={this.state.validate.nombre.error}
                        id="nombre"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          type: 'text',
                          ...this.bindValue('nombre'),
                        }}
                      />
                    </GridItem>
                  </Grid>

                  <Grid container>
                    <GridItem xs={12} sm={4}>
                      <FormControl
                        className={classes.formControl}
                        fullWidth={true}
                        style={styles.defaultSelect}
                      >
                        <InputLabel htmlFor="tipoDocumento">
                          Tipo Documento
                        </InputLabel>
                        <Select
                          //native {...this.bindValue('tipoDocumento')}
                          name="tipoDocumento"
                          inputProps={{
                            id: 'tipoDocumento',
                          }}
                          className={classes.selectEmpty}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          onChange={this.handleOnChangeValue.bind(this)(
                            'tipoDocumento'
                          )}
                          value={
                            this.state.data.tipoDocumento
                              ? this.state.data.tipoDocumento
                              : ''
                          }
                        >
                          <MenuItem key={-1} value={''}>
                            {' '}
                          </MenuItem>
                          {this.state.tipos_documentos.map((tipo, index) => {
                            return (
                              <MenuItem key={index} value={tipo.tipoDocumento}>
                                {' '}
                                {tipo.descripcion}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={4}>
                      <CustomInput
                        labelText="Número"
                        id="nroDocumento"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: this.habilitarDocumento(),
                          required: true,
                          type: 'number',
                          ...this.bindValue('nroDocumento'),
                        }}
                      />
                    </GridItem>
                  </Grid>

                  <Grid container>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        labelText="Correo Electrónico"
                        id="mail"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: true,
                          required: true,
                          type: 'mail',
                          ...this.bindValue('mail'),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    {parametros?.AU_PERFIL_EXTENDIDO?.valor === 'S' && (
                      <>
                        <GridItem xs={12} sm={6}>
                          <Autocomplete
                            id="pais"
                            name="pais"
                            options={countriesList}
                            getOptionLabel={option =>
                              countries.countries[option].name
                            }
                            value={this.state.data.pais}
                            onChange={(event, newValue) =>
                              this.handleChangePais(newValue)
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                style={styles.defaultCombo}
                                label="Pais"
                                margin="normal"
                              />
                            )}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          {this.state.data.pais === 'AR' ? (
                            <Autocomplete
                              id="provincia"
                              name="provincia"
                              required
                              options={provinciasList}
                              getOptionLabel={option => {
                                var prov = this.state.provincias.find(
                                  p => p.id === option
                                );

                                if (prov) return prov.nombre;
                                else return '---';
                              }}
                              value={this.state.data.provincia || null}
                              onChange={(event, newValue) =>
                                this.handleChangeProvincia(newValue)
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  style={styles.defaultCombo}
                                  label="Provincia"
                                  margin="normal"
                                />
                              )}
                            />
                          ) : (
                            <CustomInput
                              labelText="Provincia"
                              id="provincia"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                type: 'text',
                                ...this.bindValue('provincia'),
                              }}
                            />
                          )}
                        </GridItem>

                        <GridItem xs={12} sm={9}>
                          {this.state.data.pais === 'AR' ? (
                            <Autocomplete
                              id="localidad"
                              name="localidad"
                              options={localidadesList}
                              getOptionLabel={option => {
                                var loc = this.state.localidades.find(
                                  l => l.id === option
                                );

                                if (loc) return loc.nombre;
                                else return '---';
                              }}
                              value={this.state.data.localidad || null}
                              onChange={(event, newValue) =>
                                this.setState({
                                  ...this.state,
                                  data: {
                                    ...this.state.data,
                                    localidad: newValue,
                                  },
                                })
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  style={styles.defaultCombo}
                                  label="Localidad"
                                  margin="normal"
                                />
                              )}
                            />
                          ) : (
                            <CustomInput
                              labelText="Localidad"
                              id="localidad"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                type: 'text',
                                ...this.bindValue('localidad'),
                              }}
                            />
                          )}
                        </GridItem>

                        <GridItem xs={12} sm={3}>
                          <CustomInput
                            labelText="Codigo Postal"
                            error={this.state.validate.nombre.error}
                            id="cod_postal"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              required: true,
                              type: 'text',
                              ...this.bindValue('cod_postal'),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={8}>
                          <CustomInput
                            labelText="Calle"
                            id="calle"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              required: true,
                              type: 'text',
                              ...this.bindValue('calle'),
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={2}>
                          <CustomInput
                            labelText="Nro"
                            id="nro"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              required: true,
                              type: 'text',
                              ...this.bindValue('nro'),
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={4}>
                          <FormControl>
                            <InputLabel htmlFor="telefono1">
                              Telefono1
                            </InputLabel>
                            <Input
                              value={this.state.data.telefono1}
                              onChange={e =>
                                this.handleTelefono(e, 'telefono1')
                              }
                              name="textmask"
                              id="telefono1"
                              inputComponent={TextMaskCustom}
                              startAdornment={
                                <InputAdornment position="start">
                                  <PhoneIcon /> +
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={4}>
                          <FormControl>
                            <InputLabel htmlFor="telefono2">
                              Telefono2
                            </InputLabel>
                            <Input
                              value={this.state.data.telefono2}
                              onChange={e =>
                                this.handleTelefono(e, 'telefono2')
                              }
                              name="textmask"
                              id="telefono2"
                              inputComponent={TextMaskCustom}
                              startAdornment={
                                <InputAdornment position="start">
                                  <PhoneIcon /> +
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </GridItem>
                      </>
                    )}
                    {parametros &&
                    parametros.AU_PERFIL_CAMPO1 &&
                    parametros.AU_PERFIL_CAMPO1.valor === 'S' ? (
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          labelText={
                            parametros.AU_PERFIL_LBL_CAMPO1
                              ? parametros.AU_PERFIL_LBL_CAMPO1.valor
                              : 'Campo 1'
                          }
                          id="campo1"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: 'text',
                            ...this.bindValue('campo1'),
                          }}
                        />
                      </GridItem>
                    ) : null}

                    {parametros.AU_PERFIL_CAMPO2 &&
                    parametros.AU_PERFIL_CAMPO2.valor === 'S' ? (
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          labelText={
                            parametros.AU_PERFIL_LBL_CAMPO2
                              ? parametros.AU_PERFIL_LBL_CAMPO2.valor
                              : 'Campo 2'
                          }
                          id="campo2"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: 'text',
                            ...this.bindValue('campo2'),
                          }}
                        />
                      </GridItem>
                    ) : null}

                    {parametros.AU_PERFIL_CAMPO3 &&
                    parametros.AU_PERFIL_CAMPO3.valor === 'S' ? (
                      <GridItem xs={12} sm={6}>
                        <CustomInput
                          labelText={
                            parametros.AU_PERFIL_LBL_CAMPO3
                              ? parametros.AU_PERFIL_LBL_CAMPO3.valor
                              : 'Campo 3'
                          }
                          id="campo3"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: 'text',
                            ...this.bindValue('campo3'),
                          }}
                        />
                      </GridItem>
                    ) : null}
                  </Grid>
                  <GridContainer
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-start"
                  >
                    <GridItem>
                      <Button onClick={this.onSavePerfil} color="primary">
                        Guardar
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Grid>
        </div>
      </BlockComponent>
    );
  }
}
export default connect(
  state => ({
    user: state.auth.user,
    data: state.perfil,
    parametros: state.parametros.data,
  }),
  dispatch => ({
    // mapDispatchToProps
    actions: bindActionCreators(
      {
        getPerfil,
        getTipoDocumento,
        postPerfil,
      },
      dispatch
    ),
  })
)(withStyles(styles)(MyProfile));
