import { createActions } from "redux-actions";

export const { postCasesCase, postCases, getCasesReferences, getCasesDetails, getCasesFile, postCasesMessege, postCasesClose } = createActions({
    'POST_CASES_CASE': (params) => ({
      API: {
        endpoint: {
          url: `/cases/case`,
          method: 'post',
          params
        },
        needsToken: false,
      }
    }),
    'POST_CASES': (params) => ({
        API: {
            endpoint: {
                url: `/cases`,
                method: 'post',
                params
            },
            needsToken: false,
        }
    }),
    'GET_CASES_REFERENCES': (params) => ({
        API: {
          endpoint: {
            url: `/cases/references`,
            method: 'get',
            params
          },
          needsToken: false,
        }
      }),
    'GET_CASES_DETAILS': (params) => ({
        API: {
            endpoint: {
            url: `/cases/details`,
            method: 'get',
            params
            },
            needsToken: false,
        }
        }),
    'POST_CASES_FILE': (params) => ({
        API: {
            endpoint: {
            url: `/cases/file`,
            method: 'post',
            params
            },
            needsToken: false,
        }
        }),
    'POST_CASES_MESSAGE': (params) => ({
        API: {
            endpoint: {
            url: `/cases/message`,
            method: 'post',
            params
            },
            needsToken: false,
        }
        }),
    'POST_CASES_CLOSE': (params) => ({
        API: {
            endpoint: {
            url: `/cases/close`,
            method: 'post',
            params
            },
            needsToken: false,
        }
        }),
});
