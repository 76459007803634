import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid, Paper, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

const PagoMPReject = props => {

  const parametros = useSelector(state => {
    return state.parametros;
  });

  const mensaje =
    'Se ha producido un error al realizar el pago. Intente más tarde';

  const handleClose = () => {
    window.parent.location.href = 'autogestion/estado-deuda';
  };

  const useStyles = makeStyles(theme => ({
    main: {
      textAlign: 'center',
      '& div.headerDiv': {
        backgroundColor: 'tomato',
        color: 'white',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '500',
      },
      '& .MuiAvatar-root': {
        margin: '0 auto',
        width: '100px',
        height: '100px',
        backgroundColor: 'tomato',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '100px',
      },
      '& .error-message': {
        color: 'grey',
      },
      '& .boton_pagar': {
        backgroundColor: 'tomato',
        marginBottom: '10px',
      },
    },
    loadingDiv: {
      marginTop: '30%',
      marginLeft: '50%',
      width: '80px !important',
      height: '80px !important',
    },
  }));
  const classes = useStyles();

  //if (loading) return <CircularProgress className={classes.loadingDiv} />;

  return (
    <Container>
      <Paper elevation={3} className={classes.main}>
        <Grid container justify="center" className="error-message">
          <Grid item xs={12}>
            <ErrorOutline style={{ color: '#ff4740', fontSize: '4.6em' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="headline">
              Se produjo un error en la operación.
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="headline">
              {parametros.APP_ERROR_PAGO ? parametros.APP_ERROR_PAGO : mensaje}
            </Typography>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          id="pagos360-close-button"
          className="boton_pagar"
          size="large"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Paper>
    </Container>
  );
};
export default PagoMPReject;
