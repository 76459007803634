import React from 'react';
import { Container, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import logoSmall from '../assets/img/logo-small.png';

const ServiceOffline = () => {
  const parametros = useSelector(state => {
    return state.parametros.data;
  });
  const mensaje = parametros.OV_APP_SYSTEM_OFFLINE
    ? parametros.OV_APP_SYSTEM_OFFLINE.valor
    : 'La Oficina Virtual está temporalmente fuera de servicio. Por favor ingrese más tarde';

  const logo = parametros.OV_APP_LOGO_URL
    ? parametros.OV_APP_LOGO_URL.valor
    : logoSmall;

  const useStyles = makeStyles(theme => ({
    main: {
      width: '100%',
      marginTop: '5%',
      marginBottom: '5%',
      padding: '20px',
      borderRadius: '0px 50px 0px 50px',
      textAlign: 'center',
      '& img': {
        width: '20%',
      },
      '& h1': {
        fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
        fontWeight: '900',
        color: 'lightslategrey',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Container>
      <Paper elevation={3} className={classes.main}>
        <img src={logo} alt="logo" />
        <h1>{mensaje}</h1>
      </Paper>
    </Container>
  );
};

export default ServiceOffline;
