import { makeAPICall } from './services/http-request';

export const getLogo = async () => {
    const req = {
        requestConfig: {
          method: 'get',
          params: {
            parametro: 'OV_APP_LOGO_URL',
          },
          url: '/servicios/parametros',
        },
        methodCall: '',
        needsToken: false,
      };
      const response = await makeAPICall(req);
      if (response.data) {
        return response.data[0]["valor"];
      }
      return null;
    };

    export const getColor = async () => {
        const req = {
            requestConfig: {
              method: 'get',
              params: {
                parametro: 'OV_APP_COLOR_PRINCIPAL',
              },
              url: '/servicios/parametros',
            },
            methodCall: '',
            needsToken: false,
          };
          const response = await makeAPICall(req);
          if (response.data) {
            return response.data[0]["valor"];
          }
          return null;
        };
