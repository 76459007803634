import { GatewayPagoInterface } from '../interfaces/GatewayPagoInterface';
import { loadDynamicScript } from '../helpers';
import { MedioPago } from './MedioPago';

/**
 * Referencia API Mercado Pago: 
 * https://www.mercadopago.com.ar/developers/es/plugins_sdks/sdks/official/js/
 */
export default class DecidirGateway implements GatewayPagoInterface {

    sdk_url = "";
    api_url = "";
    publicKey = "";

    apiSandbox = "https://developers.decidir.com/api/v2";
    apiProduccion = "https://live.decidir.com/api/v2";

    isProduccion = false;
    decidirSDK = null;


    constructor(public_key: string, isProduccion = false) {
        
        if (!public_key)
            throw new Error("Public key no puede ser null");

        //sanboxKey: e9cdb99fff374b5f91da4480c8dca741
        this.publicKey = public_key;
        this.sdk_url = "https://live.decidir.com/static/v2.5/decidir.js";
        this.isProduccion = isProduccion;
        this.api_url = isProduccion ? this.apiProduccion : this.apiSandbox;
    }

    inicializar(callback: Function) {

        loadDynamicScript('decidirPagoScript', this.sdk_url, 'DecidirPagoLib', () => {

            const timeout = this.isProduccion ? 3000 : 0;
            
            this.decidirSDK = new (window as any).Decidir(this.api_url);
            (this.decidirSDK as any ).setPublishableKey(this.publicKey);
            (this.decidirSDK as any ).setTimeout(timeout);

            if (callback)
                callback();

        });
    }

    tiposDocumento(callback: Function) {

        callback([
            { id: 'dni', name: 'DNI' }
        ])

        // return [
        //     {id:2, name:'DNI' },
        //     {id:1, name:'CUIL'},
        //     {id:0, name:'CUIT'},
        // ];
        /*
        (<any>this.decidirSDK).getIdentificationTypes((status: number, data: any) => {

            if (status === 200) {

                callback(data);

            } else {

                console.error('No fue posible obtener tipos de documentos.');
                callback([]);
            }
        });*/
    }

    /**
     * Retorna el medio de pago que puede ser consultado por el bin number
     * ó su identificador
     * @param params { bin : number } || { payment_method_id : number }
     * @param callback 
     */
    medioPago(params: any, callback: Function) {

        // if (params.bin || params.payment_method_id) {

        //     (<any>this.decidirSDK).getPaymentMethod(params, (status: number, data: any) => {

        //         if (status == 200) {

        //             callback(status, data);

        //         } else {

        //             callback(status, { error: 'No fue posible obtener el medio de pago.', data: data });

        //         }
        //     })
        // }
    }

    emisoresTarjetas(metodoPago: string, callback: Function) {
        // (<any>this.decidirSDK).getIssuers(metodoPago, (status: number, data: any) => {

        //     if (status === 200) {
        //         callback(data);
        //     } else {
        //         callback([]);
        //     }

        // });
    }

    getInstallments(payment_method_id: string, amount: Number, callback: Function) {
         (<any>this.decidirSDK).getInstallments({

             "payment_method_id": payment_method_id,
             "amount": amount,

         }, (status: number, data: any) => {

             if (data.length > 0)
                 callback(data);

         });
    }

    tokenizar(params: {}, medioPago: MedioPago, callback: Function) {

        const mappedParams = this.mapearParametros(params)
        const $form = document.createElement('form');


        for (const [key, value] of Object.entries(mappedParams)) {

            let input = document.createElement('input')
            input.setAttribute('data-decidir', key)
            input.setAttribute('value', value as string)
            $form.appendChild(input);
        }

        (this.decidirSDK as any ).createToken($form, (status: number, data: any) => {

            if (status !== 200 && status !== 201) {

                callback(status, { error: 'Datos incorrectos.', data: data })

            } else {

                callback(status, data)

            }

        });
    }

    mapearParametros(params: {}) {

        var mappedParams: { [key: string]: any } = {};

        for (const [key, value] of Object.entries(params)) {

            if (value !== undefined) {

                switch (key) {
                    case 'cardNumber':
                        mappedParams.card_number = value
                        break;
                    case 'securityCode':
                        mappedParams.security_code = value
                        break;
                    case 'cardExpirationMonth':
                        mappedParams.card_expiration_month = value
                        break;
                    case 'cardExpirationYear':
                        mappedParams.card_expiration_year = value
                        break;
                    case 'cardholderName':
                        mappedParams.card_holder_name = value
                        break;
                    case 'docType':
                        mappedParams.card_holder_doc_type = value
                        break;
                    case 'docNumber':
                        mappedParams.card_holder_doc_number = value
                        break;

                    default:
                        mappedParams[key] = value;
                        break;
                }
            }
        }
        return mappedParams;
    }

}
