import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {setResumenPrevio} from "../../core/pagos/pagos-actions";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
// @material-ui/core components
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import Button from "../../components/Buttons/Button";
import Tooltip from "@material-ui/core/Tooltip";
//import Checkbox from '@material-ui/core/Checkbox';
import {browserHistory, getFullUrl} from "../../core/globals";

// @material-ui/icons
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { formatMoneda } from '../../core/helpers.js';
import Moment from 'moment';
import tooltipStyle from "../../components/Tooltip/TooltipStyle";

const styles = (theme) => ({
	...tooltipStyle(theme),
});


const SelectTable = selectTableHOC(ReactTable);

class ListadoPlanes extends Component {
	static defaultProps = {
		keyField: "id_comprobante"
	};

	static propTypes = {
		classes: PropTypes.any,
		style: PropTypes.any,
		data: PropTypes.array.isRequired,
		keyField: PropTypes.string
	};

	constructor (props){
		super(props);
		this.state = {
			width:0, height:0,
			selectAll: false,
			selection: [],
			totalSelected: 0
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	onDownloadClick(idFactura){
		this.props.donwloadFactura(idFactura);
	}


	// ----------------------------------------------------------------------------------------------

	/**
   * Toggle a single checkbox for select table
   */
  toggleSelection = (key, shift, row) => {
	if (row.pagar === 'S'){
		// start off with the existing state
		let selection = [...this.state.selection];
		const keyIndex = selection.indexOf(key);
		let totalSelected= this.state.totalSelected;

		// check to see if the key exists
		if (keyIndex >= 0) {
			totalSelected-= parseFloat(row["importe_1vto"]);
		// it does exist so we will remove it using destructing
		selection = [
			...selection.slice(0, keyIndex),
			...selection.slice(keyIndex + 1)
		];
		} else {
			totalSelected+= parseFloat(row["importe_1vto"]);
			// it does not exist so add it
			selection.push(key);
		}
		// update the state
		this.setState({ selection, totalSelected });
	}
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
	const selection = [];
	let totalSelected= 0;

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
		if (item._original['pagar'] === 'S'){
			selection.push(`select-${item._original[keyField]}`);
			totalSelected+= parseFloat(item._original["importe_1vto"]);
		}
      });
    }
    this.setState({ selectAll, selection, totalSelected });
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = key => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => {
    const { selection } = this.state;

    return {
      onClick: (e, handleOriginal) => {

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {
        background:
          rowInfo &&
          selection.includes(`select-${rowInfo.original.id_comprobante}`) &&
          "lightgreen"
      }
    };
  };

  handlePagarFacturas() {
		var facturas= [];
		
		this.state.selection.map( item => {
			var id = item.replace("select-", "");
			facturas.push({id});
		});

		if (facturas.length !== 0){
			Promise.all([
				this.props.actions.setResumenPrevio({deuda: facturas, importeDeuda: this.state.totalSelected}),
			])
			browserHistory.push(getFullUrl('estado-deuda-pago'));
		}
	}

  // ----------------------------------------------------------------------------------------------

  defaultFilter = (filter, row, column) => {
	const id = filter.pivotId || filter.id;
	return row[id] !== undefined
	  ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
	  : true;
  };
  
  render() {
	const data = this.props.data;
	const botonPagar =this.props.parametros.AU_OCULTAR_PAGO_FACTURA &&  this.props.parametros.AU_OCULTAR_PAGO_FACTURA.valor ==="S" ?  "":
				<Button
                  disabled={data.length === 0}
                  fullWidth={false}
                  color= "primary"
                  onClick={() => this.handlePagarFacturas()}
                >Pagar</Button>;

    return (
		<div>
			{botonPagar}
			<SelectTable
				keyField="id_comprobante"
				getPageJumpProps={(params)=>{
					return {
						style:{
							input:{
								display: 'inline !important',
								color:'blue',
								backgorundColor:'yellow',
							}
						}
					}
				}}
				renderPageJump={this.renderPageJump}
				showPageSizeOptions={true}
				data={this.getItemsInfo(data)}
				filterable
				resizable={true}
				columns={this.getColumns()}
				defaultPageSize={10}
				showPaginationTop={false}
				showPaginationBottom
				className="-striped -highlight"
				previousText="<"
				nextText=">"
				loadingText="Cargando..."
				noDataText="No se encontraron datos"
				pageText="Página"
				ofText="de"
				rowsText="filas"
				defaultSorted={[{
					id   : 'fecha_1vto',
					desc : true,
				}]}
				ref={r => (this.checkboxTable = r)}
				toggleSelection={this.toggleSelection}
				selectAll={this.state.selectAll}
				selectType="checkbox"
				toggleAll={this.toggleAll}
				isSelected={this.isSelected}
				getTrProps={this.rowFn}
				defaultFilterMethod={this.defaultFilter}
			/>
			{botonPagar}
		</div>
    );
  }

  //-----------------------------------------------------
  renderPageJump = (options) => {
	return (
		  <input 
			style={{maxWidth:'60px'}}
			type="text" 
			value={options.value} 
			onBlur={options.onBlur}
			onChange={options.onChange}
			onKeyPress={options.onKeyPress}
		  />
	);
}

getItemsInfo = (items) => {
	  const { classes } = this.props;
	  return items.map(item => {
		  item.alias_cuenta = ( item.cuenta.alias_cuenta ? item.cuenta.alias_cuenta : item.cuenta.descripcion);
		  return ({
			  ...item,
			  actions: (
				  <div className="actions-right">					  
					  <Button
						  justIcon
						  round
						  simple
						  onClick={() => this.onDownloadClick(item.id_comprobante)}
						  className="download"
						  color="primary"
					  >
						  <Tooltip
							  id="tooltip-download"
							  title="Descargar Factura"
							  placement="top"
							  classes={{tooltip: classes.tooltip}}
						  >
								  <CloudDownloadIcon/>
						  </Tooltip>
					  </Button>
				  </div>
			  )
		  });
	  });
  };

makePlaceholderFilter = placeholder => {
    return ({ filter, onChange }) => (
      <input
        type="text"
        placeholder={placeholder}
        style={{
          width: '100%',
        }}
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
	);
}

getColumns = () => {
	  const columns = [
		  {
			  Header: "Número",
			  accessor: "nro_factura",
			  style: { textAlign: "left"},
			  show: this.state.width > 1100,
			  sortMethod: (a, b) => {
				  return Number(a) > Number(b) ? 1 : -1;
			  },
			  Filter: this.makePlaceholderFilter("Filtrar")
		  },
		  {
			  Header: "Vencimiento",
			  accessor: "fecha_1vto",
			  style: { textAlign: "left"},
			  show: this.state.width > 800,
			  Cell: row => (
				  <span>
					  {Moment(row.value).format('DD/MM/YYYY')}
				  </span>
			  ),

			  sortMethod: (a, b) => {
				  return new Date(a) > new Date(b) ? 1 : -1;
			  },
			  Filter: this.makePlaceholderFilter("Filtrar")
		  },
		  {
			  Header: "Descripción",
			  accessor: "descripcion_factura",
			  style: { textAlign: "left"},
			  sortMethod: (a, b) => {
				  return String(a) > String(b) ? 1 : -1;
			  },
			  Filter: this.makePlaceholderFilter("Filtrar")
		  },
		  {
			  Header: "Cuenta",
			  accessor: "alias_cuenta",
			  style: { textAlign: "left"},
			  show: this.state.width > 700,
			  sortMethod: (a, b) => {
				  return String(a) > String(b) ? 1 : -1;
			  },
			  Filter: this.makePlaceholderFilter("Filtrar")
		  },
		  {
			  Header: "Importe",
			  accessor: "importe_1vto",
			  Cell: row => (
				  <div>
					  {formatMoneda(row.value)}
				  </div>
			  ),
			  style:{textAlign: "right"},
			  sortMethod: (a, b) => {
				  return String(a) > String(b) ? 1 : -1;
			  },
			  Filter: this.makePlaceholderFilter("Filtrar")
		  },
			{
			  Header: "Impuesto",
			  accessor: "impuesto",
			  style: { textAlign: "center"},
			  show: this.state.width > 600,
			  sortMethod: (a, b) => {
				  return String(a) > String(b) ? 1 : -1;
			  },
			  Filter: this.makePlaceholderFilter("Filtrar")
		  },
		  {
			  Header: "Estado",
			  accessor: "desc_estado",
			  style: { textAlign: "left"},
			  show: this.state.width > 500,
			  sortMethod: (a, b) => {
				  return String(a) > String(b) ? 1 : -1;
			  },
			  Filter: this.makePlaceholderFilter("Filtrar")
		  },
	  ];
	  columns.push({
		  Header: "Acciones",
		  accessor: "actions",
		  sortable: false,
		  filterable: false
	  });

	  return columns;
  };
}


export default  connect(
	state => ({	}),
	dispatch => ({ // mapDispatchToProps
	  actions: bindActionCreators({
		setResumenPrevio,
	  }, dispatch)
	})
  ) (withStyles(styles)(ListadoPlanes));