const initState = {
  systemError: null,
};

const systemReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SYSTEM_OFFLINE':
      return { ...state, systemOffline: action.isOffline };
    default:
      return { ...state, systemOffline: false };
  }
};

export default systemReducer;
