import {handleActions} from 'redux-actions';
import * as actionTypes from './proveedores-actions';
import {handleApiAction} from '../services/ApiMiddleware.reducer';


const initialState = {
    data: [
        // { nroFactura : String,
        //  idFactura : String,
        //  fecha : Date,
        //  expediente : String,
        //  estado : String
        //  ordenesPago : [ { ordenPago : String,
        //  id : String
        // }
        // ],
        //  importe : number,
        //  saldo  : number
        // },
    ],
    loadingFacturas: false,
    facturasError: false,
};

export default handleActions({
  ...handleApiAction(actionTypes.getFacturasProv, {loadingField: 'loadingFacturas', errorField: 'facturasError'},
      (state, {payload: {data = []}}) => {
        return {
            ...state,
            data
        };
      }),
}, initialState);
