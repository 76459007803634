const TooltipStyle = theme => ({
	tooltip: {
		padding: "10px 15px",
		minWidth: "130px",
		color: "#FFFFFF",
		lineHeight: "1.7em",
		background: "rgba(85,85,85,0.9)",
		border: "none",
		borderRadius: "3px",
		opacity: "1!important",
		boxShadow:
			"0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
		maxWidth: "200px",
		textAlign: "center",
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "400",
		textShadow: "none",
		textTransform: "none",
		letterSpacing: "normal",
		wordBreak: "normal",
		wordSpacing: "normal",
		wordWrap: "normal",
		whiteSpace: "normal",
		lineBreak: "auto",
	}
});

export default TooltipStyle;
