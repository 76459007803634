import { createActions } from "redux-actions";

export const { getFacturasProv } = createActions({
    'GET_FACTURAS_PROV': (params) => ({
      API: {
        endpoint: {
          url: `/proveedores/facturas`,
          method: 'get',
          params
        },
        needsToken: true,
      }
    }),
});
