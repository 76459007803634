import {handleActions} from 'redux-actions';
import * as actionTypes from './parametros-actions';
import {handleApiAction} from '../services/ApiMiddleware.reducer';
import {asociativeParams} from './parametros';

const initialState = {
    data:{},
    versionFrontend:"",
    versionBackend:"",
    loadingParametros: false,
    parametrosError: false,
};

export default handleActions({
    ...handleApiAction(actionTypes.getParametro, {loadingField: 'loadingParametros', errorField: 'parametrosError'},
        (state, {payload: {data = []}}
                ) => {
            return {
                ...state,
                data: Object.assign(state.data,asociativeParams(data) ),
            };
        }),
    ...handleApiAction(actionTypes.getVersion, {loadingField: 'loadingParametros', errorField: 'parametrosError'},
    (state, {payload: {data = []}}
            ) => {
        return {
            ...state,
            versionBackend : data
        };
    }),
    ...handleApiAction(actionTypes.getPaginaInicio, {loadingField: 'loadingParametros', errorField: 'parametrosError'},
    (state, {payload: {data = []}}
            ) => {
        return {
            ...state,
            data: Object.assign(state.data,asociativeParams(data) )
        };
    }),
    ...handleApiAction(actionTypes.getPaginaInicioUsuario, {loadingField: 'loadingParametros', errorField: 'parametrosError'},
    (state, {payload: {data = []}}
            ) => {
        return {
            ...state,
            data: Object.assign(state.data,asociativeParams(data) )
        };
    }),
}, initialState);
