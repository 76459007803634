import { createAction } from 'redux-actions';
import Api from '../services/API';
import * as localStorageService from '../services/localStorageService';
import { getFullUrl } from '../globals';
import { authRef, fbProvider, gProvider } from '../libs/firebase';
import { getParametro } from '../parametros/parametros-actions.js';

export const AUTH_LOGIN = createAction('AUTH_LOGIN');
export const AUTH_LOGIN_OK = createAction('AUTH_LOGIN_OK');
export const AUTH_LOGIN_FAIL = createAction('AUTH_LOGIN_FAIL');
export const AUTH_LOGOUT = createAction('AUTH_LOGOUT');
export const AUTH_USER_INFO = createAction('AUTH_USER_INFO');

let browserHistory;
export function setDeps(_browserHistory) {
  browserHistory = _browserHistory;
}

export function login(email, password) {
  return dispatch => {
    dispatch(AUTH_LOGIN());
    if (!email || !password) {
      return dispatch(
        AUTH_LOGIN_FAIL({ message: 'Correo y Clave son requeridos.' })
      );
    }
    // return Api.login(username, password)
    //     .then(resp => storeTokenAndGetUserInfo(resp[0], dispatch))
    return authRef
      .signInWithEmailAndPassword(email, password)
      .then(userCredential => {
        const {
          user: {
            displayName,
            email,
            uid,
          } /*additionalUserInfo: {isNewUser }*/,
        } = userCredential;
        const promise = true ? Api.register(uid, displayName, email) : {};
        return Promise.resolve(promise).then(() =>
          Promise.resolve(userCredential)
        );
      })
      .then(({ user }) => checkEmailVerified(user))
      .then(user => user.getIdToken())
      .then(token => storeTokenAndGetUserInfo(token, dispatch))
      .then(() => redirectIfNeededAfterLogin())
      .catch(e => {
        dispatch(AUTH_LOGIN_FAIL({ message: 'Correo o Clave incorrectas' }));
        localStorageService.removeItem('token');
        throw new Error('Correo o Clave incorrectas');
      });
  };
}

export function loginFromLocalStorage() {
  return dispatch => {
    let token = localStorageService.getItem('token');
    if (!token) {
      return Promise.resolve({});
    }
    return storeTokenAndGetUserInfo(token, dispatch).catch(e => {
      console.log(e);
      dispatch(AUTH_LOGIN_FAIL({ message: '' }));
    });
  };
}

export const requireAuthentication = () => () => {
  const currPath = browserHistory.location.pathname;
  localStorageService.setItem('redirectToAfterLogin', currPath);
  browserHistory.replace(getFullUrl(''));
};

export function isLoggedIn() {
  return !!getCurrentToken();
}

export function getCurrentToken() {
  return localStorageService.getItem('token');
}

function storeTokenAndGetUserInfo(token, dispatch) {
  localStorageService.setItem('token', token);
  dispatch(AUTH_LOGIN_OK({ token }));
  dispatch(getParametro({ parametro: '%' }));

  return Api.getMe().then(resp => {
    dispatch(AUTH_USER_INFO(resp));
  });
}

const redirectIfNeededAfterLogin = function() {
  const redirectTo = localStorageService.getItem('redirectToAfterLogin');
  if (redirectTo) {
    localStorageService.removeItem('redirectToAfterLogin');
    return browserHistory.replace(redirectTo);
  }
  browserHistory.replace(getFullUrl(''));
};

export function logout(popUpLogin = false) {
  return dispatch => {
    localStorageService.removeItem('token');
    localStorageService.removeItem('redirectToAfterLogin');
    browserHistory.replace(getFullUrl(''));
    return authRef
      .signOut()
      .then(() => {
        return dispatch(AUTH_LOGOUT({ popUpLogin }));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export const signInWithFacebookProvider = () => dispatch => {
  dispatch(AUTH_LOGIN());
  return authRef
    .signInWithPopup(fbProvider)
    .then(userCredential => {
      const {
        user: { displayName, email, uid },
        additionalUserInfo: {
          /*isNewUser,*/ profile: {
            picture: {
              data: { url },
            },
          },
        },
      } = userCredential;
      const promise = true ? Api.register(uid, displayName, email, url) : {};

      return Promise.resolve(promise).then(() =>
        Promise.resolve(userCredential)
      );
    })
    .then(({ user, credential }) => {
      return Promise.resolve(user).then(() =>
        user.getIdToken().then(function(idToken) {
          // <------ Check this line
          return storeTokenAndGetUserInfo(idToken, dispatch); // It shows the Firebase token now
        })
      );
    })
    .then(() => redirectIfNeededAfterLogin())
    .catch(e => {
      localStorageService.removeItem('token');
      dispatch(
        AUTH_LOGIN_FAIL({ message: 'Error ingresando con proveedor Facebook' })
      );
      throw new Error(`Error ingresando con proveedor Facebook`);
    });
};

export const signInWithGoogleProvider = () => dispatch => {
  dispatch(AUTH_LOGIN());

  return authRef
    .signInWithPopup(gProvider)
    .then(userCredential => {
      const {
        user: { displayName, email, uid },
        additionalUserInfo: {
          /*isNewUser,*/ profile: { picture },
        },
      } = userCredential;
      const promise = true
        ? Api.register(uid, displayName, email, picture)
        : {};
      return Promise.resolve(promise).then(() =>
        Promise.resolve(userCredential)
      );
    })
    .then(({ user, credential }) => {
      return Promise.resolve(user).then(() =>
        user.getIdToken().then(function(idToken) {
          // <------ Check this line
          return storeTokenAndGetUserInfo(idToken, dispatch); // It shows the Firebase token now
        })
      );
    })
    .then(() => redirectIfNeededAfterLogin())
    .catch(e => {
      localStorageService.removeItem('token');
      dispatch(
        AUTH_LOGIN_FAIL({ message: 'Error ingresando con proveedor Google' })
      );
      throw new Error(`Error ingresando con proveedor Google`);
    });
};

const checkEmailVerified = user => {
  if (!user.emailVerified) throw new Error('Email no verificado.');
  return Promise.resolve(user);
};

export function register(email, displayName, password) {
  return () => {
    if (!email || !password || !displayName)
      throw new Error('Email, Nombre y Password son obligatorios');

    return authRef
      .createUserWithEmailAndPassword(email, password)
      .then(userCredential => {
        const { user, additionalUserInfo } = userCredential;
        const promise = additionalUserInfo.isNewUser
          ? Api.register(user.uid, displayName, email)
          : {};
        return Promise.resolve(promise).then(() =>
          Promise.resolve(userCredential)
        );
      })
      .then(({ user }) =>
        Promise.all([
          user.updateProfile({ displayName }),
          user.sendEmailVerification(),
        ])
      )
      .then(redirectIfNeededAfterLogin)
      .catch(e => {
        var errores = {
          'auth/email-already-in-use': 'El mail ya está registrado.',
          'auth/user-disabled': 'El usuario está deshabilitado',
        };
        if (errores[e.code]) {
          throw new Error(errores[e.code]);
        } else throw new Error('Error registrando usuario.(' + e.message + ')');
      });
  };
}

export function resetPassword(email) {
  return () => {
    if (!email) throw new Error('Email es obligatorio');
    return authRef
      .sendPasswordResetEmail(email)
      .then(redirectIfNeededAfterLogin)
      .catch(e => {
        throw new Error('Error al intentar recuperar contraseña');
      });
  };
}
