import {handleActions} from 'redux-actions';
import * as actionTypes from './mensajes-actions';
import {handleApiAction} from '../services/ApiMiddleware.reducer';

const initialState = {
    data: [
        {
            id: 1,
            case_type_id: 1,
            case_type_desc:'Tipo 1', //Mapear
            subject:'Residuos de Jardin',
            start_date:'01-11-2018',
            status_id:2,
            status_desc:'En Proceso', //Mapear
            client_id:'34',
            user_id:'ddirazar@gmail.com',
            news : 'S',
            leido:'N', //Mapear
        },
        {
            id: 2,
            case_type_id: 2,
            case_type_desc:'Tipo 2', //Mapear
            subject:'Residuos de Jardin',
            start_date:'01-11-2018',
            status_id:1,
            status_desc:'Cerrado', //Mapear
            client_id:34,
            user_id:'ddirazar@gmail.com',
            news : 'N',
            leido:'S', //Mapear
        },
        {
            id: 3,
            case_type_id: 1,
            case_type_desc:'Tipo 1', //Mapear
            subject:'Solicitud de container',
            start_date:'01-11-2018',
            status_id:3,
            status_desc:'Resuelto', //Mapear
            client_id:34,
            user_id:'ddirazar@gmail.com',
            news : 'N',
            leido:'S', //Mapear
        },
        {
            id: 4,
            case_type_id: 3,
            case_type_desc:'Tipo 3', //Mapear
            subject:'Luces quemadas en la calle',
            start_date:'01-11-2018',
            status_id:3,
            status_desc:'Resuelto',//Mapear
            client_id:34,
            user_id:'ddirazar@gmail.com',
            news : 'N',
            leido:'S',//Mapear
        }

        
    ],
    loadingMensajes: false,
    mensajesError: false,
};


export default handleActions({
    ...handleApiAction(actionTypes.postCases, {loadingField: 'loadingMensajes', errorField: 'mensajesError'},
    (state, {payload: {data = []}}) => {
        return {
            ...state,
            data
        };
    }),
    
}, initialState);
