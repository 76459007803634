import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeAPICall } from '../../../core/services/http-request';
import { Container, Grid, Paper, Button, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ComprobantePago from '../../../components/ComprobantePago/ComprobantePago';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const Pago360Pending = props => {
  const history = useHistory();
  const id_operacion = history.location.search.substr(
    history.location.search.indexOf('=') + 1
  );
  const [resultadoPago, setResultadoPago] = useState();
  const parametros = useSelector(state => {
    return state.parametros;
  });
  const user = useSelector(state => {
    return state.auth.user;
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const get = async () => {
      const req = {
        requestConfig: {
          method: 'get',
          params: {
            id_operacion: id_operacion,
          },
          url: '/pagos/verificar',
        },
        methodCall: '',
        needsToken: true,
      };
      const response = await makeAPICall(req);

      setResultadoPago(response.data);
      setLoading(false);
    };
    if (user) get();
  }, [user]);

  const handleClose = () => {
    window.parent.location.href = 'autogestion/estado-deuda';
  };

  const useStyles = makeStyles(theme => ({
    main: {
      textAlign: 'center',
      '& div.headerDiv': {
        backgroundColor: '#f7e760',
        color: 'white',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '500',
      },
      '& .MuiAvatar-root': {
        margin: '0 auto',
        width: '100px',
        height: '100px',
        backgroundColor: '#f7e760',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '100px',
      },
      '& .boton_pagar': {
        backgroundColor: '#f7e760',
      },
    },
    loadingDiv: {
      marginTop: '30%',
      marginLeft: '50%',
      width: '80px !important',
      height: '80px !important',
    },
  }));
  const classes = useStyles();

  if (loading) return <CircularProgress className={classes.loadingDiv} />;

  return (
    <Container>
      <Paper elevation={3} className={classes.main}>
        <div className="headerDiv">
          <h1>Pago Pendiente</h1>
        </div>
        <Avatar>
          <ErrorOutlineIcon />
        </Avatar>

        <div>
          <Grid container justify="center">
            <Grid item xs={12} sm={6} md={6}>
              <ComprobantePago
                titulo={
                  (parametros.AU_NOMBRE_MUNICIPIO &&
                    parametros.AU_NOMBRE_MUNICIPIO.valor) ||
                  'Oficina Virtual'
                }
                fecha={moment(
                  new Date(resultadoPago.fecha),
                  'YYYY-MM-DD HH:MM:SS'
                ).format('DD/MM/YYYY')}
                hora={resultadoPago.fecha.substr(11, 18)}
                transaccion={id_operacion}
                usuario={user.nombre}
                importe={resultadoPago.monto}
                fechaActualizacion={resultadoPago.fechaActualizacion}
                medioPago={resultadoPago.medio_pago}
                estado={resultadoPago.estado}
              />
            </Grid>
          </Grid>
        </div>

        <Button
          variant="contained"
          color="primary"
          id="pagos360-close-button"
          className="boton_pagar"
          fullWidth={true}
          size="lg"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Paper>
    </Container>
  );
};
export default Pago360Pending;
