import axios from 'axios';
import { API_URL, API_ID_EMPRESA } from '../../core/globals';
import * as localStorageService from '../services/localStorageService';
import { getStore } from '../store';
import { setOffline } from '../system/system-actions';
import { logout } from '../../core/auth/auth-actions';
import { authRef } from '../libs/firebase';
import jwt_decode from 'jwt-decode';

const getToken = () => localStorageService.getItem('token');
const settings = { API_URL, API_ID_EMPRESA };
const axiosClient = axios.create({
  baseURL: settings.API_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
    'Hash-Key-Id': settings.API_ID_EMPRESA,
  },
});

let unauthenticatedAction;

export function initializeAPI(_unauthenticatedAction) {
  unauthenticatedAction = _unauthenticatedAction;
}

export const addAuthorizationHeader = headers => {
  const token = getToken();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};

export async function makeAPICall({ requestConfig, methodCall, needsToken }) {
  const token = getToken();

  if (needsToken && !token) {
    return Promise.reject(
      "A request to a protected endpoint was made, but we don't have a token"
    );
  }

  if (authRef && authRef.currentUser && token) {
    var decoded = jwt_decode(token);

    var authtime = new Date(1970, 0, 1);
    authtime.setSeconds(decoded.auth_time);
    authtime.setHours(authtime.getHours() - 3);
    var expTime = new Date(1970, 0, 1);
    expTime.setSeconds(decoded.exp);
    expTime.setHours(expTime.getHours() - 3);

    var nowDate = new Date();

    if (nowDate > expTime) {
      const idToken = await authRef.currentUser.getIdToken().catch(error => {
        console.log('Error obteniendo un nuevo token: ', error);
        return Promise.reject(error);
      });

      localStorageService.setItem('token', idToken);
    }
  }

  // const parsedDomain = parseDomain(window.location.origin) || { subdomain: 'app' };
  requestConfig = requestConfig || methodCall(); // remove methodCall()

  if (!requestConfig)
    throw new Error('You must return the axios config from makeAPICall');

  requestConfig.headers = requestConfig.headers || {};
  // requestConfig.headers.domain = parsedDomain && parsedDomain.subdomain;

  addAuthorizationHeader(requestConfig.headers);

  return axiosClient
    .request(requestConfig)
    .then(response => response)
    .catch(errorResponse => {
      let { response } = errorResponse;
      if (!response || !response.status) {
        console.log('Hard network error', errorResponse);
        return Promise.reject({
          status: 500,
          data: { message: 'Se produjo un error!' },
        });
      }
      if (response.status === 401) {
        console.log(
          'Unauthenticated request - login and comeback - status is ',
          response.status
        );
        const store = getStore();
        store.dispatch(logout(true));
        if (needsToken) {
          unauthenticatedAction();
        }
      } else if (response.status === 403) {
        console.log(
          'Unauthorized request - Not enough privileges ',
          response.status
        );
        // unauthorizeddAction()
      }
      if (!response.data.message) {
        console.log('Injecting default error');
        if (typeof response.data === 'string') {
          response.data = {};
        }
        response.data.message = 'Se produjo un error!!';
      }
      if (response.status === 500) {
        console.log('Mensaje de servicio no disponible ');
        const store = getStore();
        store.dispatch(setOffline(true));
      }

      return Promise.reject(response);
    });
}
