import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import GridItem from "../../components/Grid/GridItem.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/Buttons/Button.jsx";
import Select from "@material-ui/core/Select";
import MessageComponent from '../../components/Message/MessageComponent';
import BlockComponent from "../../components/Loading/BlockComponent";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { reNombre, reIntegerPos } from  '../../core/expRegulares.js';
import Divider from '@material-ui/core/Divider';
//import { isObject } from 'util';

const styles = theme => ({

  root: {
   display: 'flex',
   flexWrap: 'wrap',
 },
 formControl: {
   minWidth: 150,
   marginTop: 10,
 },
 selectEmpty: {
   marginTop: 2,
 },

  dense: {
    marginTop: 2,
  },
  menu: {
    width: 200,
  },
});

const CBU_LENGTH = 22;

class FormDebitoAutomatico extends Component {
    
    constructor (props){
        super(props)

        this.rootRef = React.createRef();

        this.state = {
            message:null,
            messageColor:null,
            isLoading: false,
            nroCuenta : null,
            nombre:null,
            tipoDocumento : null,
            nroDocumento : null,
            cbu :null,

            validate:{
                nroCuenta:{
                    error:false,
                    helpText:'',
                },
                nombre:{
                    error:false,
                    helpText:'',
                },
                tipoDocumento:{
                    error:false,
                    helpText:'',
                },
                nroDocumento:{
                    error:false,
                    helpText:'',
                },
                cbu:{
                    error:false,
                    helpText:'',
                },
            },
        };
    }

    componentDidMount(){

    }

    componentWillMount(){
        const item = this.props.item
        this.setState({item})
    }
    
    /* Mensajes de Validacion */
    setErrorMessage(message) {
        this.setMessage({messageColor: 'error', message});
    }

    setMessage = ({message, messageColor}) => {
        this.setState({message, messageColor});
        setTimeout(this.resetMessage, 6000);
    }

    resetMessage = () => {
        this.setState({message: null, messageColor: null});
    }

    handleOnChangeModoPago = (key) =>{

    }

    handleOnChangeNumber = (key) =>{

    }

    validateNumber = (e) =>{
        return true;
    }
    validateTextNumber = (e) =>{
        return true;
    }
    
    bindValue = key => {
        return {
            onChange: this.handleOnChangeValue.bind(this)(key),
            value: this.state[key] || '',
            validate: "'"+this.validarItem.bind(this)(key)+"'",
        }
    }

	getItemValue = key => {
		return this.state[key] || ''
    }
    
	bindNumberValue = key => {
		return {
			onChange: this.handleOnChangeValue.bind(this)(key),
			value: this.state[key] === undefined ? 0 : Number(this.state[key] || '')
		}
    }

	bindDateValue = key => {
		return {
			onChange: m => {
				const state = this.state;
				state[key] = m.format('DD/MM/YYYY');
				this.setState({state});
			},
			value: this.state[key] || ''
		}
    }
   
	handleOnChangeValue(key) {
        if (this.validateNumber(key))
            return (e) => {
                const state = this.state
                state[key] = e.target.value
                state.validate[key] = this.validarItem(e.target.value, key)
                this.setState({
                    ...state,
                })
  		    }
	}
    validarItem = (value, key)=>{
        const validate = {error:false, message:''}
        
       if (key === 'cbu' && !this.validarCBU()){
            validate.error = true;
            validate.helpText = 'CBU incorrecto, debe ser de '+  CBU_LENGTH  +' números';
        }
       
        if (key === 'nombre' && !this.validarNombre()){
            validate.error = true;
            validate.helpText = 'Nombre incorrecto.';
        }

        return validate
    }
    
    validarCBU = () => {
        const state = this.state;
        if (state.cbu == null){
            return false;
        }
        if (! reIntegerPos.test(state.cbu)){
            return false;
        }
        if (state.cbu.toString().length < CBU_LENGTH || state.cbu.toString().length > CBU_LENGTH){
            return false;
        }

        return true
    }

    validarNombre = () => {
        const state = this.state;
        if (state.nombre == null || !reNombre.test(state.nombre)){
          return false;
        }
        return true;
      }

    habilitarDocumento(){
        return this.state.tipoDocumento !== undefined
            || this.state.tipoDocumento != null ? false : true;
    }
    
    habilitarButtonAceptar = () =>{
        return !this.props.habilitarButtonAceptar;
    }
    
    handleOnClickAceptar = () => {

        if (!this.validarNombre () || !this.validarCBU ()){
            this.setErrorMessage("Verifique los campos.");
            return;
        }
        
        const data = {

            tipoCuenta:this.props.item.tipoCuenta,
            idCuenta:this.props.item.idCuenta,
            nroCuenta:this.props.item.nroCuenta,
            nombre:this.state.nombre,
            tipoDocumento:this.state.tipoDocumento,
            nroDocumento:this.state.nroDocumento,
            cbu:this.state.cbu

        }

        this.props.handleOnClickAceptar(data);
    }

    render () {
        const { classes } = this.props; //Puede ser Tarjetas de Credito o Debito
        const item = this.props.item;
        const isLoading = this.state.isLoading;
        const message = this.state.message;
        const messageColor = this.state.messageColor;
        const titulo = "Débito automático "+item.aliasCuenta;
        
        return (
        <Grid ref={this.rootRef}>
            <BlockComponent blocking={isLoading}>
           
            <Typography variant="subtitle2" gutterBottom> 
		  		{titulo}
			</Typography>
            <Divider/>
            <form autoComplete="off" className={classes.root} >
                    <Grid container >
                        <GridItem xs={12} sm={6} >
                            <FormControl
                                className={classes.formControl}
                                fullWidth={true}
                                required={true}
                            >
                            <InputLabel htmlFor="tipoDocumento">Tipo Documento</InputLabel>
                                <Select
                                    native
                                    {...this.bindValue('tipoDocumento')}
                                    name="tipoDocumento"
                                    inputProps={{
                                        id: 'tipoDocumento',
                                        autoFocus:true
                                    }}
                                    className={classes.selectEmpty}
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                >
                                    <option value="" disabled></option>
                                    {
                                        this.props.tiposDocumento.map((tipo,index)=>{
                                            return <option key={index} value={tipo.tipoDocumento}>{tipo.descripcion}</option>
                                        })
                                    }
                                    
                                </Select>
                            </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={6}>
                            <CustomInput
                                labelText="Número"
                                id="nroDocumento"
                                helpText={this.state.validate.nroDocumento.helpText}
                                error={this.state.validate.nroDocumento.error}
                                formControlProps={{
                                    required: true,
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: this.habilitarDocumento(),
                                    required: true,
                                    type: "text",
                                    ...this.bindValue('nroDocumento'),
                                }}
                                inputHtmlProps={{
                                    maxLength:11,
                                }}
                            />
                        </GridItem>
                    </Grid>

                    <Grid container >
                        <GridItem xs={12} sm={6}>
                            <CustomInput
                            labelText="Nombre"
                            id="nombre"
                            helpText={this.state.validate.nombre.helpText}
                            error={this.state.validate.nombre.error}
                            formControlProps={{
                                fullWidth: true,
                                required: true,
                            }}
                            inputProps={{
                                required: true,
                                type: "text",
                                ...this.bindValue('nombre'),
                            }}
                            inputHtmlProps={{
                                maxLength:45,
                            }}
                            />
                        </GridItem>
                    </Grid>

                    <Grid container >
                        <GridItem xs={12} sm={6} >
                            <CustomInput
                            labelText="CBU"
                            id="cbu"
                            helpText={this.state.validate.cbu.helpText}
                            error={this.state.validate.cbu.error}
                            formControlProps={{
                                fullWidth: true,
                                required: true,
                                margin: "none",
                            }}
                            inputProps={{
                                type: "text",
                                ...this.bindValue('cbu'),
                            }} 
                            inputHtmlProps={{
                                minLength:CBU_LENGTH,
                                maxLength:CBU_LENGTH,
                            }}
                            />
                        </GridItem>
                    </Grid>
                    
                    <Grid container
                        direction="row"
                        justify="space-around"
                        alignItems="center" >
                        <GridItem xs={6} sm={3}>
                            <FormControl fullWidth={true}>
                                <Button
                                    variant="contained"
                                    color="transparent"
                                    fullWidth={false}
                                    size="md"
                                    onClick={this.props.handleOnClickCancelar}>
                                    VOLVER
                                </Button>
                            </FormControl>
                        </GridItem>

                        <GridItem xs={6} sm={3}>
                            <FormControl fullWidth={true} >
                                <Button
                                    disabled={ this.habilitarButtonAceptar()}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={false}
                                    size="md"
                                    onClick={this.handleOnClickAceptar}>
                                    Aceptar
                                </Button>
                            </FormControl>
                        </GridItem>
                    </Grid>
            </form>
            
            <MessageComponent color={messageColor} message={message}/>

            </BlockComponent>
        </Grid>
        )
    }
}

export default withStyles(styles)(FormDebitoAutomatico);
