import {handleActions} from 'redux-actions';
import * as actionTypes from './noticias-actions';
import {handleApiAction} from '../services/ApiMiddleware.reducer';

const initialState = {
    data: [
    ],
    loadingNoticias: false,
    NoticiasError: false,
};


export default handleActions({
    ...handleApiAction(actionTypes.getNoticias, {loadingField: 'loadingNoticias', errorField: 'noticiasError'},
    (state, {payload: {data = []}}) => {
        return {
            ...state,
            data
        };
    }),
    ...handleApiAction(actionTypes.getNoticiasRss, {loadingField: 'loadingNoticias', errorField: 'noticiasError'},
    (state, {payload: {data = []}}) => {
        return {
            ...state,
            data
        };
    }),
}, initialState);
