import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
//import {getFullUrl} from "../../core/globals"

const styles = (theme) => ({
  item: {
    fontWeight: 'bold',
    color: '#252629',
    opacity: '0.8',
    border: '1px solid #c7c8cf00',
  },
  disabled: {
    fontWeight: 'bold',
    color: '#252629',
    fontSize: '16px',
    opacity: '0.4',
  },
  itemHover: {
    border: '1px solid #c7c8cf',
    opacity: '1',
  },
  selectedItem: {
    fontWeight: 'bold',
    border: '1px solid #c7c8cf',
    fontSize: '16px',
    opacity: '1',
    webkitBoxShadow: '0px 0px 4px 3px rgb(0, 179, 209)',
    mozBoxShadow: '0px 0px 4px 3px rgb(0, 179, 209)',
    boxShadow: '0px 0px 3px 3px rgb(0, 179, 209)',
  },
  img: {
    width: 'unset',
  },
});

class OpcionMedioPago extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  handleClick(item) {
    return this.props.item.habilitado ? this.props.handleClick(item) : false;
  }

  hoverOn = () => {
    return this.props.item.habilitado ? this.setState({ hover: true }) : false;
  };
  hoverOff = () => {
    return this.props.item.habilitado ? this.setState({ hover: false }) : false;
  };

  render() {
    const { item, selected, classes, icono } = this.props;
    const iconPath = require('../../assets/img/' + icono);
    return (
      <div onClick={() => this.handleClick(item)}>
        <Tooltip
          TransitionComponent={Zoom}
          TransitionProps={{ timeout: 600 }}
          title={item.desc_medio_pago}
        >
          <div
            onMouseEnter={this.hoverOn}
            onMouseLeave={this.hoverOff}
            className={
              selected
                ? classes.selectedItem
                : this.state.hover
                ? classes.itemHover
                : item.habilitado
                ? classes.item
                : classes.disabled
            }
          >
            <img src={iconPath.default} className={classes.img} alt="" />
          </div>
        </Tooltip>
      </div>
    );
  }
}

OpcionMedioPago.propTypes = {
  handleClick: PropTypes.func,
  classes: PropTypes.any,
  selected: PropTypes.bool,
  icono: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
  }),
};

export default withStyles(styles)(OpcionMedioPago);
