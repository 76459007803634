import { createActions } from "redux-actions";

export const { getNoticias, getNoticiasRss } = createActions({
    'GET_NOTICIAS': (params) => ({
      API: {
        endpoint: {
          url: `/noticias/noticias`,
          method: 'get',
          params
        },
        needsToken: false,
      }
    }),
    'GET_NOTICIAS_RSS': (params) => ({
      API: {
        endpoint: {
          url: `/noticias/rss`,
          method: 'get',
          params
        },
        needsToken: false,
      }
    }),
});
