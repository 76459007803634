import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { makeAPICall } from '../../core/services/http-request';
import Header from '../../components/Header/Header';
import { makeFilters, filtrar } from './makeFilters';
import FiltroPagos from './FiltroPagos';
import ListadoPlanes from './ListadoPlanes';
import { downloadFile } from '../../core/downloadService';
import CircularProgress from '@material-ui/core/CircularProgress';

const Planes = props => {
  const { headerProps } = props;
  const [filtros, setFiltros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [planes, setPlanes] = useState([]);
  const user = useSelector(state => {
    return state.auth.user;
  });
  const parametros = useSelector(state => {
    return state.parametros;
  });

  useEffect(() => {
    const get = async () => {
      const req = {
        requestConfig: {
          method: 'get',
          params: {
            usuario: user.id,
            tipo: 'planadelanto',
          },
          url: '/facturas',
        },
        methodCall: '',
        needsToken: true,
      };
      const response = await makeAPICall(req);
      if (response.data) {
        const filts = makeFilters(response.data);
        setFiltros(filts);
        var data = response.data;
        data = data.map(d => {
          d['id_cuenta'] = d.cuenta.id_cuenta;
          d['alias_cuenta'] =
            d.cuenta.alias_cuenta !== ''
              ? d.cuenta.alias_cuenta
              : d.cuenta.descripcion;
          return d;
        });
        setPlanes(data);
        setLoading(false);
      }
    };
    if (user) get();
  }, [user]);

  const handleClickFiltro = (item, index) => {
    const filts = Object.assign([], filtros);
    filts[index].active = !filts[index].active;
    setFiltros(filts);
  };

  const donwloadFactura = idFactura => {
    const req = {
      requestConfig: {
        method: 'get',
        params: {
          idFactura,
        },
        url: '/deudas/factura',
      },
      methodCall: '',
      needsToken: true,
    };
    makeAPICall(req).then(res => {
      downloadFile(res.data, 'factura_' + idFactura + '.pdf');
    });
  };

  const useStyles = makeStyles(theme => ({
    paperPlanes: {
      marginTop: '60px',
      marginBottom: '50px',
      padding: '10px',
    },
    loadingDiv: {
      marginTop: '30%',
      marginLeft: '50%',
      width: '80px !important',
      height: '80px !important',
    },
  }));
  const classes = useStyles();

  if (loading) return <CircularProgress className={classes.loadingDiv} />;

  return (
    <Container>
      <Header {...headerProps} showTitle={true}></Header>
      <Paper className={classes.paperPlanes}>
        <FiltroPagos filtros={filtros} onClickHandler={handleClickFiltro} />
        <ListadoPlanes
          data={filtrar(
            planes,
            filtros.filter(i => i.active)
          )}
          donwloadFactura={donwloadFactura}
          parametros={parametros.data}
        />
      </Paper>
    </Container>
  );
};

export default Planes;
