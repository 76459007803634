import { createActions } from "redux-actions";
/*const tipos_documentos = [
  {
    tipo_documento:1,
    descripcion:"dni",
  },
];*/
export const { getPerfil, postPerfil, postPerfilCuenta , getTipoDocumento, deletePerfilCuenta, getPerfilCuentas, postPerfilCuentas} = createActions({
    'GET_PERFIL': (params) => ({
      API: {
        endpoint: {
          url: `/usuarios/perfil`,
          method: 'get',
          params
        },
        needsToken: true,
      }
    }),
    //'GET_FACTURA_P_D_F': (params) => ({ data: file }),
    //'GET_PERFIL' : (params) => ({usuario:'Dino Di Luca',tipo_documento:'dni', nro_documento:'213123'}),
    'POST_PERFIL': (data) => ({
      API: {
        endpoint: {
          url: `/usuarios/perfil`,
          method: 'post',
          data
        },
        needsToken: true,
      }
    }),
    'POST_PERFIL_CUENTA': (data) => ({
      API: {
        endpoint: {
          url: `/usuarios/cuenta`,
          method: 'post',
          data
        },
        needsToken: true,
      }
    }),

    'GET_TIPO_DOCUMENTO': () => ({
      API: {
        endpoint: {
          url: `/usuarios/tipoDocumentos`,
          method: 'get',
        },
        needsToken: false,
      }
    }),
    'DELETE_PERFIL_CUENTA': (data) => ({
      API: {
        endpoint: {
          url: `/usuarios/cuenta`,
          method: 'delete',
          data
        },
        needsToken: true,
      }
    }),
    'GET_PERFIL_CUENTAS': (params) => ({
      API: {
        endpoint: {
          url: `/usuarios/cuentas`,
          method: 'get',
          params
        },
        needsToken: true,
      }
    }),
    'POST_PERFIL_CUENTAS': (data) => ({      
      API: {
        endpoint: {
          url: `/usuarios/cuentas`,
          method: 'post',
          data
        },
        needsToken: true,
      }
    }),
});
