import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import {
  getMediosPago,
  getGatewayPago,
} from '../../core/pagos/pagos-actions.js';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../components/Grid/GridItem.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../components/Buttons/Button.jsx';
import Select from '@material-ui/core/Select';
import MessageComponent from '../../components/Message/MessageComponent';
import BlockComponent from '../../components/Loading/BlockComponent';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { reNombre, reIntegerPos } from '../../core/expRegulares.js';
import Datetime from 'react-datetime';
import { formatMoneda } from '../../core/helpers.js';
import 'moment/locale/es';
import MercadoPagoGateway from '../../core/classes/MercadoPagoGateway';
import DecidirGateway from '../../core/classes/DecidirGateway';
import Axios from 'axios';
import { Modal } from '@material-ui/core';
import { getFullUrl } from '../../core/globals';
import loadEpagos from '../Pagos/Epagos/epagosLib';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 150,
    marginTop: 10,
  },
  selectEmpty: {
    marginTop: 2,
  },

  dense: {
    marginTop: 2,
  },
  menu: {
    width: 200,
  },
  p360ModalOverlayWrapper: {
    width: '50%',
    height: '50%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
  },
  p360ModalOverlay: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    opacity: '0.3',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
  },
  p360ModalContentFixed: {
    height: '34rem',
    maxHeight: '90%',
    width: '25rem',
    maxWidth: '90%',
    top: '50%',
    left: '50%',
    position: 'fixed',
    overflow: 'hidden',
    borderRadius: '6px',
    border: '1px solid #999',
    boxShadow: '-5px 5px 15px 0 rgba(0, 0, 0, .2)',
    webkitBorderRadius: '6px',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    webkitTransform: 'translate(-50%, -50%)',
  },
  p360ModalPopbox: {
    border: '0',
    width: '100%',
    height: '100%',
  },
});

class GatewayView extends Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.state = {
      disablePagar: false,
      isLoading: true,
      gateway: null,
      gatewayReady: false,
      loading: true,
      tiposDocumento: null,
      opcionesCuotas: null,
      _formData: {
        email: this.props.email,
        cardNumber: undefined,
        securityCode: undefined,
        cardExpirationMonth: undefined,
        cardExpirationYear: undefined,
        cardholderName: this.props.cardholderName,
        docType: undefined,
        docNumber: undefined,
        installments: 1,
      },
      messageColor: null,
      message: null,
      infoMedioPago: null,
      bin: null,
      token360: props.payment360 || null,
      pagar360: false,
      paymentMacroClick: props.paymentMacroClick || null,
      paymentMercadoPago: props.paymentMercadoPago || null,
      paymentPagosTic: props.paymentPagosTic || null,
      paymentEpagos: props.paymentEpagos || null,
      verBotCerrar: true,
      errores: {},
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    console.log("Constructor" + window.innerWidth + " " + window.innerHeight);
  }

  updateWindowDimensions() {
    console.log("updateWindowDimensions" + window.innerWidth + " " + window.innerHeight);
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


  /* Mensajes de Validacion */
  setErrorMessage(message) {
    this.setMessage({ messageColor: 'error', message });
  }
  setMessage = ({ message, messageColor }) => {
    this.setState({
      ...this.state,
      message,
      messageColor,
    });
    setTimeout(this.resetMessage, 6000);
  };
  resetMessage = () => {
    this.setState({
      ...this.state,
      message: null,
      messageColor: null,
    });
  };

  scrollToTop = () => {
    this.rootRef.current.scrollIntoView({
      block: 'start',
    });
  };

  componentDidMount() {
    if (!this.state.gatewayReady) {

      Promise.resolve(
        this.props.actions.getGatewayPago({
          codGateway: this.props.cod_gateway,
        })
      )
        .then((response) => {
          //console.log("medio", response[0]);
          let public_key = null;
          let gateway = null;
          const medio = response[0];
          //medio.descripcion= "PAGOS360";
          switch (medio.clase) {
            case 'mercadopago':
              public_key = response[0].public_key;
              gateway = new MercadoPagoGateway(public_key);
              this.inicializarGateway(gateway);

              break;

            case 'decidir':
              public_key = response[0].public_key;
              gateway = new DecidirGateway(
                public_key,
                response[0].parametros.isProduccion || false
              );
              this.inicializarGateway(gateway);

              break;

            case 'reporte':
              //Si no utiliza fomrmulario para tokenizar y tampoco actualiza fecha, se envia a
              if (
                this.props.medioPago.formulario !== 'no_form' &&
                this.props.medioPago.actualiza_fecha === 'N'
              ) {
                this.props.handlerPagar({
                  id_operacion: this.props.id_operacion,
                  medio_pago: this.props.medioPago.cod_medio_pago,
                });
              } else {
                this.setState({
                  ...this.state,
                  isLoading: false,
                });
              }

              break;

            case 'interbanking':
              this.setState({
                ...this.state,
                isLoading: false,
              });
              break;
            case 'link':
              this.setState({
                ...this.state,
                isLoading: false,
              });
              break;
            case 'pagos360':
              this.setState({
                ...this.state,
                isLoading: false,
              });
              break;
            case 'macroclick':
              this.setState({
                ...this.state,
                isLoading: false,
              });
              break;
            case 'mercadopagoch':
              this.setState({
                ...this.state,
                isLoading: false,
              });
              break;
            case 'pagostic':
              this.setState({
                ...this.state,
                isLoading: false,
              });
              break;
            case 'epagos':
              let parametros = {};
              try {
                parametros = JSON.parse(medio.parametros_str);
              } catch (e) {
                console.log('El json de parametros esta mal armado');
                this.props.handlerVolver();
                break;
              }
              this.setState({
                ...this.state,
                epagos: parametros,
                medio: medio
              });

              const script = document.createElement('script');
              const script_url = this?.state?.epagos?.script_url ? this.state.epagos.script_url : 'https://api.epagos.com.ar/quickstart/epagos.js';
              script.src = script_url;
              script.id = 'epagos_lib';

              document.body.appendChild(script);
              script.onload = () => {
                this.setState({
                  ...this.state,
                  isLoading: false,
                });
                const error_url=this.state.epagos.error_url+"&numero_operacion="+this.props.id_operacion;                
                window.ePagos.setClavePublica(this.state.medio.public_key);
                window.ePagos.setOrganismo(this.state.epagos.organismo);
                window.ePagos.setAmbiente(this.state.epagos.ambiente);

                const operacion= {datosOperacion: {convenio:this.state.epagos.convenio,
                                                          ok_url: this.state.epagos.ok_url,
                                                          error_url: this.state.epagos.error_url,
                                                          monto_operacion: this.props.importe,
                                                          numero_operacion: this.props.id_operacion,
                                                          identificador_externo_2: this.props.paymentEpagos.identificador_externo_2,
                                                          opc_descargar_pdf:1,
                                                          opc_fecha_vencimiento:this.props.paymentEpagos.fecha_vto,
                                                          detalle_operacion:this.props.paymentEpagos.detalle_operacion,
                                                          detalle_operacion_visible:this.props.paymentEpagos.detalle_operacion_visible,
                                                    }
                                                }
                window.ePagos.botonPago(operacion);                
              }

/*              loadEpagos(() => {
                this.setState({
                  ...this.state,
                  isLoading: false,
                });

                const error_url=this.state.epagos.error_url+"&numero_operacion="+this.props.id_operacion;                
                const script=`ePagos.setClavePublica('${this.state.medio.public_key}');
                              ePagos.setOrganismo('${this.state.epagos.organismo}');
                              ePagos.setAmbiente('${this.state.epagos.ambiente}');
                              ePagos.botonPago({"datosOperacion": {convenio: '${this.state.epagos.convenio}',
                                                                 ok_url: '${this.state.epagos.ok_url}',
                                                                 error_url: '${error_url}',
                                                                 monto_operacion: ${this.props.importe},
                                                                 numero_operacion: '${this.props.id_operacion}',
                                                                 identificador_externo_2: '${this.props.paymentEpagos.identificador_externo_2}',
                                                                 opc_descargar_pdf:1,
                                                                 detalle_operacion:${JSON.stringify(this.props.paymentEpagos.detalle_operacion)},
                                                                 detalle_operacion_visible:'${this.props.paymentEpagos.detalle_operacion_visible}',
                                                               }
                                                });`;
                console.log(script);
                console.log(this.props.paymentEpagos.detalle_operacion.toString());
                //eval( `ePagos.setClavePublica('${this.state.medio.public_key}'); ePagos.setOrganismo('${this.state.epagos.organismo}'); ePagos.setAmbiente('${this.state.epagos.ambiente}'); ePagos.botonPago({datosOperacion: {convenio: '${this.state.epagos.convenio}', ok_url: '${this.state.epagos.ok_url}', error_url: '${error_url}', monto_operacion: ${this.props.importe}, numero_operacion: '${this.props.id_operacion}', identificador_externo_2: '${this.props.paymentEpagos.identificador_externo_2}', opc_descargar_pdf:1,detalle_operacion_visible='${this.props.paymentEpagos.detalle_operacion_visible}',}});` );

              });*/
              break;
            default:
              console.log('Gateway de Pago no disponible.');
              this.props.handlerVolver();
              break;
          }

          this.scrollToTop();
        })
        .catch((error) => {
          this.setErrorMessage(error.message);
        });
    }
  }

  inicializarGateway = (gateway) => {
    gateway.inicializar(() => {
      gateway.tiposDocumento((data) => {
        this.setState({
          isLoading: false,
          gateway: gateway,
          tiposDocumento: data,
          gatewayReady: true,
        });
      });
    });
  };

  getBin = () => {
    const cardnumber = this.state._formData.cardNumber || '';
    return cardnumber.replace('/ /g', '').substring(0, 6);
  };

  bindValue = (key) => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value: this.state._formData[key] || '',
      //validate: "'"+this.validarItem.bind(this)(key)+"'",
    };
  };

  getMetodoPago = () => {
    const bin = this.state.bin;

    const new_bin = this.getBin();

    if ((bin == null || bin !== new_bin) && new_bin.length >= 6) {
      this.setState({ bin: new_bin });

      const importe = this.props.importe;

      this.state.gateway.medioPago(
        {
          bin: new_bin,
        },
        (status, response) => {
          if (status === 200) {
            this.setState({ infoMedioPago: response });
          }
        }
      );
      this.state.gateway.getInstallments(new_bin, importe, (data) => {
        this.setState({ opcionesCuotas: data[0].payer_costs });
      });
    }
  };

  getItemValue = (key) => {
    return this.state._formData[key] || '';
  };

  bindNumberValue = (key) => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value:
        this.state._formData[key] === undefined
          ? 0
          : Number(this.state._formData[key] || ''),
    };
  };

  bindDateValue = (key) => {
    return {
      onChange: (m) => {
        const state = this.state;
        state._formData[key] = m.format('DD/MM/YYYY');
        this.setState({ state });
      },
      value: this.state._formData[key] || '',
    };
  };

  handleOnChangeValue(key) {
    if (this.validateNumber(key))
      return (e) => {
        const state = this.state;
        state._formData[key] = e.target.value;
        //state.validate[key] = this.validarItem(e.target.value, key)
        this.setState({
          ...state,
        });
      };
  }

  validate = (values) => {
    const errors = {};
    if (
      this.props.medioPago.formulario === 'decidir_tarjeta' ||
      this.props.medioPago.formulario === 'mercadopago_tarjeta'
    ) {
      if (!values.email) errors.email = 'Verifique los datos.';
      if (!values.cardNumber) {
        errors.cardNumber = 'Verifique los datos.';
      } else {
        if (!this.validarCardNumber(values.cardNumber)) {
          errors.cardNumber = 'Número incorrecto.';
        }
      }

      if (!values.securityCode) errors.securityCode = 'Verifique los datos.';
      if (!values.cardExpirationMonth)
        errors.cardExpirationMonth = 'Verifique los datos.';
      if (!values.cardExpirationYear)
        errors.cardExpirationYear = 'Verifique los datos.';
      if (!this.validarCardHolderName(values.cardholderName))
        errors.cardholderName = 'Campo incorrecto.';
      if (!values.docType) errors.docType = 'Verifique los datos.';
      if (!values.docNumber) errors.docNumber = 'Verifique los datos.';
      if (!values.installments) errors.installments = 'Verifique los datos.';
    }

    if (this.props.medioPago.formulario === 'decidir_banelco') {
      if (!values.customer_doc_type)
        errors.customer_doc_type = 'Verifique los datos.';
      if (!values.customer_doc_number)
        errors.customer_doc_number = 'Verifique los datos.';
      if (!values.customer_name) errors.customer_name = 'Verifique los datos.';
    }

    return errors;
  };

  validarItem = (value, key) => {
    const validate = { error: false, message: '' };
    /*
		if (key === 'card_number' && !this.validarCardNumber()){
            validate.error = true;
            validate.helpText = 'Número incorrecto.';
		}
        if (key === 'card_holder_name' && !this.validarCardHolderName()){
            validate.error = true;
            validate.helpText = 'Nombre incorrecto.';
        }
        if (key === 'card_expiration_month' && !this.validarCardExpirationMonth()){
            validate.error = true;
            validate.helpText = 'Mes incorrecto.';
        }
        if (key === 'card_expiration_year' && !this.validarCardExpirationYear()){
            validate.error = true;
            validate.helpText = 'Año incorrecto.';
        }
        if (key === 'card_holder_identification_number' && !this.validarCardHolderIdentificationNumber()){
            validate.error = true;
            validate.helpText = 'Documento incorrecto.';
        }
        if (key === 'security_code' && !this.validarSecurityCode()){
            validate.error = true;
            validate.helpText = 'Código incorrecto.';
        }*/
    return validate;
  };

  validateNumber = (e) => {
    return true;
  };
  validarCardHolderIdentificationNumber = (value) => {
    if (!value || !reIntegerPos.test(value)) return false;
    return true;
  };
  validarCardHolderName = (value) => {
    //const medioPago = this.props.medioPago;
    if (!value || !reNombre.test(value)) {
      return false;
    }
    return true;
  };

  validarCardNumber = (value) => {
    const medioPago = this.props.medioPago;
    if (
      value.toString().length < medioPago.parametros.longitud_pan ||
      value.toString().length > medioPago.parametros.longitud_pan
    ) {
      return false;
    }
    return true;
  };

  habilitarButtonPagar = () => {
    return true;
  };

  onSelectTipoDocumento = (tipoDocumento) => {
    this.setState({ _tipoDocumento: tipoDocumento });
  };

  onVolver = (e) => {
    this.props.handlerVolver();
  };

  onSubmit = (e) => {
    //e.target.disabled = true;
    e.preventDefault();

    this.setState({
      isLoading: true,
    });

    const medioPago = this.props.medioPago;

    //Validaciones
    const sinErrors = this.state._formData;

    const result = this.validate(sinErrors);

    this.setState({ errores: result });

    if (Object.keys(result).length) {
      this.setState({
        isLoading: false,
      });
      return;
    }
    //Fin validaciones

    const payer_address = {
      street_name: this.state._formData['street_name'],
      street_number: this.state._formData['street_number'],
      zip_code: this.state._formData['zip_code'],
    };

    const payment_method_id = this.state.infoMedioPago[0].id;
    const installments = this.state._formData['installments'] || 1;
    this.state.gateway.tokenizar(
      this.state._formData,
      medioPago,
      (status, data) => {
        if (status !== 200 && status !== 201) {
          //e.target.disabled = false;
          this.setState({
            isLoading: false,
          });
          return this.setErrorMessage(data.error);
        }
        this.props.handlerPagar({
          id_operacion: this.props.id_operacion,
          medio_pago: this.props.medioPago.cod_medio_pago,
          payment_method_id: payment_method_id,
          installments: installments,
          token: data,
          address: payer_address,
        });
      }
    );
  };

  onSubmitInterbanking = (e) => {
    //e.target.disabled = true;
    console.log( "onSubmitInterbanking");
    e.preventDefault();

    this.setState({
      isLoading: true,
    });

    const medioPago = this.props.medioPago;

    //Validaciones
    const sinErrors = this.state._formData;

    const result = this.validate(sinErrors);


    this.setState({ errores: result });

    if (Object.keys(result).length) {
      this.setState({
        isLoading: false,
      });
      return;
    }
    //Fin validaciones

    this.props.handlerPagar({
      id_operacion: this.props.id_operacion,
      medio_pago: this.props.medioPago.cod_medio_pago,
      cuit_number: this.state._formData['customer_cuit_number'],
      razon_social: this.state._formData['customer_name'],
    });
  };

  pagar360 = () => {
    this.setState({ ...this.state, pagar360: true });
  };

  closePagar360 = () => {
    this.setState({ ...this.state, pagar360: false });
  };

  renderFormTarjeta() {
    const { classes /*, medioPago*/ } = this.props;
    return (
      <React.Fragment>
        <form className={classes.root} autoComplete="off" id="pay" name="pay">
          <Grid container>
            <GridItem xs={12} sm={3}>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                required={true}
              >
                <InputLabel htmlFor="docType">Tipo Documento</InputLabel>
                <Select
                  native
                  name="docType"
                  className={classes.selectEmpty}
                  //helpText={this.state.errors.docType || ''}
                  error={this.state.errores.docType || false}
                  {...this.bindValue('docType')}
                  inputProps={{
                    id: 'docType', //autoFocus:true
                  }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                >
                  <option>Seleccione</option>
                  {this.state.tiposDocumento &&
                    this.state.tiposDocumento.map((td, index) => (
                      <option
                        key={index}
                        value={td.id}
                        onSelect={() => this.onSelectTipoDocumento(td)}
                      >
                        {td.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                id="docNumber"
                labelText="Número"
                helpText={this.state.errores.docNumber || ''}
                error={this.state.errores.docNumber || false}
                formControlProps={{ required: true, fullWidth: true }}
                inputProps={{
                  disabled: false, //this.habilitarDocumento(),
                  required: true,
                  type: 'text',
                  ...this.bindValue('docNumber'),
                }}
              />
            </GridItem>
          </Grid>

          <Grid container>
            <GridItem xs={12} sm={6}>
              <CustomInput
                id="email"
                labelText="E-Mail"
                helpText={this.state.errores.email || ''}
                error={this.state.errores.email || false}
                formControlProps={{ required: true, fullWidth: true }}
                inputProps={{
                  disabled: true,
                  required: true,
                  type: 'email',
                  ...this.bindValue('email'),
                }}
              />
            </GridItem>
          </Grid>

          <Grid container>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Número de Tarjeta"
                id="cardNumber"
                helpText={this.state.errores.cardNumber || ''}
                error={this.state.errores.cardNumber || false}
                formControlProps={{ required: true, fullWidth: true }}
                inputProps={{
                  required: true,
                  type: 'cardNumber',
                  disabled: false,
                  onKeyUp: this.getMetodoPago,
                  onPaste: this.getMetodoPago,
                  placeholder: '0000 0000 0000 0000',
                  //onPaste: false,onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  ...this.bindValue('cardNumber'),
                }}
              />
            </GridItem>
          </Grid>

          <Grid container>
            <GridItem xs={12} sm={6}>
              <CustomInput
                id="cardholderName"
                labelText="Nombre del titular"
                helpText={this.state.errores.cardholderName || ''}
                error={this.state.errores.cardholderName || false}
                formControlProps={{ fullWidth: true, required: true }}
                inputProps={{
                  required: true,
                  type: 'text',
                  ...this.bindValue('cardholderName'),
                }}
              />
            </GridItem>
          </Grid>

          <Grid container>
            <GridItem xs={4} sm={2}>
              <CustomInput
                id="cardExpirationMonth"
                labelText="Mes Exp."
                helpText={this.state.errores.cardExpirationMonth || ''}
                error={this.state.errores.cardExpirationMonth || false}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                  margin: 'none',
                }}
                inputProps={{
                  placeholder: 'MM',
                  required: true,
                  type: 'text',
                  //onPaste: false, onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  ...this.bindValue('cardExpirationMonth'),
                }}
                inputHtmlProps={{
                  minLength: 2,
                  maxLength: 2,
                  required: true,
                }}
              />
            </GridItem>

            <GridItem xs={4} sm={2}>
              <CustomInput
                id="cardExpirationYear"
                labelText="Año Exp."
                helpText={this.state.errores.cardExpirationYear || ''}
                error={this.state.errores.cardExpirationYear || false}
                formControlProps={{ required: true, fullWidth: true }}
                inputProps={{
                  placeholder: 'YY',
                  required: true,
                  type: 'text',
                  //onPaste: false, onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  ...this.bindValue('cardExpirationYear'),
                }}
                inputHtmlProps={{ minLength: 2, maxLength: 2 }}
              />
            </GridItem>

            <GridItem xs={4} sm={2}>
              <CustomInput
                id="securityCode"
                labelText="CVV"
                helpText={this.state.errores.securityCode || ''}
                error={this.state.errores.securityCode || false}
                formControlProps={{
                  required: true,
                  fullWidth: true,
                }}
                inputProps={{
                  required: true,
                  type: 'text',
                  //onPaste: false, onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  ...this.bindValue('securityCode'),
                }}
                inputHtmlProps={
                  {
                    //minLength:medioPago.parametros.formato_cvv.length,
                    //maxLength:medioPago.parametros.formato_cvv.length,
                  }
                }
              />
            </GridItem>
          </Grid>

          <Grid container>
            <GridItem xs={4} sm={2}>
              <CustomInput
                id="importe"
                labelText="Importe"
                formControlProps={{ fullWidth: true }}
                helpText={this.state.errores.importe || ''}
                error={this.state.errores.importe || false}
                inputProps={{
                  required: true,
                  disabled: true,
                  type: 'text',
                  value: formatMoneda(this.props.importe),
                  //onPaste: false, onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={8} sm={4}>
              <FormControl
                className={classes.formControl}
                fullWidth={true}
                required={true}
              >
                <InputLabel htmlFor="installments">Cuotas</InputLabel>
                <Select
                  native
                  name="installments"
                  className={classes.selectEmpty}
                  helpText={this.state.errores.installments || ''}
                  error={this.state.errores.installments || false}
                  {...this.bindValue('installments')}
                  inputProps={{
                    id: 'installments', //autoFocus:true
                    required: false,
                  }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                >
                  <option>Seleccione</option>
                  {this.state.opcionesCuotas &&
                    this.state.opcionesCuotas.map((cuotas, index) =>
                      cuotas.installments === 1 ? (
                        <option
                          key={index}
                          value={cuotas.installments}
                          selected={cuotas.recommended_message}
                        >
                          {cuotas.recommended_message}
                        </option>
                      ) : (
                        <option key={index} value={cuotas.installments}>
                          {cuotas.recommended_message}
                        </option>
                      )
                    )}
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
          <Grid container>
            <GridItem xs={6} sm={3}>
              <CustomInput
                id="street_name"
                labelText="Calle"
                formControlProps={{ fullWidth: false }}
                helpText="Dirección destino del resumen de Tarjeta"
                error={false}
                inputProps={{
                  required: false,
                  disabled: false,
                  type: 'text',
                  value: '',
                  //onPaste: false, onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  startAdornment: '',
                  ...this.bindValue('street_name'),
                }}
              />
            </GridItem>
            <GridItem xs={3} sm={1}>
              <CustomInput
                id="street_number"
                labelText="Nro"
                helpText=""
                error=""
                formControlProps={{
                  required: false,
                  fullWidth: false,
                }}
                inputProps={{
                  required: false,
                  type: 'text',
                  //onPaste: false, onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  ...this.bindValue('street_number'),
                }}
                inputHtmlProps={
                  {
                    //minLength:medioPago.parametros.formato_cvv.length,
                    //maxLength:medioPago.parametros.formato_cvv.length,
                  }
                }
              />
            </GridItem>
            <GridItem xs={3} sm={1}>
              <CustomInput
                id="zip_code"
                labelText="CP"
                helpText=""
                error=""
                formControlProps={{
                  required: false,
                  fullWidth: false,
                }}
                inputProps={{
                  required: false,
                  type: 'text',
                  //onPaste: false, onCopy:false, onCut:false, onDrag:false, onDrop:false, autoComplete:"off",
                  ...this.bindValue('zip_code'),
                }}
                inputHtmlProps={
                  {
                    //minLength:medioPago.parametros.formato_cvv.length,
                    //maxLength:medioPago.parametros.formato_cvv.length,
                  }
                }
              />
            </GridItem>
          </Grid>

          {this.renderBotonera()}
        </form>
      </React.Fragment>
    );
  }

  renderFormFecha = () => {
    const { classes /*, medioPago*/ } = this.props;
    return (
      <React.Fragment>
        <form className={classes.root} autoComplete="off" id="pay" name="pay">
          <Grid container>
            <GridItem xs={12} sm={6}>
              <InputLabel style={{ float: 'left', paddingTop: '10px' }}>
                Fecha de Actualización
              </InputLabel>
              <Datetime
                timeFormat={false}
                dateFormat={'DD/MM/YYYY'}
                closeOnSelect={true}
                helpText={this.state.errores.fecha_actualizacion || ''}
                error={this.state.errores.fecha_actualizacion || false}
                inputProps={{
                  required: true,
                  disabled:
                    this.props.medioPago.actualiza_fecha === 'S' ? false : true,
                }}
                locale="es-ES"
                {...this.props.bindDateValue('fecha_actualizacion')}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="Importe"
                id="importe"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  required: true,
                  disabled: true,
                  type: 'text',
                  value: formatMoneda(this.props.importe),
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </GridItem>
          </Grid>

          <Grid container>
            <GridItem xs={6} sm={3}>
              <FormControl fullWidth={true}>
                <Button
                  variant="contained"
                  color="transparent"
                  fullWidth={true}
                  size="large"
                  onClick={this.onVolver}
                >
                  VOLVER
                </Button>
              </FormControl>
            </GridItem>

            <GridItem xs={6} sm={3}>
              <FormControl fullWidth={true}>
                {this.props.medioPago.clase === 'pagos360' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    id="pagos360-pay-button"
                    className="boton_pagar"
                    fullWidth={true}
                    size="large"
                    onClick={() => this.pagar360()}
                  >
                    PAGAR
                  </Button>
                ) : (
                  <Button
                    disabled={this.state.disablePagar}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    size="large"
                    onClick={() =>
                      this.props.handlerPagar({
                        id_operacion: this.props.id_operacion,
                        medio_pago: this.props.medioPago.cod_medio_pago,
                      })
                    }
                  >
                    {this.props.medioPago.clase === 'link'
                      ? 'ENVIAR'
                      : 'DESCARGAR'}
                  </Button>
                )}
              </FormControl>
            </GridItem>
          </Grid>
        </form>
      </React.Fragment>
    );
  };

  renderFormMacroClick = () => {
    const { classes /*, medioPago*/ } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <GridItem xs={12} sm={6}>
            <InputLabel
              style={{ float: 'left', paddingTop: '10px' }}
              inputProps={{
                required: true,
                disabled: true,
              }}
            >
              Fecha de Actualización
            </InputLabel>
            <Datetime
              timeFormat={false}
              dateFormat={'DD/MM/YYYY'}
              closeOnSelect={true}
              helpText={this.state.errores.fecha_actualizacion || ''}
              error={this.state.errores.fecha_actualizacion || false}
              inputProps={{
                required: true,
                disabled: true,
              }}
              locale="es-ES"
              {...this.props.bindDateValue('fecha_actualizacion')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Importe"
              id="importe"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                required: true,
                disabled: true,
                type: 'text',
                value: formatMoneda(this.props.importe),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </GridItem>
        </Grid>
        <form
          className={classes.root}
          method={'post'}
          action={this.state.paymentMacroClick.url_servicio}
          autoComplete="off"
          id="pay"
          name="pay"
        >
          <Grid container>
            <input
              type="hidden"
              name="CallbackSuccess"
              value={this.state.paymentMacroClick.callbackEncriptada}
            />
            <input
              type="hidden"
              name="CallbackCancel"
              value={this.state.paymentMacroClick.cancelEncriptada}
            />
            <input
              type="hidden"
              name="CallbackPending"
              value={this.state.paymentMacroClick.pendingEncriptada}
            />
            <input
              type="hidden"
              name="Comercio"
              value={this.state.paymentMacroClick.comercio}
            />
            <input
              type="hidden"
              name="SucursalComercio"
              value={this.state.paymentMacroClick.sucursalEncriptada}
            />
            <input
              type="hidden"
              name="hash"
              value={this.state.paymentMacroClick.hash_value}
            />
            <input
              type="hidden"
              name="TransaccionComercioId"
              value={this.state.paymentMacroClick.id}
            />
            <input
              type="hidden"
              name="monto"
              value={this.state.paymentMacroClick.montoEncriptado}
            />
            {this.state.paymentMacroClick.items.map((item, i) => (
              <input
                type="hidden"
                name={`Producto[${i}]`}
                value={item.description}
              />
            ))}
          </Grid>
          <Grid container>
            <GridItem xs={6} sm={3}>
              <FormControl fullWidth={true}>
                <Button
                  variant="contained"
                  color="transparent"
                  fullWidth={true}
                  size="large"
                  onClick={this.onVolver}
                >
                  VOLVER
                </Button>
              </FormControl>
            </GridItem>

            <GridItem xs={6} sm={3}>
              <FormControl fullWidth={true}>
                <Button
                  variant="contained"
                  color="primary"
                  id="macrolcik-pay-button"
                  className="boton_pagar"
                  fullWidth={true}
                  size="large"
                  type="submit"
                  onClick={() =>
                    this.setState({ ...this.state, isLoading: true })
                  }
                >
                  PAGAR
                </Button>
              </FormControl>
            </GridItem>
          </Grid>
        </form>
      </React.Fragment>
    );
  };

  renderFormMercadoPago = () => {
    const { classes /*, medioPago*/ } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <GridItem xs={12} sm={6}>
            <InputLabel
              style={{ float: 'left', paddingTop: '10px' }}
              inputProps={{
                required: true,
                disabled: true,
              }}
            >
              Fecha de Actualización
            </InputLabel>
            <Datetime
              timeFormat={false}
              dateFormat={'DD/MM/YYYY'}
              closeOnSelect={true}
              helpText={this.state.errores.fecha_actualizacion || ''}
              error={this.state.errores.fecha_actualizacion || false}
              inputProps={{
                required: true,
              }}
              locale="es-ES"
              {...this.props.bindDateValue('fecha_actualizacion')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Importe"
              id="importe"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                required: true,
                disabled: true,
                type: 'text',
                value: formatMoneda(this.props.importe),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </GridItem>
          <GridItem xs={6} sm={3}>
            <a
              href={this.state.paymentMercadoPago.init_point}
              data-preference-id={this.state.paymentMercadoPago.id}
            >
              <Button
                variant="contained"
                color="primary"
                id="mercadopago-pay-button"
                className="boton_pagar"
                fullWidth={true}
                size="large"
              >
                PAGAR
              </Button>
            </a>
          </GridItem>
        </Grid>
      </React.Fragment>
    );
  };

  //////////////////////////////////////////
  //////////////////////////////////////////
  renderFormPagosTic = () => {
    const { classes /*, medioPago*/ } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <GridItem xs={12} sm={6}>
            <InputLabel
              style={{ float: 'left', paddingTop: '10px' }}
              inputProps={{
                required: true,
                disabled: true,
              }}
            >
              Fecha de Actualización
            </InputLabel>
            <Datetime
              timeFormat={false}
              dateFormat={'DD/MM/YYYY'}
              closeOnSelect={true}
              helpText={this.state.errores.fecha_actualizacion || ''}
              error={this.state.errores.fecha_actualizacion || false}
              inputProps={{
                required: true,
                disabled: true,
              }}
              locale="es-ES"
              {...this.props.bindDateValue('fecha_actualizacion')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Importe"
              id="importe"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                required: true,
                disabled: true,
                type: 'text',
                value: formatMoneda(this.props.importe),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem xs={6} sm={3}>
            <FormControl fullWidth={true}>
              <Button
                variant="contained"
                color="transparent"
                fullWidth={true}
                size="large"
                onClick={this.onVolver}
              >
                VOLVER
              </Button>
            </FormControl>
          </GridItem>

          <GridItem xs={6} sm={3}>
            <FormControl fullWidth={true}>
              <a
                href={this.state.paymentPagosTic.form_url}
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  id="pagostic-pay-button"
                  className="boton_pagar"
                  fullWidth={true}
                  size="lg"
                >
                  PAGAR
                </Button>
              </a>
            </FormControl>
          </GridItem>
        </Grid>
      </React.Fragment>
    );
  };

  renderFormEpagos = () => {
    return (
      <React.Fragment>
        <Grid container>
          <GridItem xs={12} sm={6}>
            <InputLabel
              style={{ float: 'left', paddingTop: '10px' }}
              inputProps={{
                required: true,
                disabled: true,
              }}
            >
              Fecha de Actualización
            </InputLabel>
            <Datetime
              timeFormat={false}
              dateFormat={'DD/MM/YYYY'}
              closeOnSelect={true}
              helpText={this.state.errores.fecha_actualizacion || ''}
              error={this.state.errores.fecha_actualizacion || false}
              inputProps={{
                required: true,
                disabled: this.props.medioPago.actualiza_fecha === 'S' ? false : true ,
              }}
              locale="es-ES"
              {...this.props.bindDateValue('fecha_actualizacion')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Importe"
              id="importe"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                required: true,
                disabled: true,
                type: 'text',
                value: formatMoneda(this.props.importe),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </GridItem>
        </Grid>
        <Grid container>
          <GridItem xs={6} sm={3}>
            <FormControl fullWidth={true}>
              <Button
                variant="contained"
                color="transparent"
                fullWidth={true}
                size="large"
                onClick={this.onVolver}
              >
                VOLVER
              </Button>
            </FormControl>
          </GridItem>

          <GridItem xs={6} sm={3}>
            <div id="elementoEpagos"></div>
          </GridItem>
        </Grid>
      </React.Fragment>
    );
  };
  /////////////////////////////////////////
  ////////////////////////////////////////

  handleRedirect = (e) => {
    if (e.target.contentDocument)
      this.setState({ ...this.state, verBotCerrar: false });
    else this.setState({ ...this.state, verBotCerrar: true });
  };

  render() {
    const { medioPago } = this.props;
    //console.log('P R O P S', this.props);
    const { messageColor, message, isLoading } = this.state;
    //const muestraBotonera = this.props.medioPago.formulario != "no_form" ? true : medioPago.actualiza_fecha === 'S' ? true : false
    return (
      <Grid ref={this.rootRef}>
        <BlockComponent blocking={isLoading}>
          {medioPago.formulario === 'decidir_banelco' &&
            this.renderFormBanelco()}
          {medioPago.formulario === 'interbanking' &&
            this.renderFormInterbanking()}

          {(medioPago.formulario === 'decidir_tarjeta' ||
            medioPago.formulario === 'mercadopago_tarjeta') &&
            this.renderFormTarjeta()}

          {(medioPago.formulario === 'no_form' ||
            medioPago.formulario === 'pagos360') &&
            medioPago.actualiza_fecha === 'S' &&
            this.renderFormFecha()}

          {medioPago.formulario === 'macroclick' && this.renderFormMacroClick()}
          {medioPago.formulario === 'mercadopago' &&
            this.renderFormMercadoPago()}
          {medioPago.formulario === 'pagostic' && this.renderFormPagosTic()}
          {medioPago.formulario === 'epagos' && this.renderFormEpagos()}
        </BlockComponent>

        <MessageComponent color={messageColor} message={message} />
        {this.state.pagar360 && (
          <Modal
            open={this.state.pagar360}
            onClose={this.closePagar360}
            //className="p360ModalOverlayWrapper"
            style={ {
              width: '98%',
              height: '98%',
              top: '1%',
              left: '1%',
              right: 'auto',
              bottom: 'auto',
            }}
          >
            <div                 
              style={{
                  width: '98%',
                  height: '98%',
                  top: '1%',
                  left: '1%',
                  right: 'auto',
                  bottom: 'auto',
              }}
              >
              <iframe
                id="p360-modal-popbox"
                src={this.state.token360.checkout_url}
                //className="p360ModalPopbox"
                onLoad={(e) => this.handleRedirect(e)}
                referrerPolicy="origin-when-cross-origin"
                allowpaymentrequest="true"
                sandbox="allow-forms
                    allow-pointer-lock
                    allow-same-origin
                    allow-scripts
                    allow-top-navigation
                    allow-downloads
                    allow-popups"
                  style={{
                    height: '90%',
                    width: '98%',
                    display: 'flex',
                  }}

              />
              {this.state.verBotCerrar && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  size="lg"
                  onClick={(e) => this.closePagar360()}
                  style={{
                    height: '30px',
                    display: 'flex',
                  }}
                >
                  CERRAR
                </Button>
              )}
            </div>
          </Modal>
        )}
      </Grid>
    );
  }

  /*
<Fragment>
                <div id='p360-modal-overlay-wrapper'
                className="p360ModalOverlayWrapper"
                >
                    <div id='p360-modal-overlay'
                    className="p360ModalOverlay"
                    />
                </div>
                <div id= 'p360-modal-content-fixed'
                className="p360ModalContentFixed">
                    <iframe id='p360-modal-popbox' 
                    src={this.state.token360.checkout_url}
                    className="p360ModalPopbox"
                    />
                </div>
                </Fragment>
    */

  renderBotonera = () => {
    console.log("renderBotonera" + this.props.medioPago.clase );
    return (
      <Grid container>
        <GridItem xs={6} sm={3}>
          <FormControl fullWidth={true}>
            <Button
              variant="contained"
              color="transparent"
              fullWidth={true}
              size="lg"
              onClick={this.onVolver}
            >
              VOLVER
            </Button>
          </FormControl>
        </GridItem>

        <GridItem xs={6} sm={3}>
          <FormControl fullWidth={true}>
            <Button
              disabled={this.state.disablePagar}
              variant="contained"
              color="primary"
              fullWidth={true}
              size="lg"
              onClick={(e) => this.props.medioPago.clase === 'interbanking'  ? this.onSubmitInterbanking(e) : this.onSubmit(e) }
            >
              {this.props.medioPago.clase === 'interbanking'  ? 'ENVIAR' :  'PAGAR' }
            </Button>
          </FormControl>
        </GridItem>
      </Grid>
    );
  };

  renderFormBanelco = () => {
    const { classes /*, medioPago*/ } = this.props;

    return (
      <form
        autoComplete="off"
        className={classes.root}
        action=""
        method="post"
        id="formulario"
      >
        <Grid container>
          <GridItem xs={12} sm={3}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              required={true}
            >
              <InputLabel htmlFor="customer_doc_type">
                Tipo Documento
              </InputLabel>
              <Select
                native
                name="customer_doc_type"
                error={this.state.errors?.customer_doc_type || false}
                className={classes.selectEmpty}
                {...this.bindValue('customer_doc_type')}
                inputProps={{
                  id: 'customer_doc_type', //autoFocus:true
                }}
                MenuProps={{
                  className: classes.selectMenu,
                }}
              >
                <option>Seleccione</option>
                {this.state.tiposDocumento &&
                  this.state.tiposDocumento.map((td, index) => (
                    <option
                      key={index}
                      id={td.id}
                      onSelect={() => this.onSelectTipoDocumento(td)}
                    >
                      {td.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={3}>
            <CustomInput
              id="customer_doc_number"
              labelText="Número"
              helpText={this.state.errors?.customer_doc_number || ''}
              error={this.state.errors?.customer_doc_number || false}
              formControlProps={{ required: true, fullWidth: true }}
              inputProps={{
                disabled: false, //this.habilitarDocumento(),
                required: true,
                type: 'text',
                ...this.bindValue('customer_doc_number'),
              }}
            />
          </GridItem>
        </Grid>

        <Grid container>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Nombre del titular"
              id="customer_name"
              helpText={this.state.errors?.customer_name || ''}
              error={this.state.errors?.customer_name || false}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: 'TITULAR',
                required: true,
                type: 'text',
                ...this.bindValue('customer_name'),
              }}
            />
          </GridItem>
        </Grid>

        <Grid container>
          <GridItem xs={12} sm={6}>
            <InputLabel style={{ float: 'left', paddingTop: '10px' }}>
              Fecha de Actualización
            </InputLabel>
            <Datetime
              timeFormat={false}
              dateFormat={'DD/MM/YYYY'}
              closeOnSelect={true}
              helpText={this.state.errors?.fecha_actualizacion || ''}
              error={this.state.errors?.fecha_actualizacion || false}
              inputProps={{
                required: true,
                disabled:
                  this.props.medioPago.actualiza_fecha === 'S' ? false : true,
              }}
              locale="es-ES"
              {...this.props.bindDateValue('fecha_actualizacion')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Importe"
              id="importe"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                required: true,
                disabled: true,
                type: 'text',
                value: formatMoneda(this.props.importe),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </GridItem>
        </Grid>
        {this.renderBotonera()}
      </form>
    );
  };
  renderFormInterbanking = () => {
    const { classes /*, medioPago*/ } = this.props;

    return (
      <form
        autoComplete="off"
        className={classes.root}
        action=""
        method="post"
        id="formulario"
      >
        <Grid container>
          <GridItem xs={12} sm={3}>
            <FormControl
              className={classes.formControl}
              fullWidth={true}
              required={true}
            >
              <InputLabel htmlFor="customer_doc_type">
                CUIT
              </InputLabel>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={3}>
            <CustomInput
              id="customer_cuit_number"
              labelText="CUIT"
              helpText={this.state.errors?.customer_doc_number || ''}
              error={this.state.errors?.customer_doc_number || false}
              formControlProps={{ required: true, fullWidth: true }}
              inputProps={{
                disabled: false, //this.habilitarDocumento(),
                required: true,
                type: 'text',
                ...this.bindValue('customer_cuit_number'),
              }}
            />
          </GridItem>
        </Grid>

        <Grid container>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Razón Social"
              id="customer_name"
              helpText={this.state.errors?.customer_name || ''}
              error={this.state.errors?.customer_name || false}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: 'Razón Social del TITULAR',
                required: true,
                type: 'text',
                ...this.bindValue('customer_name'),
              }}
            />
          </GridItem>
        </Grid>

        <Grid container>
          <GridItem xs={12} sm={6}>
            <InputLabel style={{ float: 'left', paddingTop: '10px' }}>
              Fecha de Actualización
            </InputLabel>
            <Datetime
              timeFormat={false}
              dateFormat={'DD/MM/YYYY'}
              closeOnSelect={true}
              helpText={this.state.errors?.fecha_actualizacion || ''}
              error={this.state.errors?.fecha_actualizacion || false}
              inputProps={{
                required: true,
                disabled:
                  this.props.medioPago.actualiza_fecha === 'S' ? false : true,
              }}
              locale="es-ES"
              {...this.props.bindDateValue('fecha_actualizacion')}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Importe"
              id="importe"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                required: true,
                disabled: true,
                type: 'text',
                value: formatMoneda(this.props.importe),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </GridItem>
        </Grid>
        {this.renderBotonera()}
      </form>
    );
  };
}
export default connect(
  (state) => ({}),
  (dispatch) => ({
    // mapDispatchToProps
    actions: bindActionCreators(
      {
        getMediosPago,
        getGatewayPago,
      },
      dispatch
    ),
  })
)(withStyles(styles)(GatewayView));
