import React, { Component } from 'react';
import Header from '../../components/Header/Header.jsx';
import { getFacturas } from '../../core/facturas/facturas-actions.js';
import { getFacturaPDF } from '../../core/deudas/deudas-actions';
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../components/Page/PageStyle';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '@material-ui/core/Card';
import CardBody from '../../components/Card/CardBody.jsx';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
//import Button from '../../components/Buttons/Button.jsx';
import BlockComponent from '../../components/Loading/BlockComponent';
import ListadoFacturas from './ListadoFacturas.jsx';
import Grid from '@material-ui/core/Grid';
import CardHeader from '../../components/Card/CardHeader.jsx';

//import MessageComponent from '../../components/Message/MessageComponent';
import { downloadFile } from '../../core/downloadService';
import FiltroFacturas from './FiltroFacturas';
import { makeFiltro, filtrar } from './makeFiltro.js';

const styles = theme => ({
  ...pageStyle(theme),
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});
class MisFacturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtros: [],
    };
  }

  componentWillMount() {
    const params = {
      usuario: this.props.user.id,
      tipo: 'facturas',
    };
    Promise.resolve(this.props.actions.getFacturas(params))
      .then(result => {
        this.setState({ filtros: makeFiltro(this.props.facturas.data) });
      })
      .catch(error => console.log('error facturas', error));
  }

  handleClickFiltro = (item, index) => {
    const filtros = Object.assign([], this.state.filtros);
    filtros[index].active = !filtros[index].active;
    this.setState({ filtros: filtros });
  };

  donwloadFactura = idFactura => {
    const params = {
      idFactura,
    };
    Promise.resolve(this.props.actions.getFacturaPDF(params))
      .then(data => {
        downloadFile(data, 'factura_' + idFactura + '.pdf');
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const {
      classes,
      headerProps,
      facturas: { data: facturas, loadingFacturas },
      deudas: { loadingResumenDeudas },
    } = this.props;
    const loading = loadingFacturas || loadingResumenDeudas || false;
    return (
      <BlockComponent blocking={loading}>
        <Header {...headerProps} showTitle={true}></Header>
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardHeader>
                  <FiltroFacturas
                    filtros={this.state.filtros}
                    onClickHandler={this.handleClickFiltro}
                  />
                </CardHeader>
                <CardBody>
                  <ListadoFacturas
                    data={filtrar(
                      facturas,
                      this.state.filtros.filter(i => i.active)
                    )}
                    donwloadFactura={this.donwloadFactura}
                    parametros={this.props.parametros.data}
                  />
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </div>
      </BlockComponent>
    );
  }
}
export default connect(
  state => ({
    user: state.auth.user,
    facturas: state.facturas,
    deudas: state.resumenDeudas,
    parametros: state.parametros,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        getFacturaPDF,
        getFacturas,
      },
      dispatch
    ),
  })
)(withStyles(styles)(MisFacturas));
