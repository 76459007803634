import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { browserHistory } from '../core/globals';
import { getStore } from '../core/store';
import {
  setDeps,
  logout,
  loginFromLocalStorage,
} from '../core/auth/auth-actions';
import indexRoutes from '../routes';
import SpinnerComponent from '../components/Loading/SpinnerComponent';
import { initializeAPI } from '../core/services/http-request';
import { customTheme } from '../customTheme';
import { getColor } from '../core/params.js';

import {
  getVersion,
  getParametro,
} from '../core/parametros/parametros-actions.js';
import { PACKAGEJSON_VERSION, ISDEVELOP } from '../core/globals.js';

const store = getStore();
setDeps(browserHistory);
initializeAPI(() => store.dispatch(logout()));

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : decodeURIComponent(a[1]);
      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      //if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;

        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

//console.log("window APP", window.location);
let urlParams = {};

if (window.location.href.indexOf("?") > 1) {
  urlParams = getAllUrlParams(
    window.location.href.slice(window.location.href.indexOf("?"))
  );
}

if (urlParams.redireccionar){
  sessionStorage.setItem("redireccionar", urlParams.redireccionar);
}

export default class App extends React.Component {
  verificarVersiones = (versionFrontend, versionBackend) => {
    const frontend = versionFrontend.split('.');
    const backend = versionBackend.split('.');
    //Primer dígito
    if (parseInt(frontend[0]) !== parseInt(backend[0])) {
      if (parseInt(frontend[0]) < parseInt(backend[0]))
        return {
          ok: false,
          message: 'Debe actualizar la Aplicación',
          versionFrontend,
          versionBackend,
        };
      if (parseInt(frontend[0]) > parseInt(backend[0]))
        return {
          ok: false,
          message:
            'El contenedor de servicios es de versión ' +
            versionBackend +
            ' y la versión de la aplicación es ' +
            versionFrontend +
            ', por favor contacte al administrador.',
          versionFrontend,
          versionBackend,
        };
    }
    if (parseInt(frontend[1]) !== parseInt(backend[1])) {
      if (parseInt(frontend[1]) < parseInt(backend[1]))
        return {
          ok: false,
          message: 'Debe actualizar la Aplicación',
          versionFrontend,
          versionBackend,
        };
      if (parseInt(frontend[1]) > parseInt(backend[1]))
        return {
          ok: false,
          message:
            'El contenedor de servicios es de versión ' +
            versionBackend +
            ' y la versión de la aplicación es ' +
            versionFrontend +
            ', por favor contacte al administrador.',
          versionFrontend,
          versionBackend,
        };
    }
    return {
      ok: true,
      message:
        'Version del Frontend ' +
        versionFrontend +
        ', Backend ' +
        versionBackend,
      versionFrontend,
      versionBackend,
    };
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("standalone"))
      localStorage.setItem("standalone", urlParams.get("standalone"));
  }

  async componentWillMount() {
    this.setState({ loading: true });
    var color = await getColor();
    customTheme.palette.primary.main = color;
    //Checkeo de Versiones
    new Promise((resolve, reject) => {
      //store.dispatch(getVersion({versionApp:"version"}))
      store.dispatch(getParametro({ parametro: 'AU_NOTICIAS' }));

      //Si es desarrollo saltea el checkeo de versiones
      if (ISDEVELOP) resolve({});

      new Promise((resolve, reject) => {
        resolve(store.dispatch(getVersion({ versionApp: 'version' })));
      }).then(result => {
        //comparar la version
        //Si la version no es la correct reject
        const verificacion = this.verificarVersiones(
          PACKAGEJSON_VERSION,
          result.version
        );
        verificacion.ok ? resolve() : reject(verificacion);
      });
    })
      .then(result => {
        store.dispatch(loginFromLocalStorage()).then(() => {
          this.setState({ loading: false });
        });
      })
      .catch(error => {
        this.setState({ loading: true });
        console.log(error);
        alert(error.message);
      });
  }

  render() {
    const theme = createMuiTheme(customTheme);
    if (this.state.loading)
      return (
        <SpinnerComponent
          blocking={true}
          style={{ minHeight: 400, height: '100%' }}
        />
      );

    return (
      <Provider store={store}>
        <Router history={browserHistory}>
          <ThemeProvider theme={theme}>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </ThemeProvider>
        </Router>
      </Provider>
    );
  }
}
