import React, { Component } from 'react';
import Header from '../../components/Header/Header.jsx';
//import * as AuthService from '../../core/auth/auth-actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPerfil,
  postPerfilCuenta,
  deletePerfilCuenta,
  getPerfilCuentas,
  postPerfilCuentas,
  getTipoDocumento,
} from '../../core/perfil/perfil-actions';
import {
  postCuentaDebitoAu,
  postFacturaElectronica,
  deleteFacturaElectronica,
} from '../../core/cuentas/cuentas-actions';
import { getTiposCuentas } from '../../core/tiposCuentas/tiposCuentas-actions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardFooter from '../../components/Card/CardFooter.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../components/Buttons/Button.jsx';
import Select from '@material-ui/core/Select';
import MessageComponent from '../../components/Message/MessageComponent';
import BlockComponent from '../../components/Loading/BlockComponent';
import extendedFormsStyle from '../../components/Form/FormsStyle.jsx';
import pageStyle from '../../components/Page/PageStyle';
import ListadoCuentas from './ListadoCuentas';
import FormDebitoAutomatico from './FormDebitoAutomatico';
import FormFacturaElectronica from './FormFacturaElectronica';
import ConfirmItemComponent from '../../components/ConfirmItem/ConfirmItemComponent';
import { rePatente } from '../../core/expRegulares.js';

const styles = (theme) => ({
  ...pageStyle(theme),
  ...extendedFormsStyle,
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  defaultSelect: {
    marginTop: '9px',
  },
});

class Cuentas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      messageColor: null,
      cuentas: [],
      tiposDocumento: [],
      data: {
        cuenta_numero: null,
        cuenta_alias: null,
        cuenta_id: null,
        cuenta_tipo_cuenta: null,
        patente: null,
      },
      validate: {},
      openAgregar: false,
      openConfirm: false,
      operacionConfirm: null,
      selectedItem: null,
      operacion: '',
    };
  }

  componentWillMount() {
    const usuario = this.props.user.id;
    Promise.all([
      this.props.actions.getTiposCuentas(),
      this.props.actions.getPerfilCuentas({ usuario }),
      this.props.actions.getTipoDocumento(),
      this.props.actions.getPerfil({ usuario }),
    ]).then((data) => {
      const state = this.state;
      state.tipos_cuentas = data[0] || [];
      state.tiposDocumento = data[2];
      state.data.cuenta_tipo_cuenta = data[0][0].cdCode;
      this.setState({
        ...state,
      });
    });
  }

  /*componentDidMount(){
		const divFilters = document.getElementsByClassName('rt-thead -filters')[0];
		const rowFilters = divFilters.getElementsByClassName('rt-tr')[0];
		var divNode = document.createElement('div');
		divNode.id = 'iconFilter';
		divNode.style = 'width: 25px; margin-left: 5px;';
		divNode.innerHTML =
		'<div class="MuiListItemIcon-root"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path></svg></div>';
		rowFilters.prepend(divNode);
		rowFilters.style = "display:'flex';";
	}*/

  componentDidMount() {

    let verIconos=(this?.props?.parametros?.data?.APP_CUENTAS_VER_ICONOS?.valor==="S") ? true : false;
    let tooltipAltFact = this?.props?.parametros?.data?.APP_TOOLTIP_ALTA_FACT?.valor||'Boleta Electrónica';
    let tooltipBajFact = this?.props?.parametros?.data?.APP_TOOLTIP_ALTA_FACT?.valor||'Baja de Boleta Electrónica';
    let toolTipDesAsociar =this?.props?.parametros?.data?.APP_TOOLTIP_DES_CUENTA?.valor||'Desasociar Cuenta';
    this.setState({
      ...this.state,
      verIconos,
      tooltipAltFact,
      tooltipBajFact,
      toolTipDesAsociar,
    });
  }

  setErrorMessage(message) {
    this.setMessage({ messageColor: 'error', message });
  }

  setMessage = ({ message, messageColor }) => {
    this.setState({ message, messageColor });
    setTimeout(this.resetMessage, 6000);
  };

  resetMessage = () => {
    this.setState({ message: null, messageColor: null });
  };

  bindValue = (key) => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value: this.state.data[key] || '',
    };
  };

  bindNumberValue = (key) => {
    return {
      onChange: this.handleOnChangeValue.bind(this)(key),
      value:
        this.state.data[key] === undefined
          ? 0
          : Number(this.state.data[key] || ''),
    };
  };

  bindDateValue = (key) => {
    return {
      onChange: (m) => {
        const state = this.state;
        state.data[key] = m.format('DD/MM/YYYY');
        this.setState({ state });
      },
      value: this.state.data[key] || '',
    };
  };

  handleOnChangeValue(key) {
    return (e) => {
      const state = this.state;
      state.data[key] = e.target.value;
      state.validate[key] = this.validarItem(e.target.value, key);
      if (key === 'cuenta_tipo_cuenta') {
        state.data['cuenta_numero'] = null;
        state.data['patente'] = null;
      }
      this.setState({
        ...state,
      });
    };
  }

  validarItem = (value, key) => {
    const validate = { error: false, message: '' };
    if (key === 'patente') {

      if( value === null || value ===""){
        return {error:true,message:'Patente vacía'};   
      }
      validate.error = !rePatente.test(value);
      validate.error
        ? (validate.helpText = 'Patente inválida.')
        : (validate.helpText = '');

    }
    if (key === 'cuenta_numero') {
      if( value === null || value ==="" ){
        return {error:true,message:'Nro de cuenta vacía'};   
      }
    }
    return validate;
  };

  validateNumber = (e) => {
    return true;
  };

  borrarCuenta = (cuenta) => {
    const params = {
      usuario: this.props.user.id,
      tipoCuenta: cuenta.tipoCuenta,
      nroCuenta: cuenta.nroCuenta,
    };
    Promise.all([this.props.actions.deletePerfilCuenta(params)])
      .then((data) => {
        this.setMessage({
          messageColor: 'success',
          message: 'Cuenta desvinculada.',
        });
      })
      .catch((error) => {
        this.setErrorMessage(error.message || error);
      });
  };

  handlerUpAutoDebit = (item) => {
    this.setState({
      ...this.state,
      selectedItem: item,
      operacion: 'upDA',
    });
  };

  clearSelectedItem = () => {
    this.setState({
      selectedItem: null,
      operacion: '',
      openConfirm: false,
    });
  };

  handlerDownAutoDebit = (item) => {
    // this.setState({
    // 	...this.state,
    // 	selectedItem:item,
    // 	operacion:'downDA',
    // });
  };

  handlerUpFacturaElectronica = (item) => {
    this.setState({
      ...this.state,
      selectedItem: item,
      operacion: 'upFA',
    });
  };

  handlerDownFacturaElectronica = (item) => {
    this.setState({
      ...this.state,
      selectedItem: item,
      openConfirm: true,
      operacionConfirm: this.onDownFacturaElectronica,
    });
  };

  onDownFacturaElectronica = () => {
    const params = {
      tipoCuenta: this.state.selectedItem.tipoCuenta,
      nroCuenta: this.state.selectedItem.nroCuenta,
    };

    this.clearSelectedItem();

    Promise.resolve(this.props.actions.deleteFacturaElectronica(params))
      .then((response) => {
        this.setMessage({
          messageColor: 'info',
          message: 'Boleta Electrónica dada de baja.',
        });
        this.props.actions.getPerfilCuentas({ usuario: this.props.user.id });
      })
      .catch((error) => {
        if (error.resultado === 'PERMISO.DENEGADO')
          this.setErrorMessage('No tiene permiso para esa operación');
        else this.setErrorMessage(error.descripcion || error.message);
      });
  };

  actualizarCuentas = (cuentas) => {
    if (cuentas.length === 0)
      return this.setMessage({
        messageColor: 'info',
        message: 'No hay cambios.',
      });

    const params = {
      usuario: this.props.user.id,
      cuentas: cuentas,
    };

    Promise.all([this.props.actions.postPerfilCuentas(params)])
      .then((data) => {
        this.setMessage({
          messageColor: 'info',
          message: 'Cuentas actualizadas.',
        });
      })
      .catch((error) => {
        this.setErrorMessage(error.message || error);
      });
  };

  asociarCuenta = () => {
    var errores = 0;
    Object.values(this.state.validate).map((v) => (v.error ? errores++ : true));
    if (errores > 0) {
      this.setErrorMessage('Verifique los datos.');
      return;
    }
    if ((this.state.data.cuenta_numero === null ||this.state.data.cuenta_numero==="" )&& (this.state.data.patente === null ||this.state.data.patente === "")) {
      this.setErrorMessage('Falta completar dato de cuenta.');
      return;
    }
    const params = {
      usuario: this.props.user.id,
      aliasCuenta: this.state.data.cuenta_alias,
      tipoCuenta: this.state.data.cuenta_tipo_cuenta,
      nroCuenta: this.state.data.cuenta_numero,
      patente: this.state.data.patente,
    };

    Promise.all([this.props.actions.postPerfilCuenta(params)])
      .then((result) => {
        const data = this.state.data;
        data.cuenta_numero = null;
        data.cuenta_alias = null;
        data.cuenta_tipo_cuenta = null;
        data.patente = null;
        this.setState({
          ...this.state,
          openAgregar: false,
          data: data,
        });

        this.setMessage({ messageColor: 'info', message: 'Cuenta vinculada.' });
      })
      .catch((error) => {
        if (error.result === 'NO_EXISTE_CUENTA')
          this.setErrorMessage('La cuenta no existe o no se puede asociar');
        else this.setErrorMessage(error.descripcion || error.message);
      });
  };

  hablitarAsociarCuenta = () => {
    return true;
  };

  hablitarAsociarFacturaElectronica = () => {
    return true;
  };

  onAsociarFacturaElectronica = (data) => {
    Promise.resolve(this.props.actions.postFacturaElectronica(data))
      .then((response) => {
        this.clearSelectedItem();
        this.setMessage({
          messageColor: 'info',
          message: 'Adhesión realizada.',
        });
        this.props.actions.getPerfilCuentas({ usuario: this.props.user.id });
      })
      .catch((error) => {
        if (error.resultado === 'PERMISO.DENEGADO')
          this.setErrorMessage('No tiene permiso para esa operación');
        else this.setErrorMessage(error.descripcion || error.message);
      });
  };

  onAsociarCuenta = (data) => {
    Promise.resolve(this.props.actions.postCuentaDebitoAu(data))
      .then((response) => {
        this.clearSelectedItem();
        this.setMessage({
          messageColor: 'info',
          message: 'Adhesión realizada.',
        });
        this.props.actions.getPerfilCuentas({ usuario: this.props.user.id });
      })
      .catch((error) => {
        if (error.resultado === 'PERMISO.DENEGADO')
          this.setErrorMessage('No tiene permiso para esa operación');
        else this.setErrorMessage(error.descripcion || error.message);
      });
  };

  getInputNumero = () => {
    const cuenta_tipo_cuenta = this.state.data.cuenta_tipo_cuenta;

    if (cuenta_tipo_cuenta === 'TCR10') {
      return (
        <CustomInput
          labelText="Patente"
          id="patente"
          helpText={
            this.state.validate.patente !== undefined
              ? this.state.validate.patente.helpText
              : this.props.parametros?.data?.OV_AGREAR_CUENTA_PLACEHOLDER_NRO?.valor ||
                'Se encuentra en el margen superior derecho de la boleta'
          }
          error={
            this.state.data.patente !== undefined && this.state.validate.patente !== undefined && this.state.validate.patente.error!=undefined 
              ? this.state.validate.patente.error
              : ( this.state.data.patente !== undefined && this.state.data.patente === null ? "Completar" :  false)
          }
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            required: true,
            type: 'text',
            ...this.bindValue('patente'),
          }}
        />
      );
    }
    return (
      <CustomInput
        labelText="Número"
        id="cuenta_numero"
        helpText={
          this.props.parametros?.data?.OV_AGREAR_CUENTA_PLACEHOLDER_NRO?.valor ||
          'Se encuentra en el margen superior derecho de la boleta'
        }
        formControlProps={{
          fullWidth: true,
        }}
        value={
          this.state.data.cuenta_tipo_cuenta ? this.state.data.cuenta_tipo_cuenta : 1
        }
        error={
          this.state.data.cuenta_numero !== undefined && this.state.validate.cuenta_numero !== undefined && this.state.validate.cuenta_numero.error!=undefined 
            ? this.state.validate.cuenta_numero.error
            : ( this.state.data.cuenta_numero !== undefined && this.state.data.cuenta_numero === null ? "Completar" :  false)
        }
        inputProps={{
          required: true,
          type: 'text',
          ...this.bindValue('cuenta_numero'),
        }}
      />
    );
  };

  render() {
    //const { classes, headerProps, facturas: {data:facturas, loadingFacturas},
    //deudas: {loadingResumenDeudas}} = this.props;

    const { classes, headerProps } = this.props;
    const loadingTiposCuentas = this.props.tiposCuentas.loadingTiposCuentas;
    const tiposCuentas = this.props.tiposCuentas.data;
    const loadingPerfil = this.props.perfil.loadingPerfil;
    const loadingCuentas = this.props.cuentas.loadingCuentas;
    const { message, messageColor } = this.state;
    const loading = loadingPerfil || loadingTiposCuentas || loadingCuentas;

    return (
      <BlockComponent blocking={loading}>
        <Header {...headerProps} showTitle={true}></Header>
        <MessageComponent color={messageColor} message={message} />
        <div className={classes.wrapper}>
          <ConfirmItemComponent
            open={this.state.openConfirm}
            item="logout"
            handleClose={() =>
              this.setState({ openConfirm: false, selectedItem: null })
            }
            handleOnConfirm={this.state.operacionConfirm}
            title="Confirmar"
            description={this.state.msgConfirm}
          />

          <Grid container spacing={1}>
            <Card className={classes.card}>
              {this.state.operacion !== 'upFA' ? (
                <CardHeader>
                  {!this.state.openAgregar && (
                    <GridContainer
                      direction="row"
                      justify="flex-end"
                      alignItems="flex-end"
                    >
                      <GridItem>
                        <Button
                          color={this.state.openAgregar ? 'default' : 'primary'}
                          onClick={() =>
                            this.setState({
                              openAgregar: !this.state.openAgregar,
                            })
                          }
                        >
                          {this.state.openAgregar ? 'Cancelar' : 'Agregar'}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  )}
                </CardHeader>
              ) : null}
              <CardBody>
                {this.state.operacion === 'upDA' && (
                  <FormDebitoAutomatico
                    item={this.state.selectedItem}
                    handleOnClickCancelar={this.clearSelectedItem}
                    habilitarButtonAceptar={this.hablitarAsociarCuenta}
                    handleOnClickAceptar={this.onAsociarCuenta}
                    tiposDocumento={this.props.perfil.tiposDocumento}
                  />
                )}

                {/* {
							this.state.operacion === 'downDA' &&
								<FormDebitoAutomatico 
									item = { this.state.selectedItem }
									handleOnClickCancelar = { this.clearSelectedItem }
									habilitarButtonAceptar = { true }
									handleOnClickAceptar = { this.onDownDebitoAutomatico }
									tiposDocumento = { this.props.perfil.tiposDocumento }
									
									readonly = { true }
								/>
						} */}

                {this.state.operacion === 'upFA' && (
                  <FormFacturaElectronica
                    item={this.state.selectedItem}
                    handleOnClickCancelar={this.clearSelectedItem}
                    habilitarButtonAceptar={
                      this.hablitarAsociarFacturaElectronica
                    }
                    handleOnClickAceptar={this.onAsociarFacturaElectronica}
                    defaultMail={this.props.perfil.data.mail}
                    defaultTitle={this.props.parametros?.data?.APP_TITULO_FAC_ELECT?.valor ||'Adhiere a la Boleta Electrónica ?'
                    }
                    mensaje={this.props.parametros?.data?.OV_PARAMETRO_FACTURA_DIGITAL_TEXTO?.valor|| ''
                    }
                    permiteBaja={this.props.parametros?.data?.OV_PARAMETRO_FACTURA_DIGITAL_BAJA?.valor||'N'
                    }
                  />
                )}

                <GridContainer
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  {this.state.openAgregar && (
                    <GridItem xs={12} sm={12}>
                      <Card>
                        <CardHeader>Agregar cuenta</CardHeader>
                        <CardBody>
                          <Grid container>
                            <Grid container>
                              <GridItem xs={12} sm={8}>
                                <CustomInput
                                  labelText="Alias"
                                  id="cuenta_alias"
                                  helpText={
                                    this.props.parametros?.data
                                      ?.OV_AGREAR_CUENTA_PLACEHOLDER?.valor ||
                                    'Se refiere a la designación que desee asignarse (casa, estudio, oficina, sucursal, etc.'
                                  }
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    required: true,
                                    type: 'text',
                                    ...this.bindValue('cuenta_alias'),
                                  }}
                                />
                              </GridItem>
                            </Grid>
                            <Grid xs={12} sm={6}>
                              <GridItem xs={12} sm={8}>
                                <FormControl
                                  className={classes.defaultSelect}
                                  fullWidth={true}
                                >
                                  <InputLabel htmlFor="cuenta_tipo_cuenta">
                                    Tipo Cuenta
                                  </InputLabel>
                                  <Select
                                    native
                                    {...this.bindValue('cuenta_tipo_cuenta')}
                                    name="cuenta_tipo_cuenta"
                                    inputProps={{
                                      id: 'cuenta_tipo_cuenta',
                                    }}
                                    className={classes.selectEmpty}
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                  >
                                    <option value="" disabled></option>
                                    {tiposCuentas.map((tipo, index) => {
                                      return (
                                        <option key={index} value={tipo.cdCode}>
                                          {tipo.dsCode}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </GridItem>

                              <GridItem xs={12} sm={12}>
                                {this.getInputNumero()}
                              </GridItem>
                            </Grid>

                            <GridItem xs={12}>
                              <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                              >
                                <GridItem xs={6} sm={6}>
                                  <Button
                                    fullWidth={true}
                                    color="primary"
                                    type="submit"
                                    onClick={this.asociarCuenta}
                                  >
                                    Aceptar
                                  </Button>
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                  <Button
                                    fullWidth={true}
                                    color="secondary"
                                    onClick={() =>
                                      this.setState({
                                        openAgregar: !this.state.openAgregar,
                                      })
                                    }
                                  >
                                    Cancelar
                                  </Button>
                                </GridItem>
                              </Grid>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Card>
                    </GridItem>
                  )}
                </GridContainer>
                {this.state.operacion === '' && (
                  <ListadoCuentas
                    data={this.props.perfil.cuentas}
                    debitoAutomatico={
                      this.props?.parametros?.data?.AU_DEBITO_AUTOMATICO?.valor || false
                    }
                    onDeleteCuenta={this.borrarCuenta}
                    onSaveCuentas={this.actualizarCuentas}
                    onUpDebitoAutomatico={this.handlerUpAutoDebit}
                    onDownDebitoAutomatico={this.handlerDownAutoDebit}
                    onUpFacturaElectronica={this.handlerUpFacturaElectronica}
                    onDownFacturaElectronica={
                      this.handlerDownFacturaElectronica
                    }
                    verIconos={this.state.verIconos}
                    tooltipAltFact={this.state.tooltipAltFact}
                    tooltipBajFact={this.state.tooltipBajFact}
                    toolTipDesAsociar={this.state.toolTipDesAsociar}
                    urlIconCuenta_si={this.props?.parametros?.data?.APP_FACT_DIGITAL_SI?.valor}
                    urlIconCuenta_no={this.props?.parametros?.data?.APP_FACT_DIGITAL_NO?.valor}
                    permiteBaja={
                      this.props?.parametros?.data?.OV_PARAMETRO_FACTURA_DIGITAL_BAJA?.valor ? 
                      this.props?.parametros?.data?.OV_PARAMETRO_FACTURA_DIGITAL_BAJA?.valor : 'N'}
                  />
                )}
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Grid>
        </div>
      </BlockComponent>
    );
  }
}

export default connect(
  (state) => ({
    user: state.auth.user,
    perfil: state.perfil,
    tiposCuentas: state.tiposCuentas,
    cuentas: state.cuentas,
    parametros: state.parametros,
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      {
        getPerfil,
        getTiposCuentas,
        postPerfilCuenta,
        deletePerfilCuenta,
        getPerfilCuentas,
        postPerfilCuentas,
        postCuentaDebitoAu,
        getTipoDocumento,
        postFacturaElectronica,
        deleteFacturaElectronica,
      },
      dispatch
    ),
  })
)(withStyles(styles)(Cuentas));
